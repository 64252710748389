import clsx from "clsx";

function TablePaginationWrapper({ children, toggleMargin = false }) {
  return (
    <div
      className={clsx(
        "flex flex-row flex-wrap items-end justify-center print:hidden",
        toggleMargin ? "my-3" : "my-1"
      )}
    >
      {children}
    </div>
  );
}

export default TablePaginationWrapper;
