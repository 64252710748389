import Breadcrumb from 'app/components/atoms/breadcrumb';

function MainLayoutHeadingUpperbarBreadcrumb({ items = [] }) {
  return (
    <Breadcrumb.Wrapper>
      <Breadcrumb.Body>
        <Breadcrumb.Root />
        {
          items.map((item, key) => (
            <Breadcrumb.Item key={key} href={item.href} to={item.to}>
              {item.label}
            </Breadcrumb.Item>
          ))
        }
      </Breadcrumb.Body>
    </Breadcrumb.Wrapper>
  );
}

export default MainLayoutHeadingUpperbarBreadcrumb;
