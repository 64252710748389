import { createSlice } from "@reduxjs/toolkit";
import storage from "app/storage";
import axios from "../../integration/api/axios";

const initialState = {
  authenticated: storage.authentication.authenticated(),
  token: storage.authentication.token(),
};

export const darkModeSlice = createSlice({
  name: "darkMode",
  initialState,
  reducers: {
    authenticate: (state, action) => {
      const token = action.payload;

      state.authenticated = true;
      state.token = token;

      storage.authentication.store(true, token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    },
    revoke: (state) => {
      state.authenticated = false;
      state.token = "";

      storage.authentication.store(false, "");
    },
  },
});

export const { authenticate, revoke } = darkModeSlice.actions;

export default darkModeSlice.reducer;
