import axios from "../axios";
import h from "../helpers";

const address = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("addresses"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("addresses/" + id));
    return data;
  },
  store: async (
    desc,
    state_id,
    state_name,
    city_id,
    city_name,
    district_id,
    district_name,
    sub_district_id,
    sub_district_name,
    zipcode
  ) => {
    const { data } = await axios.post(h.baseUrl("addresses"), {
      desc,
      state_id,
      state_name,
      city_id,
      city_name,
      district_id,
      district_name,
      sub_district_id,
      sub_district_name,
      zipcode,
    });
    return data;
  },
  update: async (
    id,
    desc,
    state_id,
    state_name,
    city_id,
    city_name,
    district_id,
    district_name,
    sub_district_id,
    sub_district_name,
    zipcode
  ) => {
    const { data } = await axios.put(h.baseUrl("addresses/" + id), {
      desc,
      state_id,
      state_name,
      city_id,
      city_name,
      district_id,
      district_name,
      sub_district_id,
      sub_district_name,
      zipcode,
    });
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(h.baseUrl("addresses/" + id));
    return data;
  },
};

export default address;
