import { useApplicationModals } from "app/common/hooks";
import * as Yup from "yup";
import { useUpdateForm } from "app/integration/common/hooks";
import api from "app/integration/api";
import { useFetcher } from "app/integration/common/hooks";
import { useEffect } from "react";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik, FieldArray, getIn } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { InputLabel } from "app/components/atoms/form";
import { Button, DottedOutlineButton } from "app/components/atoms/button";
import { ClosableCard } from "app/components/atoms/card";
import AppModals from ".";

function UpdateCategoryModal({ onSuccess = () => {} }) {
  let isError = false;
  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const { openModal, setPayloads } = useApplicationModals();
  const payload = getPayloads("category.update");

  const handleClose = () => closeModal("category.update");
  const handleSuccess = () => {
    closeModal("category.update");
    onSuccess();
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        name: "",
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Nama Kategori harus diisi"),
      }),
      onSubmit: async (values) => {
        try {
          const response = await api.category.update(values.id, values.name);

          if (response && response.status == "success") {
            isError = false;
          }
        } catch (error) {
          setPayloads("errorMessage.view", {
            title: "Update Category",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Server Error",
          });
          isError = true;
          openModal("errorMessage.view");
        }
      },
      onSuccess: () => !isError && handleSuccess(),
    });

  const [category, setVariant, loader, fetch] = useFetcher({
    modelName: "category",
    payload,
  });

  useEffect(() => {
    setInitialValues({
      id: category.id,
      name: category.name,
    });
  }, [category]);

  return (
    <ModalMolecule
      show={showStates["category.update"]}
      title="Ubah Kategori"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-2" onSubmit={handleSubmit}>
            <InputMolecule
              label="Nama"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              errorMessage={errors.name && touched.name && errors.name}
            />
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Simpan</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default UpdateCategoryModal;
