
function TableHeaderRow({ children }) {
  return (
    <tr
      className="transition-colors ease-in-out duration-300"
    >
      {
        children
      }
    </tr>
  );
}

export default TableHeaderRow;
