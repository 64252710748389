import axios from "../axios";
import h from "../helpers";

const user = {
  login: async (email, password) => {
    const { data } = await axios.post(
      h.baseUrl("auth"),
      { email, password }
    );
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("users/" + id));
    return data;
  },
  update: async (id, password) => {
    const { data } = await axios.put(
      h.baseUrl('users/' + id),
      { 
        password
      },
    );
    return data;
  },
};

export default user;
