import { TrashIcon, ArrowPathIcon } from "@heroicons/react/24/solid";
import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import {
  BadgeButton,
  Button,
  DottedOutlineButton,
  IconButton,
} from "app/components/atoms/button";
import { Card } from "app/components/atoms/card";
import { InputLabel } from "app/components/atoms/form";
import { FormHeader, FormSummary } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import { ModalMolecule } from "app/components/molecules/modal";
import {
  SelectMolecule,
  SelectSearchMolecule,
} from "app/components/molecules/select";
import api from "app/integration/api";
import {
  useCollector,
  useFetcher,
  useUpdateForm,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik, getIn } from "formik";
import str from "lib/str";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "style/height.module.css";
import {
  filter as _filter,
  findWhere as _findWhere,
  flatten as _flatten,
  isError,
} from "underscore";
import * as Yup from "yup";

const breadcrumbItems = [
  { label: "Sales", href: "/sales_order" },
  { label: "Order", href: "/sales_order" },
  { label: "Ubah Order", href: "#" },
];

function UpdateSalesOrderPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  let isError = false;

  const [products, setProduct] = useState([]);
  const [addresses, setAddress] = useState([]);
  const [delivery_fee, setDeliveryFee] = useState(0);
  const [selectedCourier, setSelectedCourier] = useState("");
  const [needUpdateDeliveryFee, setNeedUpdateDeliveryFee] = useState(false);

  const handleSuccess = () => {
    navigate("/sales_order");
  };

  const checkNew = (
    old_size_variant_id,
    old_color_variant_id,
    old_product_id,
    product_id,
    size_variant_id,
    color_variant_id
  ) => {
    let count = 0;
    if (old_size_variant_id != size_variant_id) count++;
    if (old_color_variant_id != color_variant_id) count++;
    if (old_product_id != product_id) count++;

    console.log(
      old_size_variant_id,
      old_color_variant_id,
      old_product_id,
      product_id,
      size_variant_id,
      color_variant_id,
      count
    );

    return count;
  };

  const [isLoading, setIsLoading] = useState(false);
  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        customer_id: null,
        address_id: null,
        delivery_fee: 0,
        note_order: "",
        courier_package: "",
        items: [
          {
            id: null,
            product_id: null,
            size_variant_id: null,
            color_variant_id: null,
            qty: 1,
            old_qty: 1,
            unit_price: 0,
            discount_reseller: 0,
            weight: 0,
            old_product_id: null,
            old_color_variant_id: null,
            old_size_variant_id: null,
          },
        ],
        order_code: "",
      },
      validationSchema: Yup.object().shape({
        customer_id: Yup.number().required("Customer harus diisi"),
        address_id: Yup.number().required("Alamat harus diisi"),
        delivery_fee: Yup.number(),
        note_order: Yup.string(),
        items: Yup.array()
          .of(
            Yup.object().shape({
              product_id: Yup.number().required("Produk harus diisi"),
              size_variant_id: Yup.number().required("Size harus diisi"),
              color_variant_id: Yup.number().required("Warna harus diisi"),
              qty: Yup.number().required("Qty harus diisi").min(1, "minimal 1"),
              unit_price: Yup.number().required("Harga harus diisi"),
              discount_reseller: Yup.number()
                .min(0, "Diskon 0-50.000")
                .max(50000, "Diskon 0-50.000"),
            })
          )
          .min(1, "Minimal harus ada 1 Item yang dibeli."),
      }),
      onSubmit: async (values) => {
        if (needUpdateDeliveryFee) {
          setPayloads("errorMessage.view", {
            title: "Update Sales Order Error",
            message: "Harap kalkulasi ulang ongkir terlebih dahulu.",
          });
          openModal("errorMessage.view");
          isError = true;
        } else {
          try {
            setIsLoading(true);
            const response = await api.salesOrder.update(
              values.id,
              values.customer_id,
              values.address_id,
              delivery_fee,
              values.note_order,
              values.courier_package,
              values.items.map((orderDetail) => ({
                id:
                  checkNew(
                    orderDetail.old_size_variant_id,
                    orderDetail.old_color_variant_id,
                    orderDetail.old_product_id,
                    orderDetail.product_id,
                    orderDetail.size_variant_id,
                    orderDetail.color_variant_id
                  ) == 0
                    ? orderDetail.id
                    : null,
                // id: orderDetail.id,
                product_id: orderDetail.product_id,
                size_variant_id: orderDetail.size_variant_id,
                color_variant_id: orderDetail.color_variant_id,
                qty: orderDetail.qty,
                old_qty:
                  checkNew(
                    orderDetail.old_size_variant_id,
                    orderDetail.old_color_variant_id,
                    orderDetail.old_product_id,
                    orderDetail.product_id,
                    orderDetail.size_variant_id,
                    orderDetail.color_variant_id
                  ) == 0
                    ? orderDetail.old_qty
                    : null,
                unit_price: orderDetail.unit_price,
                discount_reseller: orderDetail.discount_reseller,
                weight: orderDetail.weight,
              }))
            );
            isError = false;
            return response;
          } catch (error) {
            setPayloads("errorMessage.view", {
              title: "Update Sales Order",
              message: error?.response?.data?.message
                ? error?.response?.data?.message
                : "Server Error",
            });
            isError = true;
            openModal("errorMessage.view");
            setIsLoading(false);
          }
        }
      },
      onSuccess: () => {
        if (!isError) {
          handleSuccess();
        }
      },
    });

  const [salesOrder, setSalesOrder, loader, fetch] = useFetcher({
    modelName: "salesOrder",
    payload: { id },
  });

  const { openModal, setPayloads } = useApplicationModals();
  const [currentErrors, setCurrentErrors] = useState([]);

  function runValidations(values) {
    validationSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
        setCurrentErrors([]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.name); // ValidationError
        console.log(err.errors);
        setCurrentErrors(err.errors);

        setPayloads("errorMessage.view", {
          title: "Ubah Order Error",
          data: err.errors,
        });
        openModal("errorMessage.view");
      });
  }

  const [customers] = useCollector({ modelName: "customer" });
  const [dailyMenus] = useCollector({ modelName: "dailyMenu" });

  const localHelpers = {
    subtotal: (orderItems) => {
      if (orderItems && orderItems.length > 0) {
        let subtotal = orderItems
          .map(
            (orderItem) =>
              Number(orderItem.qty) *
              (Number(orderItem.unit_price) -
                Number(orderItem.discount_reseller))
          )
          .reduce((partialSum, a) => partialSum + a, 0);

        return subtotal;
      }
    },
    totalWeight: (orderItems) => {
      if (orderItems && orderItems.length > 0) {
        let totalWeightOrder = orderItems
          .map((orderItem) => +orderItem.qty * +orderItem.weight)
          .reduce((partialSum, a) => partialSum + a, 0);

        return +totalWeightOrder;
      }
    },
  };

  const getAddress = async (customer_id) => {
    const response = await api.address.index({ customer_id });

    if (response && response.data) {
      let convert = response.data.map((list) => ({
        value: list.id,
        label: list.desc,
      }));
      setAddress(convert);
    }
  };

  function getUniqueData(products, product_id, variant, selected_variant_id) {
    let filtered = products.filter((i) => i.id === product_id)[0];
    let uniqueData = {};
    let isDisabled = false;

    filtered?.ProductVariants.forEach((item) => {
      if (variant === "color" && selected_variant_id > 0) {
        if (
          filtered?.ProductVariants.find(
            (option) =>
              option.size.id === selected_variant_id &&
              option.color.id === item[`${variant}`].id
          ) === undefined
        ) {
          isDisabled = true;
        } else {
          isDisabled = false;
        }
      }
      if (variant === "size" && selected_variant_id > 0) {
        if (
          filtered?.ProductVariants.find(
            (option) =>
              option.color.id === selected_variant_id &&
              option.size.id === item[`${variant}`].id
          ) === undefined
        ) {
          isDisabled = true;
        } else {
          isDisabled = false;
        }
      }
      uniqueData[item[`${variant}`].name] = {
        id: item[`${variant}`].id,
        name: item[`${variant}`].name,
        disabled: isDisabled,
      };
    });

    let convertData = Object.entries(uniqueData).map((item) => {
      return {
        value: item[1].id,
        label: item[1].name,
        disabled: item[1].disabled,
      };
    });

    return convertData;
  }

  const getProduct = async () => {
    const response = await api.product.index();

    if (response && response.data && response.data.length > 0) {
      setProduct(response.data);
    }
  };

  async function getCustomerAddress(address_id) {
    const response = await api.address.show(address_id);
    return response?.data?.city_id;
  }

  async function getDeliveryFee(address_id, items, type) {
    let destination = await getCustomerAddress(address_id);
    let totalWeightOrder = items
      .map((orderItem) => +orderItem.qty * +orderItem.weight)
      .reduce((partialSum, a) => partialSum + a, 0);

    if (destination && totalWeightOrder > 0) {
      const response = await api.rajaOngkir.domestic(
        destination,
        totalWeightOrder
      );

      if (response && response.data.length > 0) {
        let filtered = response.data[0]?.costs.filter(
          (i) => i.service == type
        )[0];

        if (!filtered) {
          setDeliveryFee(0);
          setNeedUpdateDeliveryFee(true);
          alert("Kurir tidak tersedia");
        } else {
          setDeliveryFee(filtered?.cost[0]?.value);
          setNeedUpdateDeliveryFee(false);
        }
      }
    }
  }

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    getAddress(salesOrder.customer_id);
    setDeliveryFee(salesOrder.delivery_fee);
    setSelectedCourier(salesOrder.courier_package);
    setInitialValues({
      id: salesOrder.id,
      customer_id: salesOrder.customer_id,
      address_id: salesOrder.address_id,
      note_order: salesOrder.note_order,
      courier_package: salesOrder.courier_package,
      items: salesOrder?.OrderItems?.map((orderItem) => ({
        id: orderItem.id,
        size: getUniqueData(products, orderItem.product_id, "size"),
        color: getUniqueData(products, orderItem.product_id, "color"),
        size_variant_id: orderItem.size_variant_id,
        color_variant_id: orderItem.color_variant_id,
        product_id: orderItem.product_id,
        imageUrl: orderItem?.master_product?.imageUrl,
        qty: orderItem.qty,
        old_qty: orderItem.old_qty,
        unit_price: orderItem.unit_price,
        discount_reseller: orderItem.discount_reseller,
        notes: orderItem.notes ? orderItem.notes : "",
        weight: orderItem.weight,
        old_product_id: orderItem.product_id,
        old_size_variant_id: orderItem.size_variant_id,
        old_color_variant_id: orderItem.color_variant_id,
      })),
      order_code: salesOrder?.order_code,
    });
  }, [salesOrder]);

  return (
    <MainLayout
      activeSidebarNavigation="order"
      breadcrumbItems={breadcrumbItems}
      pageTitle={
        <div className="flex items-center gap-2">
          {initialValues?.order_code}
        </div>
      }
      headingButtons={[
        {
          label:
            salesOrder.Invoice != null ? "Lihat Invoice" : "Generate Invoice",
          type: "menuButton",
          onClick: (e) =>
            navigate(
              salesOrder.Invoice != null
                ? "/invoice/detail/" + salesOrder.Invoice.id
                : "/invoice/create?customer_id=" + salesOrder.customer_id
            ),
          items: [
            {
              label: "Tambah Order",
              href: "/sales_order/create",
            },
            {
              label: "Print Surat Jalan",
              onClick: (e) => {
                setPayloads("singleDeliverySlip.create", {
                  data: salesOrder,
                });
                openModal("singleDeliverySlip.create");
              },
            },
          ],
        },
      ]}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
          >
            <FormHeader>
              <SelectSearchMolecule
                label="Customer"
                name="customer_id"
                options={customers?.map((customer) => ({
                  value: customer.id,
                  label:
                    customer.firstName +
                    " " +
                    customer.middleName +
                    " " +
                    customer.lastName,
                }))}
                onChange={(e) => {
                  handleChange(e);
                  getAddress(e.target.value);
                  setNeedUpdateDeliveryFee(true);
                }}
                onBlur={handleBlur}
                value={values.customer_id}
                errorMessage={
                  errors.customer_id &&
                  touched.customer_id &&
                  errors.customer_id
                }
                disabled={salesOrder?.invoice_id}
              />
              <SelectMolecule
                label="Alamat Kirim"
                name="address_id"
                options={addresses}
                onChange={(e) => {
                  handleChange(e);
                  setNeedUpdateDeliveryFee(true);
                }}
                onBlur={handleBlur}
                value={values.address_id}
                errorMessage={
                  errors.address_id && touched.address_id && errors.address_id
                }
              />
              <SelectMolecule
                label="Kurir"
                name="courier_package"
                options={[
                  {
                    value: "JAGOPACK",
                    label: "JAGOPACK",
                  },
                  {
                    value: "REGPACK",
                    label: "REGPACK",
                  },
                  {
                    value: "BIGPACK",
                    label: "BIGPACK",
                  },
                ]}
                value={values.courier_package}
                onChange={(e) => {
                  handleChange(e);
                  getDeliveryFee(
                    values.address_id,
                    values.items,
                    e.target.value
                  );
                  setSelectedCourier(e.target.value);
                  setNeedUpdateDeliveryFee(true);
                }}
              />
            </FormHeader>
            <div className="py-2 overflow-y-visible">
              <div className="w-full">
                <FieldArray name="items">
                  {(arrayHelpers) => (
                    <div className="">
                      <Table.Wrapper asIndex={false} overflow={false}>
                        <Table.Header>
                          <Table.HeaderRow>
                            <Table.Heading>Product</Table.Heading>
                            <Table.Heading>Size</Table.Heading>
                            <Table.Heading>Warna</Table.Heading>
                            {/* <Table.Heading>Catatan</Table.Heading> */}
                            <Table.Heading>Qty</Table.Heading>
                            <Table.Heading>
                              <p className="text-right">Harga Satuan</p>
                            </Table.Heading>
                            <Table.Heading>
                              <p className="text-right">Diskon</p>
                            </Table.Heading>
                            <Table.Heading>
                              <p className="text-right">Subtotal</p>
                            </Table.Heading>
                            <Table.Heading></Table.Heading>
                          </Table.HeaderRow>
                        </Table.Header>
                        <Table.Body>
                          {values.items?.map((orderDetail, key) => (
                            <Table.BodyRow key={key}>
                              <Table.Cell>
                                <div className="flex gap-2 items-center">
                                  {orderDetail?.imageUrl && (
                                    <ImageWithPreview
                                      size="lg"
                                      src={`${process.env.REACT_APP_AWS_LINK_URL}${orderDetail?.imageUrl}`}
                                    ></ImageWithPreview>
                                  )}
                                  <div className="w-4/5">
                                    <SelectMolecule
                                      hideLabel={true}
                                      name={`items[${key}].product_id`}
                                      options={products?.map((list) => ({
                                        value: list.id,
                                        label: list.code + ": " + list.name,
                                      }))}
                                      onChange={(e) => {
                                        console.log(values.items[`${key}`]);
                                        values.items[`${key}`].unit_price =
                                          products.filter(
                                            (i) => i.id == e.target.value
                                          )[0]["price"];
                                        values.items[`${key}`].weight =
                                          products.filter(
                                            (i) => i.id == e.target.value
                                          )[0]["weight"];
                                        values.items[`${key}`].imageUrl =
                                          products.filter(
                                            (i) => i.id == e.target.value
                                          )[0]["imageUrl"];

                                        // reset varian size dan color
                                        values.items[
                                          `${key}`
                                        ].color_variant_id = null;
                                        values.items[`${key}`].size_variant_id =
                                          null;

                                        handleChange(e);

                                        // karena produk diganti, harus kalkulasi ongkir ulang
                                        setNeedUpdateDeliveryFee(true);
                                      }}
                                      onBlur={handleBlur}
                                      value={orderDetail.product_id}
                                      errorMessage={
                                        getIn(
                                          errors,
                                          `items[${key}].product_id`
                                        ) &&
                                        getIn(
                                          touched,
                                          `items[${key}].product_id`
                                        ) &&
                                        getIn(
                                          errors,
                                          `items[${key}].product_id`
                                        )
                                      }
                                      absolute={true}
                                    />
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="w-24">
                                <SelectMolecule
                                  hideLabel={true}
                                  name={`items[${key}].size_variant_id`}
                                  // options={orderDetail.size}
                                  options={getUniqueData(
                                    products,
                                    values.items[`${key}`].product_id,
                                    "size",
                                    values.items[`${key}`].color_variant_id
                                  )}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  value={orderDetail.size_variant_id}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `items[${key}].size_variant_id`
                                    ) &&
                                    getIn(
                                      touched,
                                      `items[${key}].size_variant_id`
                                    ) &&
                                    getIn(
                                      errors,
                                      `items[${key}].size_variant_id`
                                    )
                                  }
                                  absolute={true}
                                />
                              </Table.Cell>
                              <Table.Cell className="max-w-xs">
                                <SelectMolecule
                                  hideLabel={true}
                                  name={`items[${key}].color_variant_id`}
                                  // options={orderDetail.color}
                                  options={getUniqueData(
                                    products,
                                    values.items[`${key}`].product_id,
                                    "color",
                                    values.items[`${key}`].size_variant_id
                                  )}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  value={orderDetail.color_variant_id}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `items[${key}].color_variant_id`
                                    ) &&
                                    getIn(
                                      touched,
                                      `items[${key}].color_variant_id`
                                    ) &&
                                    getIn(
                                      errors,
                                      `items[${key}].color_variant_id`
                                    )
                                  }
                                  absolute={true}
                                />
                              </Table.Cell>
                              {/* <Table.Cell>
                                <InputMolecule
                                  hideLabel={true}
                                  type="text"
                                  name={`items[${key}].notes`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.notes}
                                  errorMessage={
                                    getIn(errors, `items[${key}].notes`) &&
                                    getIn(touched, `items[${key}].notes`) &&
                                    getIn(errors, `items[${key}].notes`)
                                  }
                                />
                              </Table.Cell> */}
                              <Table.Cell className="w-24">
                                <InputMolecule
                                  hideLabel={true}
                                  type="number"
                                  name={`items[${key}].qty`}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setNeedUpdateDeliveryFee(true);
                                  }}
                                  onBlur={handleBlur}
                                  value={orderDetail.qty}
                                  errorMessage={
                                    getIn(errors, `items[${key}].qty`) &&
                                    getIn(touched, `items[${key}].qty`) &&
                                    getIn(errors, `items[${key}].qty`)
                                  }
                                  align="right"
                                />
                              </Table.Cell>
                              <Table.Cell className="w-48">
                                <InputMolecule
                                  hideLabel={true}
                                  type="number"
                                  name={`items[${key}].unit_price`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.unit_price}
                                  errorMessage={
                                    getIn(errors, `items[${key}].unit_price`) &&
                                    getIn(
                                      touched,
                                      `items[${key}].unit_price`
                                    ) &&
                                    getIn(errors, `items[${key}].unit_price`)
                                  }
                                  disabled={true}
                                  align="right"
                                />
                              </Table.Cell>
                              <Table.Cell className="w-32">
                                <InputMolecule
                                  hideLabel={true}
                                  type="text"
                                  name={`items[${key}].discount_reseller`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.discount_reseller}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `items[${key}].discount_reseller`
                                    ) &&
                                    getIn(
                                      touched,
                                      `items[${key}].discount_reseller`
                                    ) &&
                                    getIn(
                                      errors,
                                      `items[${key}].discount_reseller`
                                    )
                                  }
                                  align="right"
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <div className="text-right">
                                  <Typography.Currency
                                    number={
                                      orderDetail.qty *
                                      (orderDetail.unit_price -
                                        orderDetail.discount_reseller)
                                    }
                                  />
                                </div>
                              </Table.Cell>
                              <Table.Cell>
                                <IconButton
                                  icon={TrashIcon}
                                  onClick={(e) => arrayHelpers.remove(key)}
                                />
                              </Table.Cell>
                            </Table.BodyRow>
                          ))}
                        </Table.Body>
                      </Table.Wrapper>
                      <div>
                        <div className="">
                          <DottedOutlineButton
                            type="button"
                            onClick={(e) =>
                              arrayHelpers.push({
                                product_id: null,
                                size_variant_id: null,
                                color_variant_id: null,
                                qty: 1,
                                unitPrice: 0,
                                discount_reseller: 0,
                                weight: 0,
                              })
                            }
                          >
                            Tambah Item
                          </DottedOutlineButton>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
                <FormSummary>
                  <div className="flex-grow w-full md:max-w-lg order-last md:order-first">
                    <InputMolecule
                      label="Catatan"
                      type="text"
                      name="note_order"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.note_order}
                      errorMessage={
                        errors.note_order &&
                        touched.note_order &&
                        errors.note_order
                      }
                    />
                  </div>
                  <div className="h-full grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right">
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Subtotal:{" "}
                    </div>
                    <div className="pr-7">
                      <Typography.Currency
                        number={localHelpers.subtotal(values?.items)}
                      />
                    </div>

                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Ongkir ({" "}
                      {
                        <Typography.Currency
                          number={localHelpers.totalWeight(values?.items)}
                          fontSize="xs"
                        />
                      }{" "}
                      gram) :{" "}
                    </div>
                    <div className="flex items-center w-full justify-end">
                      <Typography.Currency number={delivery_fee} />
                      <IconButton
                        icon={ArrowPathIcon}
                        onClick={(e) => {
                          getDeliveryFee(
                            values.address_id,
                            values.items,
                            selectedCourier
                          );
                          // setNeedUpdateDeliveryFee(false);
                        }}
                        disabled={!needUpdateDeliveryFee}
                        color={needUpdateDeliveryFee ? "accent" : "secondary"}
                      />
                    </div>
                    <div className="text-xs uppercase text-gray-600 text-right font-bold">
                      Total:{" "}
                    </div>
                    <div className="pr-7">
                      <Typography.Currency
                        number={
                          +localHelpers.subtotal(values?.items) +
                          Number(delivery_fee)
                        }
                      />
                    </div>
                  </div>
                </FormSummary>
              </div>
            </div>

            <div className="fixed bottom-0 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
              <Button
                onClick={() => runValidations(values)}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Ubah Order"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

export default UpdateSalesOrderPage;
