import { useState, useEffect } from "react";
import { InputLabel, Input } from "app/components/atoms/form";

function DateRangeInputMolecule({
  label,
  placeholder,
  onFromChange = () => {},
  onToChange = () => {},
  onBlur,
  from,
  to,
}) {
  const [fromValue, setFromValue] = useState(from);
  const [toValue, setToValue] = useState(to);
  const fromOnChange = (event) => {
    setFromValue(event.target.value);
    onFromChange(event);
  };
  const toOnChange = (event) => {
    setToValue(event.target.value);
    onToChange(event);
  };

  useEffect(() => {
    if (from !== fromValue) {
      setFromValue(from);
    }
  }, [from]);
  useEffect(() => {
    if (to !== toValue) {
      setToValue(to);
    }
  }, [to]);

  return (
    <div className="flex gap-2 items-end">
      <div className="w-1/2">
        <InputLabel>{label}</InputLabel>
        <Input
          type="date"
          placeholder={placeholder}
          onChange={fromOnChange}
          onBlur={onBlur}
          value={fromValue}
        />
      </div>
      <div className="w-1/2">
        <Input
          type="date"
          placeholder={placeholder}
          onChange={toOnChange}
          onBlur={onBlur}
          value={toValue}
        />
      </div>
    </div>
  );
}

export default DateRangeInputMolecule;
