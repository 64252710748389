import { useApplicationModals } from "app/common/hooks";
import * as Yup from "yup";
import { useUpdateForm } from "app/integration/common/hooks";
import api from "app/integration/api";
import { useFetcher } from "app/integration/common/hooks";
import { useEffect } from "react";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { Button } from "app/components/atoms/button";

function UpdateMenuCategoryModal({ onSuccess = () => {} }) {
  const {
    showStates,
    getPayloads,
    closeModal,
  } = useApplicationModals();
  const payload = getPayloads('menuCategory.update');

  const handleClose = () => closeModal('menuCategory.update');
  const handleSuccess = () => {
    closeModal('menuCategory.update');
    onSuccess();
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        name: "",
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Nama Menu harus diisi"),
      }),
      onSubmit: async (values) => {
        await api.menuCategory.update(values.id, values.name);
      },
      onSuccess: handleSuccess,
    });

  const [
    menuCategory,
    setMenuCategory,
    loader,
    fetch
  ] = useFetcher({ modelName: 'menuCategory', payload });

  useEffect(() => {
    setInitialValues({
      id: menuCategory.id,
      name: menuCategory.name,
    });
  }, [menuCategory]);

  return (
    <ModalMolecule
      show={showStates["menuCategory.update"]}
      title="Ubah Jumlah Menu"
      onClose={handleClose}
    >
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-2" onSubmit={handleSubmit}>
            <InputMolecule
              label="Nama"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              errorMessage={errors.name && touched.name && errors.name}
            />
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Simpan</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default UpdateMenuCategoryModal;
