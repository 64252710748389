import axios from "../axios";
import h from "../helpers";

const productVariant = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("product-variants"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("product-variants/" + id));
    return data;
  },
  store: async (product_id, size_variant_id, color_variant_id, qty) => {
    const { data } = await axios.post(h.baseUrl("product-variants"), {
      product_id,
      size_variant_id,
      color_variant_id,
      qty,
    });
    return data;
  },
  update: async (id, size_variant_id, color_variant_id, qty) => {
    const { data } = await axios.put(h.baseUrl("product-variants/" + id), {
      size_variant_id,
      color_variant_id,
      qty,
    });
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(h.baseUrl("product-variants/" + id));
    return data;
  },
};

export default productVariant;
