import { useState, useEffect, Fragment } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import str from "lib/str";

function Input({
  type,
  name,
  placeholder,
  value = "",
  onChange,
  onBlur,
  onKeyUp,
  onKeyDown,
}) {
  const [localValue, setLocalValue] = useState(value);
  const localOnChange = (event) => {
    setLocalValue(event.target.value);
    onChange(event);
  };

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  const [hideValue, setHideValue] = useState(true);

  return (
    <Fragment>
      {type !== "password" && (
        <input
          className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-gray-900 placeholder-gray-300 shadow-sm focus:border-primary-600 focus:outline-none disabled:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-100 dark:placeholder-gray-500 autofill:dark:bg-gray-800 dark:disabled:bg-gray-800 focus:ring-1 focus:ring-primary-600"
          type={type}
          name={name}
          placeholder={placeholder}
          value={type === "date" ? str.dateForInput(localValue) : localValue}
          onChange={localOnChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
      )}
      {type === "password" && (
        <div className="relative">
          <input
            className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 pr-8 text-gray-900 placeholder-gray-300 shadow-sm focus:border-primary-600 focus:outline-none disabled:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-100 dark:placeholder-gray-500 autofill:dark:bg-gray-800 dark:disabled:bg-gray-800 focus:ring-1 focus:ring-primary-600"
            type={hideValue ? "password" : "text"}
            name={name}
            placeholder={placeholder}
            value={localValue}
            onChange={localOnChange}
            onBlur={onBlur}
          />
          <div
            className="absolute top-0 right-0 h-full px-2 flex items-center"
            onClick={(e) => setHideValue(!hideValue)}
          >
            {!hideValue && (
              <EyeIcon className="h-5 w-5 text-gray-300 dark:text-gray-900 hover:text-primary-600 cursor-pointer" />
            )}
            {hideValue && (
              <EyeSlashIcon className="h-5 w-5 text-gray-300 dark:text-gray-900 hover:text-primary-600 cursor-pointer" />
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Input;
