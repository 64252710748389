import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import { Select } from "app/components/atoms/select";
import { SelectSearchMolecule } from "app/components/molecules/select";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { useCollector, useFetcher } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function CreditHistoryPage() {
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();

  const [queryParams, setQueryParams] = useState({
    customer_id: "",
  });

  const [credit] = useFetcher({
    modelName: "report",
    methodName: "creditHistory",
    payload: { id: queryParams },
  });

  const [customers, setCustomers, customersLoader, collectCustomers] =
    useCollector({ modelName: "customer" });

  const creditType = (value) => {
    switch (value) {
      case 1:
        return "Tambah Kredit";
        break;
      case 2:
        return "Pengembalian Kredit";
        break;
      case 3:
        return "Pemakaian Kredit";
        break;

      default:
        return "-";
        break;
    }
  };

  const componentRef = useRef();

  const TableContent = (
    <>
      <Table.Header>
        <Table.HeaderRow>
          <Table.Heading>Tanggal</Table.Heading>
          <Table.Heading>Customer</Table.Heading>
          <Table.Heading>
            <p className="text-right">Jumlah Kredit</p>
          </Table.Heading>
          <Table.Heading>Deskripsi</Table.Heading>
          <Table.Heading>Tipe</Table.Heading>
          <Table.Heading>Admin</Table.Heading>
        </Table.HeaderRow>
      </Table.Header>
      <Table.Body>
        {credit?.length > 0 &&
          credit.map((item, index) => (
            <Table.BodyRow key={index}>
              <Table.Cell>
                <Typography.Date size="sm" format="dd MMM yyyy" date={item.createdAt} />
              </Table.Cell>
              <Table.Cell>
                <p>{item?.customer_info?.fullName}</p>
                <p className="text-gray-500 text-xs">
                  {item?.customer_info?.nickName}
                </p>
              </Table.Cell>
              <Table.Cell fontWeight="semibold">
                <span
                  className={
                    item.type === 1 ? "text-success-500" : "text-danger-500"
                  }
                >
                  <p className="text-right">
                    {item.type === 1 ? "" : "-"}
                    <Typography.Currency number={item?.amount} />
                  </p>
                </span>
              </Table.Cell>
              <Table.Cell>
                <p className="text-gray-700 font-light">{item.desc}</p>
              </Table.Cell>
              <Table.Cell>
                <Badge color={item.type === 1 ? "green" : "red"}>
                  {creditType(item.type)}
                </Badge>
              </Table.Cell>
              <Table.Cell>
                {item?.sales_info?.email ? (
                  <Badge color="yellow">{item?.sales_info?.email}</Badge>
                ) : (
                  <p className="text-xs">Sistem</p>
                )}
              </Table.Cell>
              {/* <Table.Cell>
            <div className="flex space-x-2">
              <BadgeButton>View</BadgeButton>
              <div className="flex">
                <IconButton icon={PencilSquareIcon} />
                <IconButton icon={TrashIcon} />
              </div>
            </div>
          </Table.Cell> */}
            </Table.BodyRow>
          ))}
      </Table.Body>
    </>
  );

  return (
    <MainLayout
      activeSidebarNavigation="report"
      activeSidebarChild="credit-history"
      breadcrumbItems={[
        { label: "Report", href: "#" },
        { label: "Kredit Histori", href: "#" },
      ]}
      pageTitle="Kredit Histori"
      headingButtons={[
        {
          label: "Print Report",
          type: "button",
          onClick: (e) => {
            setPayloads("report.view", {
              data: TableContent,
              title: "Report Kredit Histori",
            });
            openModal("report.view");
          },
        },
      ]}
    >
      <AppModals
        items={["report.view"]}
        onSuccess={{
          "report.view": () => fetch(),
        }}
      />
      <div ref={componentRef}>
        <Table.Container>
          <Table.UtilitiesWrapper>
            <Table.SearchBarWrapper>
              <Table.SearchBarItemWrapper>
                <SelectSearchMolecule
                  placeholder="Customer"
                  options={customers
                    ?.sort(function (a, b) {
                      if (
                        a.firstName?.toLowerCase() <
                        b.firstName?.toLowerCase()
                      )
                        return -1;
                      if (
                        a.firstName?.toLowerCase() >
                        b.firstName?.toLowerCase()
                      )
                        return 1;
                      return 0;
                    })
                    ?.map((customer) => ({
                      value: customer.id,
                      label:
                        customer.firstName +
                        " " +
                        customer.middleName +
                        " " +
                        customer.lastName,
                    }))}
                  onChange={(e) => {
                    setQueryParams({
                      ...queryParams,
                      customer_id: e.target.value,
                    });
                  }}
                />
              </Table.SearchBarItemWrapper>
            </Table.SearchBarWrapper>
            {/* <Table.UtilitiesButtonWrapper>
              <IconButton icon={PrinterIcon} />
            </Table.UtilitiesButtonWrapper> */}
          </Table.UtilitiesWrapper>
          <Table.Wrapper asIndex={true}>{TableContent}</Table.Wrapper>
        </Table.Container>
      </div>
    </MainLayout>
  );
}

export default CreditHistoryPage;
