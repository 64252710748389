import { useState, useEffect } from "react";

function InputIcon({
  type,
  name,
  placeholder,
  value = "",
  onChange = () => {},
  onBlur = () => {},
  icon,
}) {
  const [localValue, setLocalValue] = useState(value);
  const localOnChange = (event) => {
    setLocalValue(event.target.value);
    onChange(event);
  };

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  const Icon = icon;

  return (
    <div className="relative">
      <div className="h-full absolute top-0 left-0 p-1 mx-2 flex items-center">
        <Icon className="h-5 w-5 text-gray-500" />
      </div>
      <input
        className="block w-full rounded-lg border text-base border-gray-300 bg-white pl-11 pr-2.5 py-2.5 text-gray-900 placeholder-gray-300 shadow-sm focus:border-primary-600 focus:outline-none disabled:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-100 dark:placeholder-gray-500 autofill:dark:bg-gray-800 dark:disabled:bg-gray-800 focus:ring-1 focus:ring-primary-600"
        type={type}
        name={name}
        placeholder={placeholder}
        value={localValue}
        onChange={localOnChange}
        onBlur={onBlur}
      />
    </div>
  );
}

export default InputIcon;
