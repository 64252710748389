import Card from "./card";
import { XMarkIcon } from "@heroicons/react/20/solid";

function ClosableCard({
  children,
  asMainContent = false,
  grow = false,
  onClose = () => {},
  withBorder = true,
}) {
  return (
    <Card asMainContent={asMainContent} grow={grow} withBorder={withBorder}>
      <div
        className="absolute top-0 right-0 p-2 cursor-pointer"
        onClick={onClose}
      >
        <XMarkIcon className="text-gray-400 hover:text-gray-600 focus:ring-gray-300 dark:text-gray-500 dark:hover:text-gray-300 dark:focus:ring-gray-500 h-5 w-5" />
      </div>
      <div className="">{children}</div>
    </Card>
  );
}

export default ClosableCard;
