import clsx from "clsx";
import style from "style/height.module.css";

function Card({
  children,
  asMainContent = false,
  grow = false,
  withBorder = true,
  color = "white",
  fullWidth = false,
}) {
  return (
    <div
      className={clsx(
        "relative dark:bg-gray-900 rounded-lg p-2 text-gray-700 dark:text-gray-300",
        asMainContent
          ? clsx(style["card-as-main-content-wrapper-height"], "m-3")
          : "",
        grow ? "grow" : "",
        withBorder
          ? "border border-gray-300 dark:border-gray-700 shadow-md"
          : "",
        color === "white" ? "bg-white" : "",
        color === "gray" ? "bg-gray-100" : "bg-white",
        fullWidth ? "w-full" : ""
      )}
    >
      <div
        className={clsx(
          asMainContent
            ? clsx(style["card-as-main-content-body-height"], "overflow-y-auto")
            : ""
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default Card;
