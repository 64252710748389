import { FolderIcon } from "@heroicons/react/24/solid";
import { useApplicationModals } from "app/common/hooks";
import { Button, IconButton } from "app/components/atoms/button";
import Table from "app/components/atoms/table";
import { InputMolecule } from "app/components/molecules/form";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import api from "app/integration/api";
import { useUpdateForm } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik, getIn } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "style/height.module.css";
import * as Yup from "yup";

const breadcrumbItems = [
  { label: "Gudang", href: "/receive_order" },
  { label: "Penerimaan", href: "/receive_order" },
  { label: "Terima Barang", href: "#" },
];

function UpdateReceiveOrder() {
  let { id } = useParams();
  const navigate = useNavigate();
  let isError = false;

  const handleSuccess = (res) => {
    console.log(res);
    navigate("/receive_order");
  };

  const [isLoading, setIsloading] = useState(false);
  const [receive, setPurchase] = useState({});
  const [defaultStatus, setDefaultStatus] = useState(1);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // console.log(today);

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        receive_code: "",
        status: 2,
        items: [],
      },
      validationSchema: Yup.object().shape({
        items: Yup.array()
          .of(
            Yup.object().shape({
              qty: Yup.number().required("Qty harus diisi"),
            })
          )
          .min(1, "Minimal harus ada 1 Item yang dibeli."),
      }),
      onSubmit: async (values) => {
        try {
          setIsloading(true);
          let response = await api.receiveOrder.update(
            values.id,
            values.status
          );

          if (response && response.status == "success") {
            isError = false;
            setIsloading(false);
          }
          return response;
        } catch (error) {
          setPayloads("errorMessage.view", {
            title: "Update Peneriamaan Barang",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Server Error",
          });
          isError = true;
          openModal("errorMessage.view");
          setIsloading(false);
        }
      },
      onSuccess: () => !isError && handleSuccess(),
    });

  const { openModal, setPayloads } = useApplicationModals();
  const [currentErrors, setCurrentErrors] = useState([]);

  function runValidations(values) {
    validationSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
        setCurrentErrors([]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.name); // ValidationError
        console.log(err.errors);
        setCurrentErrors(err.errors);

        setPayloads("errorMessage.view", {
          title: "Tambah Order Error",
          data: err.errors,
        });
        openModal("errorMessage.view");
      });
  }

  const getReceive = async () => {
    try {
      console.log("id: ", id);
      const response = await api.receiveOrder.show(id);

      if (response && response.status == "success") {
        setPurchase(response.data);
      }
    } catch (err) {
      console.log("error: ", err);
    }
  };
  const updateOrderItem = async (item_id, qty) => {
    try {
      const response = await api.receiveOrderItem.update(item_id, qty);

      if (response && response.status == "success") {
        console.log("update qty berhasil");
      }
    } catch (err) {
      console.log("error: ", err);
    }
  };

  useEffect(() => {
    getReceive();
  }, []);

  useEffect(() => {
    setDefaultStatus(receive.status);
    setInitialValues({
      id: receive.id,
      status: 2,
      receive_code: receive.receive_code,
      items: receive?.ReceiveOrderItems?.map((item) => ({
        id: item.id,
        imageUrl: item?.master_product?.imageUrl,
        product_name: item?.master_product?.name,
        product_code: item?.master_product?.code,
        color: item?.master_color?.name,
        size: item?.master_size?.name,
        supplier_id: item.supplier_id,
        product_id: item.product_id,
        size_variant_id: item.size_variant_id,
        color_variant_id: item.color_variant_id,
        qty: item.qty,
      })),
    });
    console.log("initial: ", initialValues);
  }, [receive]);

  return (
    <MainLayout
      activeSidebarNavigation="receive"
      breadcrumbItems={breadcrumbItems}
      pageTitle={`Terima Barang - ${initialValues.receive_code}`}
      headingButtons={[]}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
          >
            <div className="py-2 overflow-visible">
              <div className="w-full">
                <FieldArray name="items">
                  {(arrayHelpers) => (
                    <div className="main-content-height overflow-y-auto pb-24">
                      <Table.Wrapper asIndex={false}>
                        <Table.Header>
                          <Table.HeaderRow>
                            <Table.Heading>Nama</Table.Heading>
                            <Table.Heading>Kode</Table.Heading>
                            <Table.Heading>Warna</Table.Heading>
                            <Table.Heading>Size</Table.Heading>
                            <Table.Heading>Jumlah Diterima</Table.Heading>
                            <Table.Heading></Table.Heading>
                          </Table.HeaderRow>
                        </Table.Header>
                        <Table.Body>
                          {values?.items?.length > 0 &&
                            values.items.map((item, key) => (
                              <Table.BodyRow key={key}>
                                <Table.Cell size="xs">
                                  <div className="flex items-center gap-2">
                                    {item?.imageUrl && (
                                      <ImageWithPreview
                                        src={`${process.env.REACT_APP_AWS_LINK_URL}${item?.imageUrl}`}
                                      ></ImageWithPreview>
                                    )}
                                    <p>{item.product_name}</p>
                                  </div>
                                </Table.Cell>
                                <Table.Cell size="xs">
                                  <p>{item.product_code}</p>
                                </Table.Cell>
                                <Table.Cell size="xs">
                                  <p>{item.color}</p>
                                </Table.Cell>
                                <Table.Cell size="xs">{item.size}</Table.Cell>
                                <Table.Cell size="xs">
                                  <div className="w-20">
                                    <InputMolecule
                                      hideLabel={true}
                                      type="number"
                                      name={`items[${key}].qty`}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      value={item.qty}
                                      errorMessage={
                                        getIn(errors, `items[${key}].qty`) &&
                                        getIn(touched, `items[${key}].qty`) &&
                                        getIn(errors, `items[${key}].qty`)
                                      }
                                      align="right"
                                      size="xs"
                                    />
                                  </div>
                                </Table.Cell>
                                <Table.Cell size="xs">
                                  {defaultStatus == 1 && (
                                    <IconButton
                                      icon={FolderIcon}
                                      onClick={(e) =>
                                        updateOrderItem(item.id, item.qty)
                                      }
                                    />
                                  )}
                                </Table.Cell>
                              </Table.BodyRow>
                            ))}
                        </Table.Body>
                      </Table.Wrapper>
                    </div>
                  )}
                </FieldArray>
              </div>
            </div>

            <div className="fixed bottom-0 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
              <Button
                onClick={() => runValidations(values)}
                type="submit"
                disabled={isLoading || defaultStatus == 2}
              >
                {/* Tambah Order{confirmedDuplicateOrder && " (duplikat)"} */}
                {isLoading ? "Loading..." : `Konfirmasi Penerimaan`}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

export default UpdateReceiveOrder;
