import {
  ChartPieIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  IdentificationIcon,
  ShoppingCartIcon,
  StarIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import SidebarOrganism from "app/components/organisms/sidebar";
import {
  default as ClientLogo,
  default as ClientLogoLandscape,
} from "img/erista-logo-landscape.png";
import { useState } from "react";

const navigations = [
  // {
  //   id: 'dashboard',
  //   label: 'Dashboard',
  //   icon: HomeIcon,
  //   href: '/dashboard',
  // },
  {
    id: "contacts",
    label: "Kontak",
    icon: IdentificationIcon,
    href: "/customer",
    children: [
      {
        id: "customer",
        label: "Customer",
        href: "/customer",
      },
      {
        id: "supplier",
        label: "Supplier",
        href: "/supplier",
      },
    ],
  },
  {
    id: "product",
    label: "Produk",
    href: "/product",
    icon: StarIcon,
  },
  {
    id: "sales",
    label: "Penjualan",
    icon: CurrencyDollarIcon,
    children: [
      {
        id: "order",
        label: "Sales Order",
        href: "/sales_order",
      },
      {
        id: "invoice",
        label: "Sales Invoice",
        href: "/invoice",
      },
    ],
  },
  {
    id: "purchase",
    label: "Pembelian",
    icon: ShoppingCartIcon,
    href: "/purchase_order",
  },
  {
    id: "warehouse",
    label: "Gudang",
    icon: TruckIcon,
    children: [
      {
        id: "receive",
        label: "Penerimaan",
        href: "/receive_order",
      },
      {
        id: "delivery",
        label: "Pengiriman",
        href: "/delivery_order",
      },
    ],
  },

  {
    id: "report",
    label: "Report",
    icon: ChartPieIcon,
    children: [
      {
        id: "daily-omzet",
        label: "Omzet",
        href: "/report/daily-omzet",
      },
      {
        id: "top-customer",
        label: "Top Customers",
        href: "/report/top-customer",
      },
      {
        id: "top-sales",
        label: "Top Sales",
        href: "/report/top-sales",
      },
      {
        id: "top-product",
        label: "Top Product",
        href: "/report/top-product",
      },
      {
        id: "top-supplier",
        label: "Top Supplier",
        href: "/report/top-supplier",
      },
      {
        id: "payment",
        label: "Pembayaran",
        href: "/report/payment",
      },
      {
        id: "credit-recap",
        label: "Kredit Rekap",
        href: "/report/credit-recap",
      },
      {
        id: "credit-history",
        label: "Kredit Histori",
        href: "/report/credit-history",
      },
    ],
  },
  {
    id: "settings",
    label: "Settings",
    icon: Cog6ToothIcon,
    children: [
      {
        id: "color-variant",
        label: "Varian Warna",
        href: "/color-variant",
      },
      {
        id: "size-variant",
        label: "Varian Size",
        href: "/size-variant",
      },
      {
        id: "category",
        label: "Kategori",
        href: "/category",
      },
    ],
  },
  // {
  //   id: "auth",
  //   label: "User",
  //   icon: UsersIcon,
  //   children: [
  //     {
  //       id: "user",
  //       label: "Daftar User",
  //       href: "/user",
  //     },
  //     {
  //       id: "role",
  //       label: "Roles & Permission",
  //       href: "/role",
  //     },
  //   ],
  // },
];

function AgnosticSidebar({ activeNavigation, activeChild }) {
  const sidebarLogo = {
    image: ClientLogoLandscape,
    collapsedImage: ClientLogo,
    imageAlt: "Erista Logo",
    collapsedImageAlt: "Erista Logo",
  };

  const calculateNavigations = (navigations) => {
    const calculatedNavigations = navigations.map((navigation) => {
      const active = navigation.id === activeNavigation;
      navigation.active = active;

      if (navigation.children) {
        navigation.expanded = active;
        navigation.children = navigation.children.map((child) => {
          child.active = child.id === activeChild;
          return child;
        });
      }

      return navigation;
    });
    return calculatedNavigations;
  };

  const [calculatedNavigations, setCalculatedNavigations] = useState(
    calculateNavigations(navigations)
  );

  const handleOnChange = (newCalculatedNavigations) => {
    setCalculatedNavigations(newCalculatedNavigations);
  };

  return (
    <SidebarOrganism
      sidebarLogo={sidebarLogo}
      navigations={calculatedNavigations}
      onChange={handleOnChange}
    />
  );
}

export default AgnosticSidebar;
