import MainLayoutHeadingUpperbar from './upperbar';
import MainLayoutHeadingTitleRow from './titleRow';

function MainLayoutHeading({ pageTitle, breadcrumbItems = [], buttons }) {
  return (
    <div className="p-2">
      <MainLayoutHeadingUpperbar breadcrumbItems={breadcrumbItems} buttons={buttons} />
      <MainLayoutHeadingTitleRow title={pageTitle} buttons={buttons} />
    </div>
  );
}

export default MainLayoutHeading;
