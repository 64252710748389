import { Fragment } from 'react';

function SidebarLogo({ image, collapsedImage, imageAlt, collapsedImageAlt }) {
  return (
    <Fragment>
      <img
        className="hidden h-10 w-full object-contain object-center xl:block"
        src={image}
        alt={imageAlt}
      />
      <img
        className="h-10 w-10 object-contain object-center xl:hidden"
        src={collapsedImage}
        alt={collapsedImageAlt}
      />
    </Fragment>
  );
}

export default SidebarLogo;
