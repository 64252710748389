import { useApplicationModals } from "app/common/hooks";
import { FormHeader } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import { useCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const breadcrumbItems = [
  { label: "Reports", href: "#" },
  { label: "Daily Omzet", href: "#" },
];

function ProductPage() {
  const navigate = useNavigate();
  const { openModal, setPayloads } = useApplicationModals();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const handleStartChange = (e) => setStart(e.target.value);
  const handleEndChange = (e) => setEnd(e.target.value);

  const [reports, setReport, loader, collect] = useCollector({
    modelName: "report",
    methodName: "dailyOmzet",
  });

  useEffect(() => {
    if (start && end && start != "" && end != "") {
      collect({ start, end });
    }
  }, [start, end]);

  return (
    <MainLayout
      activeSidebarNavigation="report"
      activeSidebarChild="daily-omzet"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Daily Omzet"
    >
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <FormHeader appearance="none">
              <InputMolecule
                label="Dari Tanggal"
                placeholder="Dari Tanggal"
                type="date"
                value={start}
                onChange={handleStartChange}
              />
              <InputMolecule
                label="Hingga Tanggal"
                placeholder="Hingga Tanggal"
                type="date"
                value={end}
                onChange={handleEndChange}
              />
            </FormHeader>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={false}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>Tanggal</Table.Heading>
              <Table.Heading align="right">Semua Tansaksi</Table.Heading>
              <Table.Heading align="right">Transaksi Lunas</Table.Heading>
              <Table.Heading align="right">Omzet</Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {reports?.map((dailyOmzet, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>{dailyOmzet?.date}</Table.Cell>
                <Table.Cell align="right"><Typography.Currency fontSize="base" type="number" number={dailyOmzet?.all_transaction}></Typography.Currency></Table.Cell>
                <Table.Cell align="right"><Typography.Currency fontSize="base" type="number" number={dailyOmzet?.done_transaction}></Typography.Currency></Table.Cell>
                <Table.Cell align="right"><Typography.Currency fontSize="base" type="number" number={dailyOmzet?.done_payment}></Typography.Currency></Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default ProductPage;
