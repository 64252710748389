import clsx from "clsx";
import { Card } from "app/components/atoms/card";

function FormHeader({ children, appearance = "" }) {
  return (
    <div className="print:hidden w-full">
      <Card
        color={appearance === "none" ? "white" : "gray"}
        fullWidth
        withBorder={appearance === "none" ? false : true}
      >
        <div
          className={clsx(
            "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 items-center gap-2"
          )}
        >
          {children}
        </div>
      </Card>
    </div>
  );
}

export default FormHeader;
