import axios from "../axios";
import h from "../helpers";

const category = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("variants"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("variants/" + id));
    return data;
  },
  store: async (name) => {
    const { data } = await axios.post(h.baseUrl("variants"), {
      name,
    });
    return data;
  },
  update: async (id, name) => {
    const { data } = await axios.put(h.baseUrl("variants/" + id), {
      name,
    });
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(h.baseUrl("variants/" + id));
    return data;
  },
};

export default category;
