import axios from "../axios";
import h from "../helpers";

const invoice = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("invoices"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("invoices/" + id));
    return data;
  },
  store: async (
    customer_id,
    total_amount,
    is_use_credit,
    invoice_date,
    due_date,
    credit_amount,
    status,
    note,
    items,
    old_invoice
  ) => {
    const { data } = await axios.post(h.baseUrl("invoices"), {
      customer_id,
      total_amount,
      is_use_credit,
      invoice_date,
      due_date,
      credit_amount,
      status,
      note,
      items,
      old_invoice,
    });
    return data;
  },
  update: async (
    id,
    customer_id,
    total_amount,
    is_use_credit,
    invoice_date,
    due_date,
    credit_amount,
    status,
    note,
    items,
    old_invoice
  ) => {
    const { data } = await axios.put(h.baseUrl("invoices/" + id), {
      customer_id,
      total_amount,
      is_use_credit,
      invoice_date,
      due_date,
      credit_amount,
      status,
      note,
      items,
      old_invoice,
    });
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(h.baseUrl("invoices/" + id));
    return data;
  },
};

export default invoice;
