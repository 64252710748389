import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import clsx from "clsx";

function SidebarNavigationCollapsibleChildItem({
  children,
  href = "#",
  active = false,
}) {
  return (
    <Disclosure.Button
      as={Link}
      to={href}
      className={clsx(
        active
          ? "text-gray-100 underline underline-offset-1"
          : "text-gray-100 hover:bg-primary-600",
        "group flex w-full items-center whitespace-nowrap rounded-md py-2 pl-12 pr-0 text-base font-normal text-gray-100 hover:bg-primary-600 hover:text-gray-100 transition-colors duration-500 ease-in-out"
      )}
    >
      {children}
    </Disclosure.Button>
  );
}

export default SidebarNavigationCollapsibleChildItem;
