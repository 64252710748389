import { CircleSpinner } from "app/components/atoms/loading";

function DottedOutlineButton({ type, children, disabled, loading, onClick = () => {} }) {
  return (
    <button
      className="border-2 border-dashed border-gray-200 bg-transparent text-primary-600 hover:text-primary-400 dark:border-gray-700 dark:text-primary-400 dark:hover:text-primary-600 inline-block cursor-pointer rounded-lg text-center text-base font-medium transition-colors duration-300 ease-in-out focus:outline-none px-4 py-2 w-full"
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {
        loading && (
          <span className="mr-1">
            <CircleSpinner />
          </span>
        )
      }
      {children}
    </button>
  );
}

export default DottedOutlineButton;
