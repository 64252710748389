import clsx from 'clsx';
import style from 'style/height.module.css';

function DirectoryWrapper({ children, asIndex }) {
  return (
    <div
      className={
        clsx(
          'overflow-y-auto print:h-full print:overflow-hidden',
          asIndex ? style['directory-as-index-height'] : '',
        )
      }
    >
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        {
          children
        }
      </div>
    </div>
  );
}

export default DirectoryWrapper;
