import axios from "../axios";
import h from "../helpers";

const report = {
  productForecast: async (params) => {
    const { data } = await axios.get(
      h.baseUrl("reports/product/product-forecast"),
      {
        params: { ...params },
      }
    );
    return data;
  },
  dailyOmzet: async (params) => {
    const { data } = await axios.get(h.baseUrl("reports/omzet/daily"), {
      params: { ...params },
    });
    return data;
  },
  topCustomer: async (params) => {
    const { data } = await axios.get(h.baseUrl("reports/top/customer"), {
      params: { ...params },
    });
    return data;
  },
  topSales: async (params) => {
    const { data } = await axios.get(h.baseUrl("reports/top/sales"), {
      params: { ...params },
    });
    return data;
  },
  topProduct: async (params) => {
    const { data } = await axios.get(h.baseUrl("reports/top/product"), {
      params: { ...params },
    });
    return data;
  },
  topSupplier: async (params) => {
    const { data } = await axios.get(h.baseUrl("reports/top/supplier"), {
      params: { ...params },
    });
    return data;
  },
  paymentReport: async (params) => {
    const { data } = await axios.get(h.baseUrl("reports/payment/report"), {
      params: { ...params },
    });
    return data;
  },
  creditRecap: async (params) => {
    const { data } = await axios.get(h.baseUrl("reports/credit/recap"), {
      params: { ...params },
    });
    return data;
  },
  creditHistory: async (params) => {
    const { data } = await axios.get(h.baseUrl("reports/credit/history"), {
      params: { ...params },
    });
    return data;
  },
};

export default report;
