import { useEffect } from "react";
import * as Yup from "yup";
import api from "app/integration/api";
import { useSelector, useDispatch } from "react-redux";
import { authenticate } from "app/features/authentication/authenticationSlice";
import { useNavigate } from "react-router-dom";

const useLoginForm = () => {
  const authenticated = useSelector(
    (state) => state.authentication.authenticated
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email harus diisi"),
    password: Yup.string().required("Password harus diisi"),
  });
  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      const { data } = await api.user.login(values.email, values.password);
      dispatch(authenticate(data.token));
    } catch (err) {
      if (err.response.status == 400) {
        setStatus("Email atau password salah");
      }
    }
    await setSubmitting(false);
  };

  useEffect(() => {
    if (authenticated) {
      navigate("/product");
    }
  }, [authenticated]);

  return {
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useLoginForm;
