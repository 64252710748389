import SimplePaginationButtons from "./simplePaginationButtons";

function ServerSidePaginationButtons({ paginationProps, params = {}, setParams = () => {} }) {
  return (
    <SimplePaginationButtons
      start={1}
      end={+paginationProps?.totalPages}
      currentPage={params.page}
      onPreviousClick={() => {
        setParams({ ...params, page: params.page - 1 });
      }}
      onNextClick={() => {
        setParams({ ...params, page: params.page + 1 });
      }}
      onPageClick={(page) => {
        setParams({ ...params, page });
      }}
    />
  );
}

export default ServerSidePaginationButtons;
