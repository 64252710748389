import clsx from 'clsx';

function TableSummaryWrapper({ children, toggleMargin = false }) {
  return (
    <div
      className={
        clsx(
          'flex flex-row items-center print:hidden space-x-2 p-2',
          toggleMargin ? 'my-3' : 'my-0'
        )
      }
    >
      {
        children
      }
    </div>
  );
}

export default TableSummaryWrapper;
