import axios from "../axios";
import h from "../helpers";

const product = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("products"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("products/" + id));
    return data;
  },
  store: async (
    name,
    code,
    variant_id,
    supplier_id,
    price,
    weight,
    colors,
    sizes,
    imageUrl
  ) => {
    const { data } = await axios.post(h.baseUrl("products"), {
      name,
      code,
      variant_id,
      supplier_id,
      price,
      weight,
      colors,
      sizes,
      imageUrl,
    });
    return data;
  },
  update: async (
    id,
    name,
    code,
    variant_id,
    supplier_id,
    price,
    weight,
    imageUrl
  ) => {
    const { data } = await axios.put(h.baseUrl("products/" + id), {
      name,
      code,
      variant_id,
      supplier_id,
      price,
      weight,
      imageUrl,
    });
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(h.baseUrl("products/" + id));
    return data;
  },
};

export default product;
