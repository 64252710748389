import MainLayoutHeadingButton from '../button';

function MainLayoutHeadingTitleRow({ title, buttons }) {
  return (
    <div className="flex h-10 flex-wrap items-center justify-between gap-2">
      <div className="flex-1 w-full">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100 sm:text-3xl md:text-4xl truncate text-ellipsis">
          {title}
        </h1>
      </div>
      <div className="mt-1 hidden flex-shrink-0 space-x-2 md:flex print:hidden lg:ml-4">
        <MainLayoutHeadingButton
          buttons={buttons}
        />
      </div>
    </div>
  );
}

export default MainLayoutHeadingTitleRow;
