import { useApplicationModals } from "app/common/hooks";
import { FormHeader } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import { useCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const breadcrumbItems = [
  { label: "Reports", href: "#" },
  { label: "Top Products", href: "#" },
];

function TopProduct() {
  const navigate = useNavigate();
  const { openModal, setPayloads } = useApplicationModals();
  const [query, setQuery] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    if (start && end && start != "" && end != "") {
      collect({ start, end });
    }
  }, [start, end]);

  const handleStartChange = (e) => setStart(e.target.value);
  const handleEndChange = (e) => setEnd(e.target.value);

  const handleQueryChange = (e) => setQuery(e.target.value);
  const [reports, setReport, loader, collect] = useCollector({
    modelName: "report",
    methodName: "topProduct",
  });

  useEffect(() => {
    collect({ dailyOmzet_search: query });
  }, [query.length >= 3]);

  return (
    <MainLayout
      activeSidebarNavigation="report"
      activeSidebarChild="top-product"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Top Products"
    >
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <FormHeader appearance="none">
              <InputMolecule
                label="Dari Tanggal"
                placeholder="Dari Tanggal"
                type="date"
                value={start}
                onChange={handleStartChange}
              />
              <InputMolecule
                label="Hingga Tanggal"
                placeholder="Hingga Tanggal"
                type="date"
                value={end}
                onChange={handleEndChange}
              />
            </FormHeader>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={false}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>Nama Produk</Table.Heading>
              <Table.Heading align="right">Jumlah Penjualan</Table.Heading>
              <Table.Heading align="right">Total Omzet</Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {reports?.map((dailyOmzet, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>
                  <div className="flex gap-2 items-center">
                    {dailyOmzet?.image && (
                      <ImageWithPreview
                        size="lg"
                        src={`${process.env.REACT_APP_AWS_LINK_URL}${dailyOmzet?.image}`}
                      ></ImageWithPreview>
                    )}
                    <div className="flex flex-col">
                      <span>{dailyOmzet?.product}</span>
                      <span>{dailyOmzet?.code}</span>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell align="right"><Typography.Currency fontSize="base" type="number" number={dailyOmzet?.total_sales}/></Table.Cell>
                <Table.Cell align="right"><Typography.Currency fontSize="base" type="number" number={dailyOmzet?.total_payment}/></Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default TopProduct;
