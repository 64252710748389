import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'

export default function ImageWithPreview({
    src = "",
    size = "sm"
  }) {

  function getSize(size) {
    switch (size) {
      case "lg":
        return "h-12 w-12";
        break;
      case "base":
        return "h-8 w-8";
        break;
      case "sm":
        return "h-5 w-5";
        break;
      case "xs":
        return "h-3 w-3";
        break;

      default:
        return "h-5 w-5";
        break;
    }
  }

  return (
    <div className={"relative group " + getSize(size)}>
      <img
        className={"object-cover " + getSize(size)}
        src={src}
      />
      <img className="h-96 w-96 z-20 hidden object-contain fixed top-12 right-8 group-hover:block" src={src} />
    </div>
  )
}