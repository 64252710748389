import { Menu, Transition } from "@headlessui/react";
import { Bars4Icon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import ClientLogo from "img/erista-logo.png";
import storage from "app/storage";

const profileNavigation = [
  { name: "Profil", href: "/profile", role: "Admin" },
  { name: "Logout", href: "/logout", role: "Admin" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function MainLayoutNavbar({ onSidebarButtonClick }) {
  const token = storage.authentication.token();
  const username = parseJwt(token).email;

  return (
    <div className="flex flex-1 flex-col justify-between print:hidden">
      <div className="sticky top-0 z-30 flex items-center w-full h-16 flex-shrink-0 border-b border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800 md:bg-white md:dark:bg-gray-800">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:border-gray-700 md:hidden "
          onClick={() => onSidebarButtonClick()}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars4Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Profile dropdown */}
        <Menu as="div" className="relative mx-4 flex-grow flex justify-end">
          <div className="px-2">
            <Menu.Button className="flex h-14 max-w-xs items-center gap-2 text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
              <span className="sr-only">Open Church menu</span>
              <img
                className="h-14 w-14 ml-2 bg-white object-contain text-gray-300 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-600"
                src={ClientLogo}
                alt=""
              />
              <div className="flex flex-col px-2 text-left">
                <span className="text-xs font-semibold text-gray-700 dark:text-gray-200">
                  ERP DEMO
                </span>
                <span className="text-xs font-normal text-gray-500 dark:text-gray-400">
                  {username}
                </span>
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 top-14 z-50 w-32 origin-top-right rounded-md bg-white py-1 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800 md:w-48">
              {profileNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={classNames(
                        active ? "bg-gray-100 dark:bg-gray-800" : "",
                        "block px-4 py-2 text-sm text-gray-700 dark:text-gray-200"
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

export default MainLayoutNavbar;
