import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import {
  BadgeButton,
  IconButton,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import { Input } from "app/components/atoms/input";
import { FormHeader } from "app/components/atoms/layout";
import { SelectSearch } from "app/components/atoms/select";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import {
  useCollector,
  usePaginatedCollector,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const breadcrumbItems = [
  { label: "Purchase", href: "#" },
  { label: "Order", href: "#" },
];

function PurchaseOrderPage() {
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();

  const [params, setParams] = useState({
    search: "",
    createdAt: null,
    supplier_id: null,
    status: null,
    is_valid_order: null,
    page: 1,
    limit: 50,
  });

  const [
    purchaseOrders,
    setPurchaseOrders,
    purchaseOrdersLoader,
    collectPurchaseOrders,
    purchaseOrderPaginationProps,
  ] = usePaginatedCollector({ modelName: "purchaseOrder", params: params });

  const [suppliers, setMSuppliers, suppliersLoader, collectMSuppliers] =
    useCollector({ modelName: "supplier" });

  const statusPO = (status) => {
    switch (status) {
      case 1:
        return "Draft";
        break;
      case 2:
        return "Confirm";
        break;

      default:
        break;
    }
  };

  return (
    <MainLayout
      activeSidebarNavigation="purchase"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Daftar Pembelian"
      headingButtons={[
        {
          label: "Tambah Pembelian",
          type: "routerButton",
          to: "/purchase_order/create",
        },
      ]}
    >
      {/* <AppModals
        items={["purchaseOrder.delete"]}
        onSuccess={{
          "purchaseOrder.delete": () => collectPurchaseOrders(),
        }}
      /> */}
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <FormHeader appearance="none">
              <SelectSearch
                label="Supplier"
                placeholder="Filter Supplier"
                options={suppliers
                  ?.sort(function (a, b) {
                    if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase())
                      return -1;
                    if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase())
                      return 1;
                    return 0;
                  })
                  .map((supplier) => ({
                    value: supplier.id,
                    label:
                      supplier.firstName +
                      " " +
                      supplier.middleName +
                      " " +
                      supplier.lastName +
                      (supplier.nickName ? " (" + supplier.nickName + ")" : ""),
                  }))}
                value={params.supplier_id}
                onChange={(e) =>
                  setParams({ ...params, supplier_id: e.target.value })
                }
              />
              <Input
                label="Tanggal Kirim"
                placeholder="Tanggal Kirim"
                type="date"
                value={params.createdAt}
                onChange={(e) =>
                  setParams({ ...params, createdAt: e.target.value })
                }
              />
              {/* <Select
                label="Status PO"
                placeholder=""
                options={[
                  {
                    value: null,
                    label: "Semua Status",
                  },
                  {
                    value: 1,
                    label: "Draft",
                  },
                  {
                    value: 2,
                    label: "Confirm",
                  },
                ]}
                value={params.is_valid_order}
                onChange={(e) =>
                  setParams({ ...params, is_valid_order: e.target.value })
                }
              /> */}
            </FormHeader>
          </Table.SearchBarWrapper>
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={true}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>No.</Table.Heading>
              <Table.Heading>Supplier</Table.Heading>
              <Table.Heading>Salesperson</Table.Heading>
              <Table.Heading>Status</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {purchaseOrders?.map((purchaseOrder, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell className="">
                  <p>{purchaseOrder.purchase_code}</p>
                  <Typography.Date size="xs" format="dd MMM yyyy" date={purchaseOrder.createdAt} />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    {purchaseOrder.MSupplier?.firstName}{" "}
                    {purchaseOrder.MSupplier?.middleName}{" "}
                    {purchaseOrder.MSupplier?.lastName}
                    {purchaseOrder.delivery_to !== purchaseOrder.supplier_id &&
                      purchaseOrder.MSupplier?.nickName !== null &&
                      purchaseOrder.MSupplier?.nickName !== "" &&
                      "(" + purchaseOrder.MSupplier?.nickName + ")"}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p className="text-left">
                    {purchaseOrder?.sales_info?.email}
                  </p>
                  {/* <p className="text-center text-danger-500 text-xs font-extralight">
                    (hardcoded)
                  </p> */}
                </Table.Cell>

                <Table.Cell>
                  {purchaseOrder.Invoice != null ? (
                    <BadgeButton
                      onClick={(e) =>
                        navigate("/invoice/detail/" + purchaseOrder?.invoice_id)
                      }
                    >
                      Invoice
                    </BadgeButton>
                  ) : (
                    <Badge
                      color={purchaseOrder.status != 1 ? "green" : "yellow"}
                    >
                      {statusPO(purchaseOrder.status)}
                    </Badge>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex space-x-2">
                    <div className="flex">
                      <IconButton
                        icon={PencilSquareIcon}
                        onClick={(e) => {
                          navigate(
                            "/purchase_order/update/" + purchaseOrder.id
                          );
                        }}
                      />
                      {/* <IconButton
                        icon={TrashIcon}
                        onClick={(e) => {
                          setPayloads("purchaseOrder.delete", {
                            id: purchaseOrder.id,
                          });
                          openModal("purchaseOrder.delete");
                        }}
                      /> */}
                    </div>
                  </div>
                </Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
        <Table.PaginationWrapper>
          <ServerSidePaginationButtons
            paginationProps={purchaseOrderPaginationProps}
            params={params}
            setParams={setParams}
          />
        </Table.PaginationWrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default PurchaseOrderPage;
