import axios from "../axios";
import h from "../helpers";

const deliveryOrder = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("delivery-orders"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("delivery-orders/" + id));
    return data;
  },
  update: async ({
    id,
    status = null,
    packing_picture = null,
    resi_number = "",
  }) => {
    console.log("on update: ", id, status);

    let bodyFormData = new FormData();
    status && bodyFormData.append("status", status);
    resi_number != "" && bodyFormData.append("resi_number", resi_number);
    packing_picture &&
      bodyFormData.append("packing_picture[]", packing_picture);

    console.log("form: ", bodyFormData);

    const { data } = await axios.put(
      h.baseUrl("delivery-orders/packing/" + id),
      bodyFormData
    );
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(h.baseUrl("delivery-orders/" + id));
    return data;
  },
};

export default deliveryOrder;
