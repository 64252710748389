import axios from "../axios";
import h from "../helpers";

const colorVariant = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("color-variants"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("color-variants/" + id));
    return data;
  },
  store: async (name) => {
    const { data } = await axios.post(h.baseUrl("color-variants"), {
      name,
    });
    return data;
  },
  update: async (id, name) => {
    const { data } = await axios.put(h.baseUrl("color-variants/" + id), {
      name,
    });
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(h.baseUrl("color-variants/" + id));
    return data;
  },
};

export default colorVariant;
