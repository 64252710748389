import Wrapper from './wrapper';
import Body from './body';
import Root from './root';
import Item from './item';

export default {
  Wrapper,
  Body,
  Root,
  Item,
}

export {
  Wrapper as BreadcrumbWrapper,
  Body as BreadcrumbBody,
  Root as BreadcrumbRoot,
  Item as BreadcrumbItem,
}
