
function SidebarWrapper({ children }) {
  return (
    <div className="z-50 flex flex-grow flex-col overflow-hidden print:hidden">
      {
        children
      }
    </div>
  )
}

export default SidebarWrapper;
