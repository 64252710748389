import axios from "../axios";
import h from "../helpers";

const rajaOngkir = {
  province: async (params) => {
    const { data } = await axios.get(
      h.baseUrl("raja-ongkirs/location/province"),
      {
        params: { ...params },
      }
    );
    return data;
  },
  city: async (params) => {
    const { data } = await axios.get(h.baseUrl("raja-ongkirs/location/city"), {
      params: { ...params },
    });
    return data;
  },
  subdistrict: async (params) => {
    const { data } = await axios.get(
      h.baseUrl("raja-ongkirs/location/subdistrict"),
      {
        params: { ...params },
      }
    );
    return data;
  },

  domestic: async (destination, weight, courier = "lion") => {
    const { data } = await axios.post(h.baseUrl("raja-ongkirs/cost/domestic"), {
      destination,
      weight,
      courier,
    });
    return data;
  },
};

export default rajaOngkir;
