import axios from "../axios";
import h from "../helpers";

const salesOrder = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("sales-orders"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("sales-orders/" + id));
    return data;
  },
  store: async (
    customer_id,
    address_id,
    note_order,
    delivery_fee,
    courier_package,
    items
  ) => {
    const { data } = await axios.post(h.baseUrl("sales-orders"), {
      customer_id,
      address_id,
      note_order,
      delivery_fee,
      courier_package,
      items,
    });
    return data;
  },
  update: async (
    id,
    customer_id,
    address_id,
    delivery_fee,
    note_order,
    courier_package,
    items
  ) => {
    const { data } = await axios.put(h.baseUrl("sales-orders/" + id), {
      customer_id,
      address_id,
      delivery_fee,
      note_order,
      courier_package,
      items,
    });
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(h.baseUrl("sales-orders/" + id));
    return data;
  },
};

export default salesOrder;
