import clsx from 'clsx';

function DirectoryRow({ children, hidden = false, active = false, onClick = () => {} }) {
  return (
    <div
      className={
        clsx(
          'transition-colors ease-in-out duration-300 cursor-pointer',
          hidden ? 'bg-gray-200 dark:bg-gray-600' : 'dark:hover:bg-gray-900 hover:bg-gray-50',
          'px-4 py-4 print:py-2 text-sm font-normal truncate',
          active ? 'bg-primary-600 hover:bg-primary-600 dark:bg-primary-400 dark:hover:bg-primary-400 text-white' : '',
        )
      }
      onClick={onClick}
    >
      {
        children
      }
    </div>
  );
}

export default DirectoryRow;
