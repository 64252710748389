
function fontSize(size) {
  return {
    'xs': 'text-xs',
    'sm': 'text-sm',
    'base': 'text-base',
    'lg': 'text-lg',
    'xl': 'text-xl',
    '2xl': 'text-2xl',
  }[size]
}

export default {
  fontSize,
}

export {
  fontSize,
}
