import { useApplicationModals } from "app/common/hooks";
import { useEffect } from "react";
import { useDeleteForm } from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik } from "formik";
import { Button } from "app/components/atoms/button";

function NewReceiveOrderItem({ onSuccess = () => {} }) {
  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("receiveOrderItem.update");

  const handleClose = () => closeModal("receiveOrderItem.update");
  const handleSuccess = () => {
    closeModal("receiveOrderItem.update");
    onSuccess(true, payload.id, payload.qty);
  };
  const handleNextAction = () => {
    closeModal("receiveOrderItem.update");
    onSuccess(false, payload.id, payload.qty);
  };

  return (
    <ModalMolecule
      show={showStates["receiveOrderItem.update"]}
      title="Konfirmasi Penerimaan Barang Partial"
      onClose={handleClose}
    >
      <div>
        Jumlah produk yang diterima kurang dari yang dipesan, apakah sisa produk
        akan dikirim lagi oleh supplier?
        <p className="text-sm text-gray-500">
          Jika Ya, maka sistem akan membuat catatan Penerimaan Barang baru.
        </p>
        <div className="flex gap-2 fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
          <Button onClick={handleNextAction}>Tidak</Button>
          <Button onClick={handleSuccess}>Ya</Button>
        </div>
      </div>
    </ModalMolecule>
  );
}

export default NewReceiveOrderItem;
