import axios from "../axios";
import h from "../helpers";

const customer = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("customers"), {
      params: { ...params, order: "firstName", sort: "asc" },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("customers/" + id));
    return data;
  },
  store: async (
    firstName,
    middleName,
    lastName,
    nickName,
    first_bank,
    second_bank,
    third_bank,
    email,
    phone,
    customer_address
  ) => {
    const { data } = await axios.post(h.baseUrl("customers"), {
      firstName,
      middleName,
      lastName,
      nickName,
      first_bank,
      second_bank,
      third_bank,
      email,
      phone,
      customer_address,
    });
    return data;
  },
  update: async (
    id,
    firstName,
    middleName,
    lastName,
    nickName,
    first_bank,
    second_bank,
    third_bank,
    email,
    phone,
    customer_address
  ) => {
    const { data } = await axios.put(h.baseUrl("customers/" + id), {
      firstName,
      middleName,
      lastName,
      nickName,
      first_bank,
      second_bank,
      third_bank,
      email,
      phone,
      customer_address,
    });
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(h.baseUrl("customers/" + id));
    return data;
  },
};

export default customer;
