import axios from "../axios";
import h from "../helpers";

const uploadImage = {
  product: async ({ image }) => {
    console.log("on update: ", image);

    let bodyFormData = new FormData();
    image && bodyFormData.append("image", image);

    console.log("form: ", bodyFormData);

    const { data } = await axios.post(
      h.baseUrl("upload/global/the-vow-frontend/product"),
      bodyFormData
    );
    return data;
  },
};

export default uploadImage;
