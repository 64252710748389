import { Fragment } from "react";
import CreateCategoryModal from "./createCategory";
import UpdateCategoryModal from "./updateCategory";
import DeleteCategoryModal from "./deleteCategory";
import CreateMenuCategoryModal from "./createMenuCategory";
import UpdateMenuCategoryModal from "./updateMenuCategory";
import DeleteMenuCategoryModal from "./deleteMenuCategory";
import CreateDailyMenuModal from "./createDailyMenu";
import UpdateDailyMenuModal from "./updateDailyMenu";
import DeleteDailyMenuModal from "./deleteDailyMenu";
import CreateCustomerModal from "./createCustomer";
import DeleteCustomerModal from "./deleteCustomer";
import CreateSupplierModal from "./createSupplier";
import DeleteSupplierModal from "./deleteCustomer";
import DeleteSalesOrderModal from "./deleteSalesOrder";
import DeleteInvoiceModal from "./deleteInvoice";
import CreatePaymentModal from "./createPayment";
import UpdatePaymentModal from "./updatePayment";
import DeletePaymentModal from "./deletePayment";
import ConfirmPaymentModal from "./confirmPayment";
import CreateCreditModal from "./createCredit";
import ViewInvoiceModal from "./viewInvoice";
import ViewReportModal from "./viewReport";
import ViewErrorMessageModal from "./viewErrorMessage";

import CreateColorVariantModal from "./createColorVariant";
import UpdateColorVariantModal from "./updateColorVariant";
import DeleteColorVariantModal from "./deleteColorVariant";
import CreateSizeVariantModal from "./createSizeVariant";
import UpdateSizeVariantModal from "./updateSizeVariant";
import DeleteSizeVariantModal from "./deleteSizeVariant";
import CreateProductModal from "./createProduct";
import UpdateProductModal from "./updateProduct";
import UpdateProductVariantModal from "./updateProductVariant";
import CreateProductVariantModal from "./createProductVariant";
import CreateDeliveryPackingModal from "./createPackingOrder";
import CreateDeliveryStickerModal from "./createDeliverySticker";
import ViewSuccessMsgModal from "./viewSuccessMessage";

import NewReceiveOrderItem from "./confirmCreateReceiveOrder";

const registeredItems = {
  "receiveOrderItem.update": NewReceiveOrderItem,
  "deliveryOrder.packing": CreateDeliveryPackingModal,
  "deliveryOrder.sticker": CreateDeliveryStickerModal,
  "product.create": CreateProductModal,
  "product.update": UpdateProductModal,
  "product.delete": CreateSizeVariantModal,
  "productVariant.create": CreateProductVariantModal,
  "productVariant.update": UpdateProductVariantModal,
  "productVariant.delete": UpdateProductVariantModal,
  "sizeVariant.create": CreateSizeVariantModal,
  "sizeVariant.update": UpdateSizeVariantModal,
  "sizeVariant.delete": DeleteSizeVariantModal,
  "colorVariant.create": CreateColorVariantModal,
  "colorVariant.update": UpdateColorVariantModal,
  "colorVariant.delete": DeleteColorVariantModal,
  "category.create": CreateCategoryModal,
  "category.update": UpdateCategoryModal,
  "category.delete": DeleteCategoryModal,
  "menuCategory.create": CreateMenuCategoryModal,
  "menuCategory.update": UpdateMenuCategoryModal,
  "menuCategory.delete": DeleteMenuCategoryModal,
  "dailyMenu.create": CreateDailyMenuModal,
  "dailyMenu.update": UpdateDailyMenuModal,
  "dailyMenu.delete": DeleteDailyMenuModal,
  "customer.create": CreateCustomerModal,
  "customer.delete": DeleteCustomerModal,
  "supplier.create": CreateSupplierModal,
  "supplier.delete": DeleteSupplierModal,
  "salesOrder.delete": DeleteSalesOrderModal,
  "invoice.delete": DeleteInvoiceModal,
  "payment.create": CreatePaymentModal,
  "payment.update": UpdatePaymentModal,
  "payment.delete": DeletePaymentModal,
  "payment.confirm": ConfirmPaymentModal,
  "credit.create": CreateCreditModal,
  "invoice.view": ViewInvoiceModal,
  "report.view": ViewReportModal,
  "errorMessage.view": ViewErrorMessageModal,
  "successMessage.view": ViewSuccessMsgModal,
};

function AppModals({ items = [], onSuccess = {} }) {
  return (
    <Fragment>
      {items.map((item, key) => {
        const Component = registeredItems[item];
        return <Component key={key} onSuccess={onSuccess[item]} />;
      })}
    </Fragment>
  );
}

export default AppModals;
