import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import { Button } from "app/components/atoms/button";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import { ModalMolecule } from "app/components/molecules/modal";
import { useFetcher } from "app/integration/common/hooks";
import ClientLogo from "img/erista-logo.png";
import { Fragment, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { filter as _filter } from "underscore";

function ViewInvoiceModal({ onSuccess = () => {} }) {
  const [queryParams, setQueryParams] = useState({
    // delivery_slip: 1,
    order_ids: "",
  });

  const [report] = useFetcher({
    modelName: "invoice",
    payload: { id: queryParams },
  });

  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("invoice.view");
  if (payload?.data?.items?.length > 0) {
    console.log("payload: ", payload);
    console.log("data: ", payload.data);
  }
  const invoice = payload?.data;

  const localHelpers = {
    subtotal: (customerId) => {
      return _filter(invoice?.sales_orders, {
        customer_id: customerId,
        invoice_id: invoice?.id,
      })
        .map((salesOrder) => {
          return salesOrder?.OrderItems.map(
            (orderDetail) =>
              orderDetail.qty * (orderDetail.unit_price - orderDetail.discount_reseller) 
          ).reduce((partialSum, a) => partialSum + a, 0);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    shipmentCost: (customerId) => {
      return _filter(invoice?.sales_orders, {
        customer_id: customerId,
        invoice_id: invoice?.id,
      })
        .map((salesOrder) => {
          return Number(salesOrder.delivery_fee);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    shipmentCostDiscount: (customerId) => {
      return _filter(invoice?.sales_orders, {
        customer_id: customerId,
        invoice_id: invoice?.id,
      })
        .map((salesOrder) => {
          return Number(salesOrder.delivery_fee_discount);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    total: (customerId) => {
      return (
        +localHelpers.subtotal(customerId) +
        +localHelpers.shipmentCost(customerId)
        // +localHelpers.shipmentCostDiscount(customerId)
      );
    },
    payment: (payments) => {
      var total = 0;
      payments?.map((payment, key) => (total += Number(payment.total_payment)));
      return total;
    },
  };

  const handleClose = () => closeModal("invoice.view");
  const handleSuccess = () => {
    closeModal("invoice.view");
    onSuccess();
  };

  useEffect(() => {
    if (payload?.data?.length > 0) {
      console.log("payload: ", payload.data);
      const order_ids = payload.data.map((item) => {
        return item.order_id;
      });

      setQueryParams({
        ...queryParams,
        order_ids: order_ids.join(","),
      });
    } else {
      console.log("payload kosong");
    }
  }, [payload]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <ModalMolecule
      show={showStates["invoice.view"]}
      title="View Invoice"
      onClose={handleClose}
      size="xl"
    >
      <div
        ref={componentRef}
        className="space-y-1 text-sm border border-gray-100 shadow-xl print:shadow-none print:border-none"
      >
        <div>
          <div className="w-full bg-white flex justify-between px-8 py-4">
            <div>
              <h1 className="text-4xl font-bold text-black">Invoice</h1>
              <p className="text-sm font-medium text-yellow-500">
                {invoice?.inv_code}
              </p>
            </div>
            <img alt="Logo Erista" src={ClientLogo} className="w-24"></img>
          </div>
          <div className="px-8 py-4 flex justify-between">
            <div>
              <p className="text-lg font-bold">{invoice?.customerName}</p>
              <p className="text-sm font-medium text-gray-500">
                {invoice?.customerPhone}
              </p>
            </div>
            {/* <div className="">
              <Typography.Date date={invoice?.invoiceDate}></Typography.Date>
            </div> */}
          </div>
        </div>
        <div className="px-8 py-4">
          <Table.Wrapper asIndex={false}>
            <Table.Header>
              <Table.HeaderRow>
                <Table.Heading>Order</Table.Heading>
                <Table.Heading className="text-right">Harga</Table.Heading>
                <Table.Heading className="text-right">Diskon</Table.Heading>
                <Table.Heading className="text-right">Qty</Table.Heading>
                <Table.Heading className="text-right">Ongkir</Table.Heading>
                <Table.Heading className="text-right">Subtotal</Table.Heading>
              </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
              {/* {_filter(salesOrders, {
                            customer_id: values.customerId,
                          })?.map((salesOrder, key) => ( */}
              {invoice?.sales_orders?.map((salesOrder, key) => (
                <Fragment key={key}>
                  <Table.BodyRow>
                    <Table.Cell size="sm" fontWeight="semibold">
                      {salesOrder.order_code}
                    </Table.Cell>
                    <Table.Cell size="sm"></Table.Cell>
                    <Table.Cell size="sm"></Table.Cell>
                    <Table.Cell size="sm"></Table.Cell>
                    <Table.Cell size="sm" className="text-right">
                      <Typography.Currency number={salesOrder.delivery_fee} />
                    </Table.Cell>
                    <Table.Cell size="sm" className="text-right">
                      <Typography.Currency number={salesOrder.delivery_fee} />
                    </Table.Cell>
                  </Table.BodyRow>
                  {salesOrder.OrderItems.map((orderItem, nestedKey) => (
                    <Fragment key={nestedKey}>
                      <Table.BodyRow>
                        <Table.Cell size="sm">
                          <div className="flex items-center gap-2">
                            {orderItem?.master_product?.imageUrl && (
                              <ImageWithPreview size="lg" src={`${process.env.REACT_APP_AWS_LINK_URL}${orderItem?.master_product?.imageUrl}`}></ImageWithPreview>
                            )}
                            <div className="flex flex-col gap-1">
                              <p className="whitespace-nowrap">{orderItem.master_product.name}</p>
                              <span>{orderItem.master_product.code}</span>
                            </div>
                            <div className="flex flex-wrap gap-1">
                              <Badge color="gray">{orderItem.master_color.name}</Badge>
                              <Badge color="gray">{orderItem.master_size.name}</Badge>
                            </div>
                          </div>
                        </Table.Cell>
                        <Table.Cell size="sm" className="text-right">
                          <Typography.Currency number={orderItem.unit_price} />
                        </Table.Cell>
                        <Table.Cell size="sm" className="text-right">
                          <Typography.Currency number={orderItem.discount_reseller} />
                        </Table.Cell>
                        <Table.Cell size="sm" className="text-right">
                          <Typography.Currency number={orderItem.qty} />
                        </Table.Cell>
                        <Table.Cell size="sm" className="text-right">
                        
                        </Table.Cell>
                        <Table.Cell size="sm" className="text-right">
                          <Typography.Currency
                            number={+orderItem.qty * (+orderItem.unit_price - orderItem.discount_reseller)}
                          />
                        </Table.Cell>
                      </Table.BodyRow>
                      {/* {orderItem.variants.map((item, nestedNestedKey) => (
                        <Table.BodyRow key={nestedNestedKey}>
                          <Table.Cell size="sm" className="pl-11">
                            {item.name}
                          </Table.Cell>
                          <Table.Cell size="sm" className="text-right">
                            {orderItem.qty}
                          </Table.Cell>
                          <Table.Cell size="sm" className="text-right">
                            <Typography.Currency number={item.price} />
                          </Table.Cell>
                          <Table.Cell className="text-right">
                            <Typography.Currency
                              number={item.price * orderItem.qty}
                            />
                          </Table.Cell>
                        </Table.BodyRow>
                      ))} */}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </Table.Body>
          </Table.Wrapper>
        </div>
        <hr />
        <div className="w-full flex flex-col md:flex-row justify-between items-end px-10 py-4 gap-8 md:gap-0">
          <div className="w-full md:w-1/2 text-gray-700">
            <h3 className="uppercase text-[10px] tracking-tight leading-tight font-medium ">
              Keterangan Pembayaran
            </h3>
            <p className="text-[10px] tracking-tight leading-tight font-extralight mt-2">
              Untuk melakukan pembayaran, harap transfer ke:
            </p>
            <div className="grid grid-cols-2 text-[10px] tracking-tight leading-tight w-fit gap-y-1 mt-1 pl-2">
              <span className="">Bank:</span>
              <span>BCA</span>
              <span className="">Nama:</span>
              <span>ERISTA</span>
              <span className="">Rekening:</span>
              <span>123456789</span>
            </div>
            <p className="text-[10px] tracking-tight leading-tight font-extralight mt-2">
              Setelah melakukan pembayaran, harap mengirimkan bukti pembayaran
              melalui WhatsApp:
            </p>
            <ul className="pl-2 space-y-1 mt-1 text-[10px] tracking-tight leading-tight font-extralight">
              <li>
                <strong>+62 81-1234-1111</strong> (Admin 1)
              </li>
              <li>
                <strong>+62 81-1234-2222</strong> (Admin 2)
              </li>
              <li>
                <strong>+62 81-1234-3333</strong> (Admin 3)
              </li>
            </ul>
          </div>
          <div className="h-full w-fit grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right">
            <div className="text-xs uppercase text-gray-600 text-right font-light">
              Subtotal:{" "}
            </div>
            <Typography.Currency
              number={localHelpers.subtotal(invoice?.customer_id)}
            />
            {console.log("customer id:", invoice?.customer_id)}
            <div className="text-xs uppercase text-gray-600 text-right font-light">
              Ongkir:{" "}
            </div>
            <Typography.Currency
              number={localHelpers.shipmentCost(invoice?.customer_id)}
            />
            {/* <div className="text-xs uppercase text-gray-600 text-right font-light">
              Diskon Ongkir:{" "}
            </div>
            <Typography.Currency
              number={-localHelpers.shipmentCostDiscount(invoice?.customer_id)}
            /> */}
            {/* <div className="col-span-2 my-3"></div> */}

            <div className="text-xs uppercase text-gray-600 text-right font-light">
              Total:{" "}
            </div>
            <Typography.Currency
              number={localHelpers.total(invoice?.customer_id)}
            />
            <div className="col-span-2">
              <hr />
            </div>

            {invoice?.isUseCredit ? (
              <>
                <div className="text-xs uppercase text-right font-light text-primary-600">
                  Kredit Customer:{" "}
                </div>
                <div className="text-primary-600">
                  <Typography.Currency number={invoice?.creditAmount} />
                </div>
              </>
            ) : (
              <></>
            )}

            {invoice?.total_payment > 0 ? 
              <>
                <div
                  className="text-xs uppercase text-primary-600 text-right font-light"
                >
                  Pembayaran:{" "}
                </div>
                <Typography.Currency number={invoice?.total_payment} />
              </> : <></>
            }
            <div className="text-sm uppercase text-gray-600 text-right font-bold">
              Sisa Tagihan:{" "}
            </div>
            <Typography.Currency
              number={
                localHelpers.total(invoice?.customer_id) -
                +invoice?.credit_amount -
                invoice?.total_payment
              }
            />
          </div>
        </div>
      </div>
      <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
        <Button onClick={handlePrint} type="submit">
          Print Invoice
        </Button>
      </div>
    </ModalMolecule>
  );
}

export default ViewInvoiceModal;
