import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function MainLayoutAuthGuard({ children }) {
  const authenticated = useSelector((state) => state.authentication.authenticated);

  if ( ! authenticated) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default MainLayoutAuthGuard;
