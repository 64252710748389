import { useState, useEffect } from "react";
import { useLoadingState } from "app/common/hooks";
import api from "app/integration/api";

function usePaginatedCollector({ modelName, methodName = "index", params }) {
  const [data, setData] = useState([]);
  const [paginationProps, setPaginationProps] = useState([]);
  const loader = useLoadingState();

  const collect = async () => {
    loader.charge();
    try {
      const { data, pagination } = await api[modelName][methodName](params);
      setData(data);
      setPaginationProps(pagination);
    } catch (err) {
      setData([]);
    }
    loader.discharge();
  };

  useEffect(() => {
    collect();
  }, [params]);

  return [data, setData, loader, collect, paginationProps];
}

function useCollector({
  modelName,
  methodName = "index",
  collectUponInstantiation = true,
  params = {},
}) {
  const [data, setData] = useState([]);
  const loader = useLoadingState();

  const collect = async (params) => {
    loader.charge();
    try {
      const { data } = await api[modelName][methodName](params);
      setData(data);
    } catch (err) {
      setData([]);
    }
    loader.discharge();
  };

  useEffect(() => {
    if (collectUponInstantiation) {
      collect(params);
    }
  }, []);

  return [data, setData, loader, collect];
}

function useFetcher({
  modelName,
  methodName = "show",
  payload = { id: null },
}) {
  const [data, setData] = useState({});
  const loader = useLoadingState();

  const fetch = async () => {
    loader.charge();
    try {
      const { data } = await api[modelName][methodName](payload.id);
      setData(data);
    } catch (err) {
      setData([]);
    }
    loader.discharge();
  };

  useEffect(() => {
    if (payload.id != null && payload.id != undefined) {
      fetch();
    }
  }, [payload.id]);

  return [data, setData, loader, fetch];
}

function useCreateForm({
  initialValues,
  validationSchema,
  onSubmit,
  onSuccess,
}) {
  const calculatedOnSubmit = async (values, { setSubmitting, setStatus }) => {
    let result = false;
    let response = undefined;
    try {
      response = await onSubmit(values);
      result = true;
      setSubmitting(false);
      if (result) onSuccess(response);
    } catch (err) {}
  };

  return {
    initialValues,
    validationSchema,
    onSubmit: calculatedOnSubmit,
  };
}

function useUpdateForm({
  initialValues,
  validationSchema,
  onSubmit,
  onSuccess,
}) {
  const [localInitialValues, setLocalInitialValues] = useState(initialValues);

  const calculatedOnSubmit = async (values, { setSubmitting, setStatus }) => {
    let result = false;
    try {
      await onSubmit(values);
      result = true;
    } catch (err) {}
    setSubmitting(false);
    if (result) onSuccess();
  };

  return {
    initialValues: localInitialValues,
    setInitialValues: setLocalInitialValues,
    validationSchema,
    onSubmit: calculatedOnSubmit,
  };
}

function useDeleteForm({
  initialValues,
  validationSchema,
  onSubmit,
  onSuccess,
}) {
  const [localInitialValues, setLocalInitialValues] = useState(initialValues);

  const calculatedOnSubmit = async (values, { setSubmitting, setStatus }) => {
    let result = false;
    try {
      await onSubmit(values);
      result = true;
    } catch (err) {}
    setSubmitting(false);
    if (result) onSuccess();
  };

  return {
    initialValues: localInitialValues,
    setInitialValues: setLocalInitialValues,
    validationSchema,
    onSubmit: calculatedOnSubmit,
  };
}

function usePagination(data = []) {
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const [maximumPage, setMaximumPage] = useState(0);

  useEffect(() => {
    const modulus = data.length % itemsPerPage;
    setMaximumPage(
      (data.length - modulus) / itemsPerPage + (modulus > 0 ? 1 : 0)
    );
  }, [data, itemsPerPage]);

  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    let localPaginatedData = [];
    const minIndex = itemsPerPage * (currentPage - 1);
    const maxIndex = itemsPerPage * currentPage - 1;

    data.forEach((item, index) => {
      if (index >= minIndex && index <= maxIndex) {
        localPaginatedData.push(item);
      }
    });

    setPaginatedData(localPaginatedData);
  }, [data, currentPage, maximumPage]);

  const method = {
    nextPage: () => {
      if (method.nextPageIsNotAvailable()) {
        return;
      }
      setCurrentPage(currentPage + 1);
    },
    previousPage: () => {
      if (method.previousPageIsNotAvailable()) {
        return;
      }
      setCurrentPage(currentPage - 1);
    },
    setItemsPerPage: (value) => {
      setItemsPerPage(value);
    },
    nextPageIsNotAvailable: () => {
      return currentPage === maximumPage;
    },
    previousPageIsNotAvailable: () => {
      return currentPage === 1;
    },
    setPage: (value) => {
      setCurrentPage(value);
    },
  };

  return [
    paginatedData,
    {
      itemsPerPage,
      currentPage,
      maximumPage,
      method,
    },
  ];
}

export default {
  useCollector,
  useFetcher,
  useCreateForm,
  useUpdateForm,
  useDeleteForm,
  usePagination,
};

export {
  usePaginatedCollector,
  useCollector,
  useFetcher,
  useCreateForm,
  useUpdateForm,
  useDeleteForm,
  usePagination,
};
