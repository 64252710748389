
function SidebarNavigationWrapper({ children }) {
  return (
    <nav className="flex-1 space-y-1 px-2" aria-label="Sidebar">
      {
        children
      }
    </nav>
  );
}

export default SidebarNavigationWrapper;
