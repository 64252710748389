import { useApplicationModals } from "app/common/hooks";
import { useEffect } from "react";
import { useDeleteForm } from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik } from "formik";
import { Button } from "app/components/atoms/button";

function DeleteCategoryModal({ onSuccess = () => {} }) {
  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("category.delete");

  const handleClose = () => closeModal("category.delete");
  const handleSuccess = () => {
    closeModal("category.delete");
    onSuccess();
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useDeleteForm({
      initialValues: {
        id: payload.id,
      },
      validationSchema: Yup.object().shape({
        id: Yup.number().required("Id diperlukan"),
      }),
      onSubmit: async (values) => {
        await api.category.delete(values.id);
      },
      onSuccess: handleSuccess,
    });

  useEffect(() => {
    setInitialValues({ id: payload.id });
  }, [payload.id]);

  return (
    <ModalMolecule
      show={showStates["category.delete"]}
      title="Hapus Kategori Menu"
      onClose={handleClose}
    >
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            Apakah Anda yakin ingin menghapus Kategori Menu ini?
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Hapus Kategori Menu</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default DeleteCategoryModal;
