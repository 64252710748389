import { Fragment, useEffect } from 'react';

function BodyClassNameOverrider({ className = '', children }) {
  useEffect(() => {
    if (className != '') {
      document.body.classList.add(...className.split(' '));
    }
    return () => {
      if (className != '') {
        document.body.classList.remove(...className.split(' '));
      }
    };
  }, [className]);

  return (
    <Fragment>
      {
        children
      }
    </Fragment>
  );
}

export default BodyClassNameOverrider;
