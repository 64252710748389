import { configureStore } from '@reduxjs/toolkit';
import darkModeReducer from 'app/features/darkMode/darkModeSlice';
import authenticationReducer from 'app/features/authentication/authenticationSlice';
import modalsReducer from 'app/features/modals/modalsSlice';

export const store = configureStore({
  reducer: {
    darkMode: darkModeReducer,
    authentication: authenticationReducer,
    modals: modalsReducer,
  },
});
