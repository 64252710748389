import { PrinterIcon } from "@heroicons/react/24/solid";
import { Badge } from "app/components/atoms/badge";
import { IconButton, BadgeButton } from "app/components/atoms/button";
import AppModals from "app/modals";
import { useApplicationModals } from "app/common/hooks";
import { TrashIcon } from "@heroicons/react/20/solid";
import { FormHeader } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import { DateRangeInputMolecule } from "app/components/molecules/input";
import { SelectMolecule } from "app/components/molecules/select";
import { useFetcher } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import BadgeLink from "app/components/atoms/button/badgeLink";

function PaymentReportPage() {
  const navigate = useNavigate();

  const today = () => {
    const now = DateTime.now();
    return now.toISODate();
  };

  const [queryParams, setQueryParams] = useState({
    payment_report: 1,
    payment_start: "",
    payment_end: "",
    inv_status: null,
    payment_status: null,
    delivery_start: today(),
    delivery_end: today(),
  });

  const [report, setReport, loader, fetch] = useFetcher({
    modelName: "report",
    methodName: "paymentReport",
    payload: { id: queryParams },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { openModal, setPayloads } = useApplicationModals();

  const convertStatus = (int) => {
    switch (int) {
      case 1:
        return "Unpaid";
        break;
      case 2:
        return "Partial";
        break;
      case 3:
        return "Lunas";
        break;
      case 3:
        return "Cancel";
        break;

      default:
        break;
    }
  };

  const TableContent = (
    <>
      <Table.Header>
        <Table.HeaderRow>
          <Table.Heading>Invoice</Table.Heading>
          <Table.Heading>Customer</Table.Heading>
          <Table.Heading>Payment Date</Table.Heading>
          <Table.Heading>
            <p className="text-right">Jumlah Bayar</p>
          </Table.Heading>
          <Table.Heading>
            <p className="text-right">Total Invoice</p>
          </Table.Heading>
          <Table.Heading>Salesperson</Table.Heading>
          <Table.Heading>Invoice</Table.Heading>
          {/* <Table.Heading>Status</Table.Heading> */}
          <Table.Heading></Table.Heading>
        </Table.HeaderRow>
      </Table.Header>
      <Table.Body>
        {Array.isArray(report) &&
          report?.map((item, index) => (
            <Table.BodyRow key={index}>
              <Table.Cell>
                <BadgeLink href={"/invoice/detail/" + item.invoice_id}>
                  {item.inv_code}
                </BadgeLink>
              </Table.Cell>
              <Table.Cell>
                <div className="whitespace-pre-wrap">
                  {item?.nick_name?.length > 0
                    ? item.customer_name + " (" + item.nick_name + ")"
                    : item.customer_name}
                </div>
              </Table.Cell>
              <Table.Cell gradient={500} fontWeight="light">
                <div className="flex flex-col md:flex-row md:gap-2 items-center">
                  <Typography.Date size="sm" format="dd MMM yyyy" date={item.payment_date} />
                  <p className="font-light">{item.payment_time}</p>
                </div>
              </Table.Cell>
              <Table.Cell fontWeight="semibold">
                <p className="text-right">
                  <Typography.Currency number={item.total_payment} />
                </p>
              </Table.Cell>
              <Table.Cell fontWeight="semibold">
                <p className="text-right">
                  <Typography.Currency number={item.total_amount} />
                </p>
              </Table.Cell>
              <Table.Cell>
                {" "}
                <p className="text-center">{item?.user_create_payment}</p>
                {/* <p className="text-center text-danger-500 text-xs font-extralight">
      (hardcoded)
    </p> */}
              </Table.Cell>
              <Table.Cell>
                {item.status === 3 ? (
                  <Badge color="green">{convertStatus(item.status)}</Badge>
                ) : (
                  <></>
                )}
                {item.status === 2 ? (
                  <Badge color="yellow">{convertStatus(item.status)}</Badge>
                ) : (
                  <></>
                )}
                {item.status === 1 ? (
                  <Badge color="red">{convertStatus(item.status)}</Badge>
                ) : (
                  <></>
                )}
              </Table.Cell>
              {/* <Table.Cell>
                {item.payment_status ? (
                  <Badge color="green">Confirmed</Badge>
                ) : (
                  <Badge color="red">Unconfirmed</Badge>
                )}
              </Table.Cell> */}
              {/* <Table.Cell>
                <div className="flex space-x-1">
                  {!item.payment_status ? (
                    <BadgeButton
                      onClick={(e) => {
                        setPayloads("payment.confirm", {
                          id: item.payment_id,
                        });
                        openModal("payment.confirm");
                      }}
                    >
                      Confirm Payment
                    </BadgeButton>
                  ) : (
                    <BadgeButton disabled>Confirm Payment</BadgeButton>
                  )}

                  <IconButton
                    icon={PencilSquareIcon}
                    onClick={(e) => {
                      setPayloads("payment.update", {
                        id: item.payment_id,
                      });
                      openModal("payment.update");
                    }}
                  />
                  <div className="flex">
                    <IconButton
                      icon={TrashIcon}
                      onClick={(e) => {
                        setPayloads("payment.delete", {
                          id: item.payment_id,
                        });
                        openModal("payment.delete");
                      }}
                    />
                  </div>
                </div>
              </Table.Cell> */}
            </Table.BodyRow>
          ))}
        {Array.isArray(report) && (
          <Table.TotalsRow key="PAYMENT_TOTAL" className="sticky bottom-0">
            <Table.Cell background={100}></Table.Cell>
            <Table.Cell background={100}>
              <strong>TOTAL</strong>
            </Table.Cell>
            <Table.Cell
              background={100}
              gradient={500}
              fontWeight="light"
            ></Table.Cell>
            <Table.Cell background={100}>
              <p className="text-right">
                <Typography.Currency
                  number={report
                    .map((item) => +item.total_payment)
                    .reduce((partialSum, a) => partialSum + a, 0)}
                />
              </p>
            </Table.Cell>
            <Table.Cell background={100}>
              <p className="text-right">
                <Typography.Currency
                  number={report
                    .map((item) => +item.total_amount)
                    .reduce((partialSum, a) => partialSum + a, 0)}
                />
              </p>
            </Table.Cell>
            <Table.Cell background={100}></Table.Cell>
            <Table.Cell background={100}></Table.Cell>
            <Table.Cell background={100}></Table.Cell>
            <Table.Cell background={100}></Table.Cell>
          </Table.TotalsRow>
        )}
      </Table.Body>
    </>
  );

  return (
    <MainLayout
      activeSidebarNavigation="report"
      activeSidebarChild="payment"
      breadcrumbItems={[
        { label: "Report", href: "#" },
        { label: "Pembayaran", href: "#" },
      ]}
      pageTitle="Report Pembayaran"
      headingButtons={[
        {
          label: "Print Report",
          type: "button",
          onClick: (e) => {
            setPayloads("report.view", {
              data: TableContent,
              date_start: queryParams.delivery_start,
              date_end: queryParams.delivery_end,
              title: "Report Pembayaran",
            });
            openModal("report.view");
          },
        },
      ]}
    >
      <AppModals
        items={[
          "payment.update",
          "payment.delete",
          "payment.confirm",
          "report.view",
        ]}
        onSuccess={{
          "payment.update": () => fetch(),
          "payment.delete": () => fetch(),
          "payment.confirm": () => fetch(),
          "report.view": () => fetch(),
        }}
      />
      <div ref={componentRef}>
        <Table.Container>
          <Table.SearchBarWrapper>
            <FormHeader>
              <div className="col-span-2">
                <DateRangeInputMolecule
                  label="Tanggal Pembayaran"
                  from={queryParams?.payment_start}
                  to={queryParams?.payment_end}
                  onFromChange={(e) => {
                    setQueryParams({
                      ...queryParams,
                      payment_start: e.target.value,
                    });
                  }}
                  onToChange={(e) => {
                    setQueryParams({
                      ...queryParams,
                      payment_end: e.target.value,
                    });
                  }}
                />
              </div>
              <SelectMolecule
                label="Status Invoice"
                placeholder="Pilih Status"
                options={[
                  {
                    value: "",
                    label: "Semua Status",
                  },
                  {
                    value: "2",
                    label: "Partial",
                  },
                  {
                    value: "3",
                    label: "Lunas",
                  },
                ]}
                onChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    inv_status: e.target.value,
                  });
                }}
                absolute={false}
              />
              {/* <SelectMolecule
                label="Status Pembayaran"
                placeholder="Pilih Status"
                options={[
                  {
                    value: null,
                    label: "Semua Status",
                  },
                  {
                    value: true,
                    label: "Confirmed",
                  },
                  {
                    value: "false",
                    label: "Unconfirmed",
                  },
                ]}
                onChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    payment_status: e.target.value,
                  });
                }}
                absolute={false}
              /> */}
            </FormHeader>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
    <IconButton icon={PrinterIcon} />
  </Table.UtilitiesButtonWrapper> */}
        </Table.Container>

        <Table.Wrapper asIndex={true}>{TableContent}</Table.Wrapper>
        {(typeof report === "string" || report instanceof String) && (
          <p className="text-sm px-2 py-1 text-gray-500 font-light">{report}</p>
        )}
      </div>
    </MainLayout>
  );
}

export default PaymentReportPage;
