
function DirectoryContainer({ children }) {
  return (
    <div className="overflow-x-auto print:overflow-hidden">
      <div className="inline-block align-middle">
        <div className="overflow-hidden border-b border-gray-200 shadow dark:border-gray-700 print:border-0">
          {
            children
          }
        </div>
      </div>
    </div>
  );
}

export default DirectoryContainer;
