
function TableContainer({ children }) {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto print:overflow-hidden">
        <div className="inline-block w-full align-middle lg:min-w-full">
          <div className="overflow-hidden border-b border-gray-200 shadow dark:border-gray-700 print:border-0">
            {
              children
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableContainer;
