
function DirectoryHeader({ children }) {
  return (
    <div className="bg-gray-100 dark:bg-gray-700 bg-opacity-75 print:table-row-group transition-colors ease-in-out duration-300 sticky top-0 z-0 text-xs font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wider py-3 px-4">
      {
        children
      }
    </div>
  );
}

export default DirectoryHeader;
