import clsx from "clsx";
import { Card } from "app/components/atoms/card";

function FormSummary({ children, big = false }) {
  return (
    <div className="mt-4">
      <Card color="gray">
        <div
          className={clsx(
            "overflow-y-auto flex flex-col md:flex-row justify-between items-end md:items-center gap-4",
            big ? "lg:h-40 print:lg:h-full" : "lg:h-28 print:lg:h-full"
          )}
        >
          {children}
        </div>
      </Card>
      <div className="h-24 block print:hidden"></div>
    </div>
  );
}

export default FormSummary;
