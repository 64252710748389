import { useApplicationModals } from "app/common/hooks";
import { useCreateForm } from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { Button } from "app/components/atoms/button";

function CreateMenuCategoryModal({ onSuccess = () => {} }) {
  const {
    showStates,
    closeModal,
  } = useApplicationModals();

  const handleClose = () => closeModal('menuCategory.create');
  const handleSuccess = () => {
    closeModal('menuCategory.create');
    onSuccess();
  };
  
  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama Kategori Menu harus diisi"),
    }),
    onSubmit: async (values) => {
      await api.menuCategory.store(
        values.name
      );
    },
    onSuccess: handleSuccess,
  });

  return (
    <ModalMolecule
      show={showStates["menuCategory.create"]}
      title="Tambah Jumlah Menu"
      onClose={handleClose}
    >
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-2" onSubmit={handleSubmit}>
            <InputMolecule
              label="Nama"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              errorMessage={errors.name && touched.name && errors.name}
            />
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Simpan</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreateMenuCategoryModal;
