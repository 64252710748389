import { useApplicationModals } from "app/common/hooks";
import { Button } from "app/components/atoms/button";
import { InputLabel } from "app/components/atoms/form";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import { ModalMolecule } from "app/components/molecules/modal";
import { SelectMolecule } from "app/components/molecules/select";
import api from "app/integration/api";
import { useCreateForm, useFetcher } from "app/integration/common/hooks";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import AppModals from ".";

function CreatePaymentModal({ onSuccess = () => {} }) {
  const { showStates, closeModal, getPayloads, openModal, setPayloads } =
    useApplicationModals();
  const payloads = getPayloads("payment.create");

  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);

  const handleClose = () => closeModal("payment.create");
  const handleSuccess = () => {
    closeModal("payment.create");
    onSuccess();
  };

  const now = DateTime.now();
  const dt = now.toISODate();
  const time = now.toLocaleString(DateTime.TIME_24_SIMPLE);

  const [invoice, setInvoice, loader, fetch] = useFetcher({
    modelName: "invoice",
    payload: { id: payloads.invoice_id },
  });

  var isError = false;

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      invoice_id: payloads.invoice_id,
      payment_date: dt,
      payment_time: time,
      method: 1,
      ref_number: "",
      bank_name: "",
      account_number: "",
      total_payment: 0,
      total_payment_credit: 0,
      status: false,
      mode: 1,
    },
    validationSchema: Yup.object().shape({
      payment_date: Yup.date()
        .max(new Date(), "Tanggal pembayaran tidak boleh di masa depan.")
        .required("Tanggal Pembayaran harus diisi."),
      payment_time: Yup.string(),
      method: Yup.string(),
      ref_number: Yup.string().when("method", {
        is: (method) => method.toUpperCase() === "BANK",
        then: Yup.string(),
      }),
      bank_name: Yup.string().when("method", {
        is: (method) => method.toUpperCase() === "BANK",
        then: Yup.string(),
      }),
      account_number: Yup.string().when("method", {
        is: (method) => method.toUpperCase() === "BANK",
        then: Yup.string(),
      }),
      total_payment: Yup.number()
        .required("Total harus harus diisi")
        .when("total_payment_credit", {
          is: 0,
          then: (schema) =>
            schema.min(1, "Jumlah Pembayaran harus lebih dari 0."),
        }),
      total_payment_credit: Yup.number()
        .required("Total harus harus diisi")
        .max(invoice?.inv_customer?.credit, "Kredit Customer tidak mencukupi"),
      mode: Yup.number().required("Sumber Pembayaran harus dipilih."),
    }),
    onSubmit: async (values) => {
      try {
        await api.payment.store(values.invoice_id, [
          {
            mode: 1,
            method: 1,
            payment_date: values.payment_date,
            payment_time: values.payment_time,
            ref_number: values.ref_number,
            bank_name: values.bank_name,
            account_number: values.account_number,
            total_payment: values.total_payment ? values.total_payment : 0,
            status: values.status,
          },
          {
            mode: 2,
            method: 1,
            payment_date: values.payment_date,
            payment_time: values.payment_time,
            ref_number: "",
            bank_name: "",
            account_number: "",
            total_payment: values.total_payment_credit
              ? values.total_payment_credit
              : 0,
            status: values.status,
          },
        ]);
        isError = false;
      } catch (error) {
        setPayloads("errorMessage.view", {
          title: "Gagal Register Pembayaran",
          message: error?.response?.data?.message,
        });
        isError = true;
        openModal("errorMessage.view");
      }
    },
    onSuccess: () => {
      if (!isError) {
        handleSuccess();
      }
    },
  });

  useEffect(() => {
    const kurangBayar =
      invoice?.total_amount - invoice?.total_payment - invoice?.credit_amount;
    if (kurangBayar < paymentAmount) {
      setPaymentStatus(" (Lebih Bayar)");
    } else if (kurangBayar > paymentAmount) {
      setPaymentStatus(" (Kurang Bayar)");
    } else {
      setPaymentStatus(" (Lunas)");
    }
  }, [paymentAmount]);

  function copyValue(setFieldValue, mode) {
    const { credit_amount, total_amount, total_payment, inv_customer } =
      invoice;
    const { credit } = inv_customer;
    console.log(
      "copyValue: ",
      credit_amount,
      total_amount,
      total_payment,
      credit
    );

    if (total_amount - total_payment >= credit) {
      if (credit > 0) {
        setFieldValue("total_payment_credit", credit);
        setFieldValue(
          "total_payment",
          total_amount - total_payment - credit_amount - credit
        );
        setPaymentAmount(total_amount - total_payment - credit_amount);
      } else {
        setFieldValue("total_payment_credit", 0);
        setFieldValue(
          "total_payment",
          total_amount - total_payment - credit_amount
        );
        setPaymentAmount(total_amount - total_payment - credit_amount);
      }
    } else {
      setFieldValue(
        "total_payment_credit",
        total_amount - total_payment - credit_amount
      );
      setFieldValue("total_payment", 0);
      setPaymentAmount(total_amount - total_payment - credit_amount);
    }

    // if (mode && mode == 2) {
    //   if (invoice && invoice.credit_amount > invoice.total_amount) {
    //     setFieldValue(
    //       "total_payment",
    //       invoice?.total_amount -
    //         invoice?.total_payment -
    //         invoice?.credit_amount
    //     );
    //     setPaymentAmount(
    //       invoice?.total_amount -
    //         invoice?.total_payment -
    //         invoice?.credit_amount
    //     );
    //   }
    // } else {
    //   setFieldValue(
    //     "total_payment",
    //     invoice?.total_amount - invoice?.total_payment - invoice?.credit_amount
    //   );
    //   setPaymentAmount(
    //     invoice?.total_amount - invoice?.total_payment - invoice?.credit_amount
    //   );
    // }
  }

  return (
    <ModalMolecule
      show={showStates["payment.create"]}
      title="Register Payment"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <InputMolecule
                label="Tanggal Pembayaran"
                type="date"
                name="payment_date"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.payment_date}
                errorMessage={
                  errors.payment_date &&
                  touched.payment_date &&
                  errors.payment_date
                }
              />
              <InputMolecule
                label="Waktu Pembayaran"
                type="time"
                name="payment_time"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.payment_time}
                errorMessage={
                  errors.payment_time &&
                  touched.payment_time &&
                  errors.payment_time
                }
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <InputLabel>Kredit Yang Dimiliki Customer</InputLabel>
                <div className="h-11 flex items-center px-3 border border-gray-300 rounded-lg bg-gray-100 cursor-default">
                  <Typography.Currency
                    align="right"
                    number={invoice?.inv_customer?.credit}
                  />
                </div>
              </div>
              <div
                onClick={(e) => {
                  copyValue(setFieldValue, values.mode);
                }}
              >
                <InputLabel>Kurang Bayar</InputLabel>
                <div className="h-11 flex items-center px-3 border border-gray-300 rounded-lg bg-gray-100 cursor-default">
                  <Typography.Currency
                    align="right"
                    number={
                      invoice?.total_amount -
                      invoice?.total_payment -
                      invoice?.credit_amount
                    }
                  />
                </div>
              </div>
              {/* <SelectMolecule
                label="Sumber Pembayaran"
                placeholder="Pilih Status"
                options={[
                  {
                    value: 1,
                    label: "Pembayaran Customer",
                  },
                  {
                    value: 2,
                    label: "Kredit Customer",
                  },
                ]}
                name="mode"
                onChange={(e) => {
                  handleChange(e);
                  const mode = e.target.value;
                  if (mode === 1) {
                    setFieldValue("total_payment", 0);
                  } else if (mode === 2) {
                    const outstandingAmount =
                      invoice?.total_amount -
                      invoice?.total_payment -
                      invoice?.credit_amount;
                    const customerCredit = invoice?.inv_customer?.credit;
                    setFieldValue(
                      "total_payment",
                      outstandingAmount > customerCredit
                        ? customerCredit
                        : outstandingAmount
                    );
                  }
                }}
                onBlur={handleBlur}
                value={values.mode}
                errorMessage={errors.mode && touched.mode && errors.mode}
                absolute={false}
              /> */}
            </div>
            <div className="grid grid-cols-1 gap-4">
              <InputMolecule
                label="Jumlah Pembayaran by Kredit"
                type="number"
                name="total_payment_credit"
                onChange={(e) => {
                  handleChange(e);
                  setPaymentAmount(Number(e.target.value) + Number(values.total_payment));
                }}
                onBlur={handleBlur}
                value={values.total_payment_credit}
                errorMessage={
                  errors.total_payment_credit &&
                  touched.total_payment_credit &&
                  errors.total_payment_credit
                }
              />
              {/* <InputLabel>Jumlah Pembayaran Kredit</InputLabel>
              <div className="h-11 flex items-center px-3 border border-gray-300 rounded-lg bg-gray-100 cursor-default">
                <Typography.Currency
                  align="right"
                  number={values.total_payment}
                />
              </div> */}
              <InputMolecule
                label="Jumlah Pembayaran by Cash"
                type="number"
                name="total_payment"
                onChange={(e) => {
                  handleChange(e);
                  setPaymentAmount(Number(e.target.value) + Number(values.total_payment_credit));
                }}
                onBlur={handleBlur}
                value={values.total_payment}
                errorMessage={
                  errors.total_payment &&
                  touched.total_payment &&
                  errors.total_payment
                }
              />
            </div>
            <div className="hidden">
              <InputMolecule
                label="Metode Pembayaran"
                type="text"
                name="method"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.method}
                errorMessage={errors.method && touched.method && errors.method}
              />
            </div>
            <InputMolecule
              label="Nomor Referensi"
              type="text"
              name="ref_number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.ref_number}
              errorMessage={
                errors.ref_number && touched.ref_number && errors.ref_number
              }
            />
            <div className="grid grid-cols-2 gap-4">
              <InputMolecule
                label="Nama Bank"
                type="text"
                name="bank_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bank_name}
                errorMessage={
                  errors.bank_name && touched.bank_name && errors.bank_name
                }
              />
              <InputMolecule
                label="No. Account"
                type="text"
                name="account_number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.account_number}
                errorMessage={
                  errors.account_number &&
                  touched.account_number &&
                  errors.account_number
                }
              />
            </div>
            {/* <SelectMolecule
              label="Status Pembayaran"
              placeholder="Pilih Status"
              options={[
                {
                  value: true,
                  label: "OK",
                },
                {
                  value: false,
                  label: "Not OK",
                },
              ]}
              name="status"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.status}
              errorMessage={
                errors.status &&
                touched.status &&
                errors.status
              }
              absolute={false}
            /> */}
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Simpan {paymentStatus}</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreatePaymentModal;
