import clsx from "clsx";

function circleSpinnerSize(size) {
  return {
    "sm": "h-5 w-5",
    "md": "h-8 w-8",
    "lg": "h-12 w-12",
  }[size];
}

function circleSpinnerColor(color) {
  return {
    "primary": "fill-primary-600",
    "secondary": "fill-secondary-600",
    "success": "fill-success-600",
    "danger": "fill-danger-600",
    "warning": "fill-warning-600",
    "info": "fill-info-600",
    "light": "fill-light-100",
    "dark": "fill-dark-600",
  }[color];
};

function CircleSpinner({ size = "sm", color = "light" }) {
  return (
    <svg
      className={
        clsx(
          "inline animate-spin",
          circleSpinnerSize(size),
          circleSpinnerColor(color),
        )
      }
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 40 40"
      enableBackground="new 0 0 40 40"
      xmlSpace="preserve"
    >
      <path opacity="0.2" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
        s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
        c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
      <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
        C22.32,8.481,24.301,9.057,26.013,10.047z">
      </path>
    </svg>
  );
}

export default CircleSpinner;
