import { CircleSpinner } from "app/components/atoms/loading";

function Button({ type, children, disabled, loading, onClick = () => {} }) {
  return (
    <button
      className={(disabled ? "border-secondary-600 bg-secondary-300 text-secondary-400 hover:border-secondary-400 hover:bg-secondary-300 dark:border-secondary-300 dark:bg-secondary-600 dark:hover:border-secondary-400 dark:hover:bg-secondary-400" 
        : "border-primary-600 bg-primary-600 text-secondary-100 hover:border-primary-400 hover:bg-primary-400 dark:border-primary-300 dark:bg-primary-600 dark:hover:border-primary-400 dark:hover:bg-primary-400") 
        + " inline-block cursor-pointer rounded-lg text-center text-base font-medium transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 dark:focus:ring-offset-gray-900 px-4 py-2 w-full"}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {
        loading && (
          <span className="mr-1">
            <CircleSpinner />
          </span>
        )
      }
      {children}
    </button>
  );
}

export default Button;
