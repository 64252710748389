import { useParams, useNavigate } from "react-router-dom";
import { useApplicationModals } from "app/common/hooks";
import {
  useUpdateForm,
  useCollector,
  useFetcher,
} from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { useEffect, useState } from "react";
import Directory from "app/components/atoms/directory";
import { ClosableCard } from "app/components/atoms/card";
import { SearchInput } from "app/components/atoms/input";
import { Formik, FieldArray, getIn } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { InputLabel } from "app/components/atoms/form";
import { SelectSearchMolecule } from "app/components/molecules/select";
import { Button, DottedOutlineButton } from "app/components/atoms/button";
import Alert from "app/components/molecules/alert";
import AppModals from "app/modals";

function CustomerDetailSubPage({
  onSuccess = () => {},
  customers = [],
  query = "",
  onQueryChange = () => {},
}) {
  let { id } = useParams();
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();
  const [isLoading, setIsLoading] = useState(false);
  const handleSuccess = () => {
    onSuccess();
  };

  var isError = false;

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        nickName: "",
        phone: "",
        email: "",
        first_bank: "",
        second_bank: "",
        credit: 0,
        customer_address: [],
      },
      validationSchema: Yup.object().shape({
        firstName: Yup.string().required("Nama Customer harus diisi"),
        middleName: Yup.string(),
        lastName: Yup.string(),
        nickName: Yup.string(),
        phone: Yup.string(),
        email: Yup.string(),
        // first_bank: Yup.string(),
        // second_bank: Yup.string(),
        // third_bank: Yup.string(),
        customer_address: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            desc: Yup.string().required("Alamat harus diisi"),
            state_id: Yup.number().required("Provinsi harus diisi"),
            state_name: Yup.string(),
            city_id: Yup.number().required("Kota harus diisi"),
            city_name: Yup.string(),
            // district_id: Yup.number(),
            // district_name: Yup.string(),
            // sub_district_id: Yup.number(),
            // sub_district_name: Yup.string(),
            // zipcode: Yup.string(),
          })
        ),
      }),
      onSubmit: async (values) => {
        try {
          setIsLoading(true);
          const response = await await api.customer.update(
            customer.id,
            values.firstName,
            values.middleName,
            values.lastName,
            values.nickName,
            values.first_bank,
            values.second_bank,
            values.third_bank,
            values.email,
            values.phone,
            values.customer_address.map((address) => ({
              id: address.id,
              desc: address.desc,
              state_id: address.state_id,
              state_name: address.state_name,
              city_id: address.city_id,
              city_name: address.city_name,
              district_id: address.district_id,
              district_name: address.district_name,
              sub_district_id: address.sub_district_id,
              sub_district_name: address.sub_district_name,
              zipcode: address.zipcode,
            }))
          );

          if (response && response.status == "success") {
            setIsLoading(false);
            isError = false;
            setPayloads("successMessage.view", {
              title: "Update Customer Success",
              message: 'Update Customer "' + values.firstName + '" berhasil',
            });
            openModal("successMessage.view");
          }
        } catch (error) {
          console.log("error: ", error);
          setIsLoading(false);
          setPayloads("errorMessage.view", {
            title: "Update Customer Error",
            message: error?.response?.data?.message,
          });
          isError = true;
          openModal("errorMessage.view");
        }
      },
      onSuccess: () => {
        if (!isError) {
          handleSuccess();
        }
      },
    });

  const [customer, setCustomer, loader, fetch] = useFetcher({
    modelName: "customer",
    payload: { id },
  });

  const [states] = useCollector({
    modelName: "rajaOngkir",
    methodName: "province",
  });

  const [cities, setCities, cityLoader, collectCities] = useCollector({
    modelName: "rajaOngkir",
    methodName: "city",
  });

  const [subDistricts, setSubDistricts, subDistrictLoader, collectSubDistrict] =
    useCollector({
      modelName: "rajaOngkir",
      methodName: "subdistrict",
    });

  const [showAlert, setAlert] = useState(false);

  setTimeout(() => {
    setAlert(false);
  }, 7000);

  const closeBanner = (e) => {
    setAlert(false);
  };

  useEffect(() => {
    setInitialValues({
      id: customer.id,
      firstName: customer.firstName,
      middleName: customer.middleName,
      lastName: customer.lastName,
      nickName: customer.nickName,
      first_bank: customer.first_bank,
      second_bank: customer.second_bank,
      third_bank: customer.third_bank,
      email: customer.email,
      phone: customer.phone,
      credit: customer.credit,
      customer_address: customer.customer_address,
    });
  }, [customer]);

  return (
    <div className="flex">
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <AppModals
        items={["successMessage.view"]}
        onSuccess={{
          "successMessage.view": () => fetch(),
        }}
      />
      <div className="hidden md:block">
        <Directory.Container>
          <Directory.UtilitiesWrapper>
            <Directory.SearchBarWrapper>
              <Directory.SearchBarItemWrapper>
                <SearchInput
                  placeholder="Cari Customer"
                  type="text"
                  query={query}
                  onChange={onQueryChange}
                />
              </Directory.SearchBarItemWrapper>
            </Directory.SearchBarWrapper>
          </Directory.UtilitiesWrapper>
          <Directory.Wrapper asIndex={true}>
            <Directory.Header>Customer</Directory.Header>
            <Directory.Body>
              {[...customers]
                ?.sort(function (a, b) {
                  if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase())
                    return -1;
                  if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase())
                    return 1;
                  return 0;
                })
                .map((customer, key) => (
                  <Directory.Row
                    key={key}
                    active={customer.id === +id}
                    onClick={(e) => navigate("/customer/detail/" + customer.id)}
                  >
                    {customer.firstName} {customer.middleName}{" "}
                    {customer.lastName}
                  </Directory.Row>
                ))}
            </Directory.Body>
          </Directory.Wrapper>
        </Directory.Container>
      </div>
      <ClosableCard
        grow={true}
        asMainContent={true}
        onClose={(e) => navigate("/customer")}
      >
        {showAlert && (
          <Alert title="Sukses" desc="Supplier berhasil diubah"></Alert>
        )}
        <Formik
          {...{ initialValues, validationSchema, onSubmit }}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            status,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="space-y-4 p-2 pb-24" onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Nama Depan"
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  errorMessage={
                    errors.firstName && touched.firstName && errors.firstName
                  }
                />
                <InputMolecule
                  label="Nama Tengah"
                  type="text"
                  name="middleName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.middleName}
                  errorMessage={
                    errors.middleName && touched.middleName && errors.middleName
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Nama Belakang"
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  errorMessage={
                    errors.lastName && touched.lastName && errors.lastName
                  }
                />
                <InputMolecule
                  label="Nama Panggilan"
                  type="text"
                  name="nickName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nickName}
                  errorMessage={
                    errors.nickName && touched.nickName && errors.nickName
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Email"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  errorMessage={errors.email && touched.email && errors.email}
                />
                <InputMolecule
                  label="No. HP"
                  type="text"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  errorMessage={errors.phone && touched.phone && errors.phone}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Nama Bank"
                  type="text"
                  name="first_bank"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_bank}
                  errorMessage={
                    errors.first_bank && touched.first_bank && errors.first_bank
                  }
                />
                <InputMolecule
                  label="Nomor Rekening"
                  type="text"
                  name="second_bank"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.second_bank}
                  errorMessage={
                    errors.second_bank &&
                    touched.second_bank &&
                    errors.second_bank
                  }
                />

                {/* <InputMolecule
                  label="Account Bank 3"
                  type="text"
                  name="third_bank"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.third_bank}
                  errorMessage={
                    errors.third_bank && touched.third_bank && errors.third_bank
                  }
                /> */}

                <InputMolecule
                  label="Kredit Customer"
                  type="number"
                  name="credit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.credit}
                  errorMessage={
                    errors.credit && touched.credit && errors.credit
                  }
                  disabled={true}
                />
              </div>
              <div>
                <hr className="my-5" />
              </div>
              <div>
                <InputLabel>Alamat</InputLabel>
                <FieldArray name="customer_address">
                  {(arrayHelpers) => (
                    <div className="space-y-2">
                      {values?.customer_address?.map((address, addressKey) => (
                        <ClosableCard
                          key={addressKey}
                          onClose={() => arrayHelpers.remove(addressKey)}
                        >
                          <div className="space-y-2 p-2">
                            <InputMolecule
                              label="Alamat Lengkap"
                              type="text"
                              name={`customer_address[${addressKey}].desc`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.desc}
                              errorMessage={
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].desc`
                                ) &&
                                getIn(
                                  touched,
                                  `customer_address[${addressKey}].desc`
                                ) &&
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].desc`
                                )
                              }
                            />
                            <SelectSearchMolecule
                              label="Provinsi"
                              name={`customer_address[${addressKey}].state_id`}
                              options={states?.map((state) => ({
                                value: state.province_id,
                                label: state.province,
                              }))}
                              absolute={true}
                              onChange={(e) => {
                                values.customer_address[
                                  `${addressKey}`
                                ].state_name = states.filter(
                                  (i) => i.province_id == e.target.value
                                )[0]["province"];
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              value={`${address.state_id}`}
                              errorMessage={
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].state_id`
                                ) &&
                                getIn(
                                  touched,
                                  `customer_address[${addressKey}].state_id`
                                ) &&
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].state_id`
                                )
                              }
                            />
                            <SelectSearchMolecule
                              label="Kota"
                              name={`customer_address[${addressKey}].city_id`}
                              options={cities?.map((city) => ({
                                value: city.city_id,
                                label: city.city_name,
                              }))}
                              absolute={true}
                              onChange={(e) => {
                                values.customer_address[
                                  `${addressKey}`
                                ].city_name = cities.filter(
                                  (i) => i.city_id == e.target.value
                                )[0]["city_name"];
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              value={`${address.city_id}`}
                              errorMessage={
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].city_id`
                                ) &&
                                getIn(
                                  touched,
                                  `customer_address[${addressKey}].city_id`
                                ) &&
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].city_id`
                                )
                              }
                            />
                            <InputMolecule
                              label="Kecamatan"
                              type="text"
                              name={`customer_address[${addressKey}].district_name`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.district_name}
                              errorMessage={
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].district_name`
                                ) &&
                                getIn(
                                  touched,
                                  `customer_address[${addressKey}].district_name`
                                ) &&
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].district_name`
                                )
                              }
                            />
                            <InputMolecule
                              label="Kelurahan"
                              type="text"
                              name={`customer_address[${addressKey}].sub_district_name`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.sub_district_name}
                              errorMessage={
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].sub_district_name`
                                ) &&
                                getIn(
                                  touched,
                                  `customer_address[${addressKey}].sub_district_name`
                                ) &&
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].sub_district_name`
                                )
                              }
                            />
                            <InputMolecule
                              label="Kode Pos"
                              type="text"
                              name={`customer_address[${addressKey}].zipcode`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.zipcode}
                              errorMessage={
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].zipcode`
                                ) &&
                                getIn(
                                  touched,
                                  `customer_address[${addressKey}].zipcode`
                                ) &&
                                getIn(
                                  errors,
                                  `customer_address[${addressKey}].zipcode`
                                )
                              }
                            />
                          </div>
                        </ClosableCard>
                      ))}
                      <div>
                        <div className="my-8">
                          <DottedOutlineButton
                            type="button"
                            onClick={(e) =>
                              arrayHelpers.push({
                                address: "",
                                state: "",
                                city: "",
                                district: "",
                                subDistrict: "",
                                shipmentCostCategoryId: null,
                              })
                            }
                          >
                            Tambah Alamat
                          </DottedOutlineButton>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>
              <div>
                <hr className="my-5" />
              </div>
              <div className="fixed bottom-2 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? "Loading..." : "Ubah Customer"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </ClosableCard>
    </div>
  );
}

export default CustomerDetailSubPage;
