import { useState } from "react";
import { useApplicationModals } from "app/common/hooks";
import { Button } from "app/components/atoms/button";
import { InputLabel } from "app/components/atoms/form";
import { InputMolecule } from "app/components/molecules/form";
import { ModalMolecule } from "app/components/molecules/modal";
import api from "app/integration/api";
import { useCollector, useCreateForm } from "app/integration/common/hooks";
import { Formik, getIn } from "formik";
import * as Yup from "yup";
import AppModals from ".";

import { PlainMultiselect } from "app/components/atoms/multiselect";
import { SelectMolecule } from "app/components/molecules/select";
import Resizer from "react-image-file-resizer";

function CreateProductModal({ onSuccess = () => {} }) {
  let isError = false;
  const { showStates, closeModal, getPayloads, openModal, setPayloads } =
    useApplicationModals();

  const handleClose = () => {
    setImage("");
    closeModal("product.create");
  };
  const handleSuccess = () => {
    closeModal("product.create");
    onSuccess();
  };

  const [colorVariants] = useCollector({ modelName: "colorVariant" });
  const [sizeVariants] = useCollector({ modelName: "sizeVariant" });
  const [categories] = useCollector({ modelName: "category" });
  const [suppliers] = useCollector({ modelName: "supplier" });
  const [image, setImage] = useState("");

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      name: "",
      code: "",
      variant_id: null,
      supplier_id: null,
      price: 0,
      weight: 0,
      image_url: "",
      sizes: [],
      colors: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama harus diisi"),
      code: Yup.string().required("Kode harus diisi"),
      price: Yup.number().required("Harga harus diisi"),
      weight: Yup.number().required("Berat harus diisi"),
      colors: Yup.array()
        .required("Color harus diisi")
        .min(1, "Minimal satu warna harus dipilih"),
      sizes: Yup.array()
        .required("Size harus diisi")
        .min(1, "Minimal satu ukuran harus dipilih"),
    }),
    onSubmit: async (values) => {
      try {
        await api.product.store(
          values.name,
          values.code,
          values.variant_id,
          values.supplier_id,
          values.price,
          values.weight,
          values.colors,
          values.sizes,
          values.image_url
        );
        isError = false;
      } catch (error) {
        setPayloads("errorMessage.view", {
          title: "Add Product Error",
          message: error?.response?.data?.message,
        });
        isError = true;
        openModal("errorMessage.view");
      }
    },
    onSuccess: () => {
      if (!isError) {
        handleSuccess();
      }
    },
  });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const fileChangeHandler = async (e, index) => {
    console.log("before resize: ", e.target.files);
    let imageResize = await resizeFile(e.target.files[0]);

    console.log("after resize: ", imageResize);

    let fileType = imageResize.type;
    let fileName = imageResize.name;

    if (
      fileType.search(/jpeg|jpg|png/gi) >= 0 ||
      fileName.search(/.rar/gi) >= 0
    ) {
      console.log(
        "file ini di perbolehkan untuk di upload",
        fileType.search(/jpeg|jpg|pdf|xls|zip|rar/gi),
        fileName.search(/.rar/gi)
      );
      const uploaded = await api.uploadImage.product({ image: imageResize });

      if (uploaded && uploaded.status == "success") {
        // setImage(uploaded.image_url);
        return uploaded.image_url;
      }
    } else {
      console.log(
        "file tidak dapat di upload.",
        fileType.search(/jpeg|jpg|pdf|xls|zip|rar/gi),
        fileName.search(/.rar/gi)
      );
      alert(`Jenis File ${fileType} tidak dapat di upload`);
    }
  };

  return (
    <ModalMolecule
      show={showStates["product.create"]}
      title="Tambah Produk"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className="space-y-4 pb-24" onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 gap-4">
              <InputMolecule
                label="Nama"
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                errorMessage={errors.name && touched.name && errors.name}
              />
              <InputMolecule
                label="Kode Produk"
                type="text"
                name="code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
                errorMessage={errors.code && touched.code && errors.code}
              />
            </div>
            <div className="grid md:grid-cols-2 gap-4">
              <InputMolecule
                label="Harga"
                type="number"
                name="price"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.price}
                errorMessage={errors.price && touched.price && errors.price}
              />
              <InputMolecule
                label="Berat (gram)"
                type="number"
                name="weight"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.weight}
                errorMessage={errors.weight && touched.weight && errors.weight}
              />
            </div>
            <div className="grid md:grid-cols-2 gap-4">
              <SelectMolecule
                label="Kategori"
                name="variant_id"
                options={categories?.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.variant_id}
                errorMessage={
                  errors.variant_id && touched.variant_id && errors.variant_id
                }
              />
              <SelectMolecule
                label="Supplier"
                name="supplier_id"
                options={suppliers?.map((supplier) => ({
                  value: supplier.id,
                  label: `${supplier.firstName} ${supplier.middleName} ${supplier.lastName}`,
                }))}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.supplier_id}
                errorMessage={
                  errors.supplier_id &&
                  touched.supplier_id &&
                  errors.supplier_id
                }
              />
            </div>

            <div>
              <hr className="my-5" />
            </div>
            <div>
              <InputLabel>Size Variant</InputLabel>
              <PlainMultiselect
                name={`sizes`}
                options={sizeVariants.map((sizeVariant) => ({
                  value: sizeVariant.id,
                  label: sizeVariant.name,
                }))}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sizes}
                errorMessage={
                  getIn(errors, `sizes`) &&
                  getIn(touched, `sizes`) &&
                  getIn(errors, `sizes`)
                }
              />
            </div>

            <div>
              <hr className="my-5" />
            </div>
            <div>
              <InputLabel>Color Variant</InputLabel>
              <PlainMultiselect
                name={`colors`}
                options={colorVariants.map((colorVariant) => ({
                  value: colorVariant.id,
                  label: colorVariant.name,
                }))}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.colors}
                errorMessage={
                  getIn(errors, `colors`) &&
                  getIn(touched, `colors`) &&
                  getIn(errors, `colors`)
                }
              />
            </div>

            <div>
              <hr className="my-5" />
            </div>
            <div className="grid md:grid-cols-1 gap-1">
              {values.image_url && values.image_url != "" ? (
                <img
                  src={`${process.env.REACT_APP_AWS_LINK_URL}${values.image_url}`}
                />
              ) : null}
              <>
                <div>Upload foto produk di sini:</div>
                <input
                  type="file"
                  name="file"
                  onChange={async (e) => {
                    const uploadImage = await fileChangeHandler(e);

                    await setFieldValue("image_url", uploadImage);
                  }}
                />
              </>
            </div>

            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Simpan</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreateProductModal;
