
function InputLabel({ children, required = false }) {
  return (
    <div className="flex gap-1 items-center">
      <label className="mb-1 block text-gray-400 dark:text-gray-500 text-xs uppercase whitespace-nowrap overflow-ellipsis overflow-clip">
        {children}
      </label>
      {required && <span className="text-red-400">*</span>}
    </div>
  );
}

export default InputLabel;
