import { Link } from "react-router-dom";
import clsx from "clsx";

function SidebarNavigationItem({ href = "#", active = false, icon, children }) {
  const Icon = icon;

  return (
    <Link
      to={href}
      className={clsx(
        active
          ? "bg-primary-700 text-gray-100"
          : "text-gray-100 hover:bg-primary-600",
        "group flex w-full transform items-center whitespace-nowrap rounded-md py-2 pl-2 pr-0 text-base font-normal transition-colors duration-500 ease-in-out"
      )}
    >
      <Icon
        className="mr-4 h-6 w-6 flex-shrink-0 text-gray-100"
        aria-hidden="true"
      />
      {children}
    </Link>
  );
}

export default SidebarNavigationItem;
