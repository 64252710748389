import { useApplicationModals } from "app/common/hooks";
import {
  BadgeButton,
  Button,
  DottedOutlineButton,
} from "app/components/atoms/button";
import { FormHeader, FormSummary } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import { SelectSearchMolecule } from "app/components/molecules/select";
import { ModalMolecule } from "app/components/molecules/modal";
import api from "app/integration/api";
import { useCollector, useCreateForm } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik } from "formik";
import { DateTime } from "luxon";
import { Fragment, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import style from "style/height.module.css";
import * as Yup from "yup";
import {
  filter as _filter,
  findWhere as _findWhere,
  flatten as _flatten,
  where as _where,
} from "underscore";
import { Badge } from "app/components/atoms/badge";
import ImageWithPreview from "app/components/molecules/imageWithPreview";

const breadcrumbItems = [
  { label: "Sales", href: "#" },
  { label: "Invoice", href: "/invoice" },
  { label: "Tambah Invoice", href: "#" },
];

function CreateInvoicePage() {
  let isError = false;
  const navigate = useNavigate();
  const [params] = useSearchParams();
  // console.log(Object.fromEntries([...params]).customer_id);

  const handleSuccess = (res) => {
    navigate("/invoice/detail/" + res.data.id);
  };

  const now = DateTime.now();
  const dt = now.toISODate();
  const tomorrow = now.plus({ days: 1 });

  const [currentErrors, setCurrentErrors] = useState([]);

  const [isLoading, setIsloading] = useState(false);
  const [oldInv, setOldInv] = useState([]);
  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    +Object.fromEntries([...params])?.customer_id
  );
  const [invoices, setInvoices, invoicesLoader, collectInvoices] = useCollector(
    { modelName: "invoice", collectUponInstantiation: false }
  );
  const [customers] = useCollector({ modelName: "customer" });

  const [salesOrders, setSalesOrder] = useState([]);
  const setSalesItem = async (customer_id) => {
    try {
      if (!isNaN(customer_id)) {
        const response = await api.salesOrder.index({ customer_id });

        if (response && response.data) {
          let sales = response.data;
          setSalesOrder(sales);
          return sales;
        }
      }
    } catch (error) {
      setSalesOrder([]);
      return [];
    }
  };

  useEffect(() => {
    if (!isNaN(selectedCustomerId)) {
      collectInvoices({ customer_id: selectedCustomerId });
      // collectSalesOrders({ customer_id: selectedCustomerId });
    }
  }, [selectedCustomerId]);

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      customer_id: +Object.fromEntries([...params])?.customer_id,
      total_amount: 0,
      is_use_credit: false,
      invoice_date: dt,
      due_date: tomorrow,
      credit_amount: 0,
      status: 1,
      note: "",
      items: [],
      old_invoice: [],
    },
    validationSchema: Yup.object().shape({
      customer_id: Yup.number()
        .typeError("Customer harus diisi")
        .required("Customer harus diisi"),
      total_amount: Yup.number(),
      invoice_date: Yup.date().required("Tanggal Invoice harus diisi"),
      due_date: Yup.date().required("Tanggal Jatuh Tempo harus diisi"),
      credit_amount: Yup.number(),
      old_invoice: Yup.array(),
      items: Yup.array().of(Yup.number()),
      // .when("old_invoice", (oldInvoice, schema) => {
      //   if (oldInvoice[0].length === 0) {
      //     console.log("old invoice validation: ", oldInvoice[0].length);
      //     return schema.min(1, "Customer ini tidak memiliki order");
      //   } else if (oldInvoice[0].length > 0) {
      //     console.log("old invoice validation 0: ", oldInvoice[0].length);
      //   }
      // }),
    }),
    onSubmit: async (values) => {
      try {
        setIsloading(true);
        let response = await api.invoice.store(
          values.customer_id,
          values.total_amount,
          values.is_use_credit,
          values.invoice_date,
          values.due_date,
          values.credit_amount,
          values.status,
          values.note,
          values.items,
          values.old_invoice
        );
        console.log("values submit: ", values.items);
        isError = false;
        return response;
      } catch (error) {
        setPayloads("errorMessage.view", {
          title: "Generate Invoice",
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : "Server Error",
        });
        isError = true;
        openModal("errorMessage.view");
        setIsloading(false);
      }
    },
    onSuccess: handleSuccess,
  });

  const { openModal, setPayloads } = useApplicationModals();

  function runValidations(values) {
    validationSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
        setCurrentErrors([]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.name); // ValidationError
        console.log(err.errors);
        setCurrentErrors(err.errors);

        console.log("values ketika error: ", values);

        // setPayloads("errorMessage.view", {
        //   title: "Tambah Invoice Error",
        //   data: err.errors,
        // });
        // openModal("errorMessage.view");
      });
  }

  const localHelpers = {
    subtotal: (sales = []) => {
      let subTotal = sales.reduce((total, order) => {
        total += Number(order.delivery_fee);
        order.OrderItems.forEach((item) => {
          total +=
            Number(item.qty) *
            (Number(item.unit_price) - Number(item.discount_reseller));
        });
        return total;
      }, 0);

      return subTotal;
    },
    shipmentCost: (customer_id) => {
      return _filter(salesOrders, { customer_id: customer_id, Invoice: null })
        .map((salesOrder) => {
          return Number(salesOrder.delivery_fee);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    shipmentCostDiscount: (customer_id) => {
      return _filter(salesOrders, { customer_id: customer_id, Invoice: null })
        .map((salesOrder) => {
          return +salesOrder.delivery_fee_discount;
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },

    parkingFee: (customer_id) => {
      return _filter(salesOrders, { customer_id: customer_id, Invoice: null })
        .map((salesOrder) => {
          return +salesOrder.parking_fee;
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },

    total: () => {
      return +localHelpers.subtotal(
        _filter(salesOrders, {
          invoice_id: null,
        })
      );
    },
  };

  function customerInvoices(customer_id) {
    // console.log(_where(invoices, { customer_id: customer_id }));
    return _where(invoices, { customer_id: customer_id });
  }

  useEffect(() => {
    setSalesItem(selectedCustomerId);
  }, [selectedCustomerId]);

  return (
    <MainLayout
      activeSidebarNavigation="invoice"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Tambah Invoice"
      headingButtons={[]}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={false}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
          >
            <FormHeader>
              <SelectSearchMolecule
                label="Customer"
                name="customer_id"
                options={customers
                  ?.sort(function (a, b) {
                    if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase())
                      return -1;
                    if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase())
                      return 1;
                    return 0;
                  })
                  ?.map((customer) => ({
                    value: customer.id,
                    label:
                      customer.firstName +
                      " " +
                      customer.middleName +
                      " " +
                      customer.lastName +
                      (customer.nickName ? " (" + customer.nickName + ")" : ""),
                  }))}
                onChange={async (e) => {
                  handleChange(e);
                  const customer_id = e.target.value;
                  let sales = await setSalesItem(customer_id);
                  setFieldValue(
                    "items",
                    _filter(sales, {
                      invoice_id: null,
                    }).map((i) => i.id)
                  );

                  setSelectedCustomerId(customer_id);
                }}
                onBlur={handleBlur}
                value={values.customer_id}
                errorMessage={
                  errors.customer_id &&
                  touched.customer_id &&
                  errors.customer_id
                }
              />
              <InputMolecule
                label="Tanggal Invoice"
                type="date"
                name="invoice_date"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.invoice_date}
                errorMessage={
                  errors.invoice_date &&
                  touched.invoice_date &&
                  errors.invoice_date
                }
              />
            </FormHeader>
            <div className="py-2 overflow-y-visible col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
              <div className="w-full">
                <FieldArray name="items">
                  {(arrayHelpers) => (
                    <div className="table-content-height overflow-y-auto">
                      <Table.Wrapper asIndex={false}>
                        <Table.Header>
                          <Table.HeaderRow>
                            <Table.Heading>Order</Table.Heading>
                            <Table.Heading>Qty</Table.Heading>
                            <Table.Heading>Harga Satuan</Table.Heading>
                            <Table.Heading>Discount Reseller</Table.Heading>
                            <Table.Heading>Subtotal</Table.Heading>
                            <Table.Heading>Ongkir</Table.Heading>
                          </Table.HeaderRow>
                        </Table.Header>
                        <Table.Body>
                          {_filter(salesOrders, {
                            invoice_id: null,
                          })?.map((salesOrder, key) => (
                            <Fragment key={key}>
                              <Table.BodyRow>
                                <Table.Cell size="sm" fontWeight="semibold">
                                  <div className="flex gap-4 items-center">
                                    {salesOrder.order_code}
                                  </div>
                                </Table.Cell>
                                <Table.Cell size="sm"></Table.Cell>
                                <Table.Cell size="sm"></Table.Cell>
                                <Table.Cell size="sm"></Table.Cell>
                                <Table.Cell size="sm" className="text-right">
                                  <Typography.Currency
                                    number={salesOrder.delivery_fee}
                                  />
                                </Table.Cell>
                                <Table.Cell size="sm" className="text-right">
                                  <Typography.Currency
                                    number={salesOrder.delivery_fee_discount}
                                  />
                                </Table.Cell>
                                <Table.Cell size="sm" className="text-right">
                                  <Typography.Currency
                                    number={salesOrder.parking_fee}
                                  />
                                </Table.Cell>
                              </Table.BodyRow>
                              {salesOrder?.OrderItems?.map(
                                (orderItem, nestedKey) => (
                                  <Fragment key={nestedKey}>
                                    <Table.BodyRow>
                                      <Table.Cell size="sm">
                                        <div className="flex gap-2 items-center flex-wrap">
                                          {orderItem?.master_product
                                            ?.imageUrl && (
                                            <ImageWithPreview
                                              size="lg"
                                              src={`${process.env.REACT_APP_AWS_LINK_URL}${orderItem?.master_product?.imageUrl}`}
                                            ></ImageWithPreview>
                                          )}
                                          <span className="ml-5">
                                            {orderItem?.master_product?.name} ({" "}
                                            {orderItem?.master_size?.name} ) ({" "}
                                            {orderItem?.master_color?.name} )
                                          </span>
                                        </div>
                                      </Table.Cell>
                                      <Table.Cell size="sm">
                                        {orderItem.qty}
                                      </Table.Cell>
                                      <Table.Cell size="sm">
                                        <Typography.Currency
                                          number={orderItem.unit_price}
                                        />
                                      </Table.Cell>
                                      <Table.Cell size="sm">
                                        <Typography.Currency
                                          number={orderItem.discount_reseller}
                                        />
                                      </Table.Cell>
                                      <Table.Cell size="sm">
                                        <Typography.Currency
                                          number={
                                            orderItem.qty *
                                              orderItem.unit_price -
                                            orderItem.discount_reseller
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.BodyRow>
                                  </Fragment>
                                )
                              )}
                            </Fragment>
                          ))}
                        </Table.Body>
                      </Table.Wrapper>

                      {oldInv && oldInv.length > 0 && (
                        <Table.Wrapper asIndex={false}>
                          <Table.Header>
                            <Table.HeaderRow>
                              <Table.Heading>Kode</Table.Heading>
                              <Table.Heading align="right">
                                Total Tagihan
                              </Table.Heading>
                              <Table.Heading align="right">
                                Pembayaran
                              </Table.Heading>
                              <Table.Heading align="right">
                                Kredit
                              </Table.Heading>
                              <Table.Heading align="right">
                                Kurang/Lebih
                              </Table.Heading>
                              <Table.Heading align="right">Hapus</Table.Heading>
                            </Table.HeaderRow>
                          </Table.Header>
                          <Table.Body>
                            {customerInvoices(values.customer_id)?.map(
                              (invoice, key) => {
                                if (oldInv.includes(invoice.id)) {
                                  return (
                                    <Table.BodyRow key={key}>
                                      <Table.Cell>
                                        <BadgeButton
                                          onClick={(e) =>
                                            window.open(
                                              `/invoice/detail/${invoice.id}`,
                                              "_blank",
                                              "rel=noopener noreferrer"
                                            )
                                          }
                                        >
                                          {invoice.inv_code}
                                        </BadgeButton>
                                      </Table.Cell>
                                      <Table.Cell align="right">
                                        <Typography.Currency
                                          number={invoice.total_amount}
                                        ></Typography.Currency>
                                      </Table.Cell>
                                      <Table.Cell align="right">
                                        <Typography.Currency
                                          number={invoice.total_payment}
                                        ></Typography.Currency>
                                      </Table.Cell>
                                      <Table.Cell align="right">
                                        <Typography.Currency
                                          number={invoice.credit_amount}
                                        ></Typography.Currency>
                                      </Table.Cell>
                                      <Table.Cell align="right">
                                        <Typography.Currency
                                          color={
                                            Number(invoice.total_amount) >
                                            Number(invoice.total_payment) +
                                              Number(invoice.credit_amount)
                                              ? "red"
                                              : "green"
                                          }
                                          number={
                                            Number(invoice.total_payment) +
                                            Number(invoice.credit_amount) -
                                            Number(invoice.total_amount)
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell align="right">
                                        <div
                                          className="cursor-pointer"
                                          onClick={async () => {
                                            setOldInv(
                                              oldInv.filter(
                                                (item) => item !== invoice.id
                                              )
                                            );
                                          }}
                                        >
                                          hapus
                                        </div>
                                      </Table.Cell>
                                    </Table.BodyRow>
                                  );
                                }
                              }
                            )}
                          </Table.Body>
                        </Table.Wrapper>
                      )}
                    </div>
                  )}
                </FieldArray>
                <FormSummary>
                  <div className="h-full">
                    {values.customer_id && (
                      <BadgeButton onClick={(e) => setShowInvoice(true)}>
                        Lihat Invoice Outstanding{" ("}
                        {
                          customerInvoices(values.customer_id)?.filter(
                            (invoice) => invoice.status !== 3
                          ).length
                        }
                        {")"}
                      </BadgeButton>
                    )}
                  </div>
                  <div className="h-full grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right">
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Subtotal:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.subtotal(
                        _filter(salesOrders, {
                          invoice_id: null,
                        })
                      )}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Total:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.total(values.customer_id)}
                    />
                    {/* <div className="col-span-2 my-3"></div> */}
                    <div className="flex justify-between">
                      <input
                        type="checkbox"
                        name="is_use_credit"
                        value={values.is_use_credit}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.checked) {
                            setFieldValue(
                              "credit_amount",
                              +_findWhere(customers, { id: values.customer_id })
                                ?.credit >
                                localHelpers.total(values.customer_id)
                                ? +localHelpers.total(values.customer_id)
                                : +_findWhere(customers, {
                                    id: values.customer_id,
                                  })?.credit
                            );
                          } else {
                            setFieldValue("credit_amount", 0);
                          }
                        }}
                      />
                      <div
                        className={clsx(
                          "text-xs uppercase text-right font-light",
                          values.is_use_credit
                            ? "text-gray-600"
                            : "text-gray-300"
                        )}
                      >
                        Kredit:{" "}
                      </div>
                    </div>
                    <Typography.Currency
                      number={
                        +_findWhere(customers, { id: values.customer_id })
                          ?.credit > localHelpers.total(values.customer_id)
                          ? +localHelpers.total(values.customer_id)
                          : +_findWhere(customers, {
                              id: values.customer_id,
                            })?.credit
                      }
                    />

                    <div className="col-span-2">
                      <hr />
                    </div>
                    <div className="text-sm uppercase text-gray-600 text-right font-bold">
                      Grand Total:{" "}
                    </div>
                    <Typography.Currency
                      number={
                        localHelpers.total(values.customer_id) -
                        values.credit_amount
                      }
                    />
                  </div>
                </FormSummary>
              </div>
            </div>

            <ModalMolecule
              show={showInvoice}
              title="Invoice yang belum lunas"
              onClose={() => setShowInvoice(false)}
            >
              <Table.Wrapper>
                <Table.Header>
                  <Table.HeaderRow>
                    <Table.Heading>Kode</Table.Heading>
                    <Table.Heading align="right">Total Tagihan</Table.Heading>
                    <Table.Heading align="right">Pembayaran</Table.Heading>
                    <Table.Heading align="right">Kredit</Table.Heading>
                    <Table.Heading align="right">Kurang/Lebih</Table.Heading>
                    <Table.Heading align="right">Gabung Invoice</Table.Heading>
                  </Table.HeaderRow>
                </Table.Header>
                <Table.Body>
                  {customerInvoices(values.customer_id)?.map((invoice, key) => {
                    if (invoice.status !== 3) {
                      return (
                        <Table.BodyRow key={key}>
                          <Table.Cell>
                            <BadgeButton
                              // onClick={(e) =>
                              //   navigate("/invoice/detail/" + invoice.id)
                              // }
                              onClick={() => window.open("/invoice/detail/" + invoice.id, '_blank', 'noopener,noreferrer')}
                            >
                              {invoice.inv_code}
                            </BadgeButton>
                          </Table.Cell>
                          <Table.Cell align="right">
                            <Typography.Currency
                              number={invoice.total_amount}
                            ></Typography.Currency>
                          </Table.Cell>
                          <Table.Cell align="right">
                            <Typography.Currency
                              number={invoice.total_payment}
                            ></Typography.Currency>
                          </Table.Cell>
                          <Table.Cell align="right">
                            <Typography.Currency
                              number={invoice.credit_amount}
                            ></Typography.Currency>
                          </Table.Cell>
                          <Table.Cell align="right">
                            <Typography.Currency
                              color={
                                Number(invoice.total_amount) >
                                Number(invoice.total_payment) +
                                  Number(invoice.credit_amount)
                                  ? "red"
                                  : "green"
                              }
                              number={
                                Number(invoice.total_payment) +
                                Number(invoice.credit_amount) -
                                Number(invoice.total_amount)
                              }
                            />
                          </Table.Cell>
                          <Table.Cell align="right">
                            <div
                              className="cursor-pointer"
                              onClick={async () => {
                                if (!oldInv.includes(invoice.id)) {
                                  setOldInv([...oldInv, invoice.id]);
                                }
                              }}
                            >
                              Tambah
                            </div>
                          </Table.Cell>
                        </Table.BodyRow>
                      );
                    }
                  })}
                </Table.Body>
              </Table.Wrapper>
            </ModalMolecule>

            <div className="fixed bottom-2 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
              <Button
                onClick={() => {
                  setFieldValue(
                    "items",
                    _filter(salesOrders, {
                      customer_id: values.customer_id,
                      invoice_id: null,
                    }).map((salesOrder) => salesOrder.id)
                  );
                  setFieldValue("old_invoice", oldInv);
                  runValidations(values);
                }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Konfirmasi Invoice"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

export default CreateInvoicePage;
