import { useApplicationModals } from "app/common/hooks";
import { Button } from "app/components/atoms/button";
import { InputMolecule } from "app/components/molecules/form";
import { ModalMolecule } from "app/components/molecules/modal";
import api from "app/integration/api";
import { useCreateForm } from "app/integration/common/hooks";
import { Formik } from "formik";
import * as Yup from "yup";
import AppModals from ".";

function CreateSizeVariantModal({ onSuccess = () => {} }) {
  const { showStates, closeModal, getPayloads, openModal, setPayloads } =
    useApplicationModals();
  let isError = false;

  const handleClose = () => closeModal("sizeVariant.create");
  const handleSuccess = () => {
    closeModal("sizeVariant.create");
    onSuccess();
  };

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      name: "",
      description: "",
      variantCategories: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama Varian harus diisi"),
    }),
    onSubmit: async (values) => {
      try {
        await api.sizeVariant.store(values.name);
        isError = false;
      } catch (error) {
        setPayloads("errorMessage.view", {
          title: "Create Size",
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : "Server Error",
        });
        isError = true;
        openModal("errorMessage.view");
      }
    },
    onSuccess: !isError && handleSuccess,
  });

  return (
    <ModalMolecule
      show={showStates["sizeVariant.create"]}
      title="Tambah Varian Size"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className="space-y-2" onSubmit={handleSubmit}>
            <InputMolecule
              label="Nama Size"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              errorMessage={errors.name && touched.name && errors.name}
            />
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Simpan</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreateSizeVariantModal;
