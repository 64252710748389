import { useEffect, useState } from "react";
import { useApplicationModals } from "app/common/hooks";
import * as Yup from "yup";
import { useCollector, useUpdateForm } from "app/integration/common/hooks";
import api from "app/integration/api";
import { useFetcher } from "app/integration/common/hooks";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik, FieldArray, getIn } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { InputLabel } from "app/components/atoms/form";
import { Button, DottedOutlineButton } from "app/components/atoms/button";
import { ClosableCard } from "app/components/atoms/card";
import { SelectMolecule } from "app/components/molecules/select";
import AppModals from ".";

function CreateProductVariantModal({ onSuccess = () => {} }) {
  let isError = false;
  const { showStates, closeModal, getPayloads, openModal, setPayloads } =
    useApplicationModals();
  const payload = getPayloads("productVariant.create");

  const [productName, setProductName] = useState("");

  const handleClose = () => closeModal("productVariant.create");
  const handleSuccess = () => {
    closeModal("productVariant.create");
    onSuccess();
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        product_id: null,
        size_variant_id: null,
        color_variant_id: null,
        qty: 0,
      },
      validationSchema: Yup.object().shape({
        size_variant_id: Yup.number().required("Size harus diisi"),
        color_variant_id: Yup.number().required("Color harus diisi"),
        qty: Yup.number(),
      }),
      onSubmit: async (values) => {
        try {
          await api.productVariant.store(
            values.product_id,
            values.size_variant_id,
            values.color_variant_id,
            values.qty
          );
          isError = false;
        } catch (error) {
          setPayloads("errorMessage.view", {
            title: "Create Product Variant",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Server Error",
          });
          isError = true;
          openModal("errorMessage.view");
        }
      },
      onSuccess: !isError && handleSuccess,
    });

  const [product, setVariant, loader, fetch] = useFetcher({
    modelName: "product",
    payload,
  });

  const [sizes] = useCollector({
    modelName: "sizeVariant",
  });
  const [colors] = useCollector({
    modelName: "colorVariant",
  });

  useEffect(() => {
    setProductName(product?.name);
    setInitialValues({
      ...initialValues,
      product_id: product.id,
    });
  }, [product]);

  return (
    <ModalMolecule
      show={showStates["productVariant.create"]}
      title={`Ubah Variant: ${productName}`}
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-2" onSubmit={handleSubmit}>
            <SelectMolecule
              label="Size"
              name="size_variant_id"
              options={sizes?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.size_variant_id}
              errorMessage={
                errors.size_variant_id &&
                touched.size_variant_id &&
                errors.size_variant_id
              }
            />
            <SelectMolecule
              label="Color"
              name="color_variant_id"
              options={colors?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.color_variant_id}
              errorMessage={
                errors.color_variant_id &&
                touched.color_variant_id &&
                errors.color_variant_id
              }
            />
            <InputMolecule
              label="Qty"
              type="text"
              name="qty"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.qty}
              errorMessage={errors.qty && touched.qty && errors.qty}
            />
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Simpan</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreateProductVariantModal;
