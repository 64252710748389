import clsx from "clsx";

function TableTotalsRow({ children, hidden = false }) {
  return (
    <tr
      className={clsx(
        "transition-colors ease-in-out duration-300 sticky bottom-0",
        hidden
          ? "bg-gray-200 dark:bg-gray-600"
          : "dark:hover:bg-gray-900 hover:bg-gray-50"
      )}
    >
      {children}
    </tr>
  );
}

export default TableTotalsRow;
