import { useState, useEffect } from "react";
import { InputLabel, ErrorMessage } from "app/components/atoms/form";
import { SelectSearch } from "app/components/atoms/select";

function SelectSearchMolecule({
  label,
  name,
  placeholder,
  options = [],
  value,
  onChange = () => {},
  errorMessage,
  hideLabel = false,
  absolute = true,
  required = false,
  disabled = false,
}) {
  const [localValue, setLocalValue] = useState(value);
  const localOnChange = (event) => {
    setLocalValue(event.target.value);
    onChange(event);
  };

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div className="h-full">
      {!hideLabel && <InputLabel required={required}>{label}</InputLabel>}
      <SelectSearch
        placeholder={placeholder}
        name={name}
        options={options}
        onChange={localOnChange}
        value={localValue}
        absolute={absolute}
        disabled={disabled}
      />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
}

export default SelectSearchMolecule;
