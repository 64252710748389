
function TableBody({ children }) {
  return (
    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-100 dark:divide-gray-700 text-gray-900 dark:text-gray-100 print:divide-gray-50">
      {children}
    </tbody>
  );
}

export default TableBody;
