
function BreadcrumbWrapper({ children }) {
  return (
    <nav className="flex">
      {
        children
      }
    </nav>
  );
}

export default BreadcrumbWrapper;
