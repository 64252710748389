import { useApplicationModals } from "app/common/hooks";
import { Button } from "app/components/atoms/button";
import { ModalMolecule } from "app/components/molecules/modal";
import api from "app/integration/api";
import { useFetcher, useUpdateForm } from "app/integration/common/hooks";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { useEffect } from "react";
import * as Yup from "yup";
import AppModals from ".";

function ConfirmPaymentModal({ onSuccess = () => {} }) {
  const { showStates, closeModal, getPayloads } = useApplicationModals();
  const { openModal, setPayloads } = useApplicationModals();
  const payloads = getPayloads("payment.confirm");
  let isError = false;

  const handleClose = () => closeModal("payment.confirm");
  const handleSuccess = () => {
    closeModal("payment.confirm");
    onSuccess();
  };

  const now = DateTime.now();
  const dt = now.toISODate();
  const time = now.toLocaleString(DateTime.TIME_24_SIMPLE);

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        invoiceId: null,
        paymentDate: dt,
        paymentTime: time,
        method: 1,
        refNumber: "",
        bankName: "",
        accountNumber: "",
        totalPayment: 0,
        status: false,
      },
      validationSchema: Yup.object().shape({
        id: Yup.number().required("Id diperlukan"),
        paymentDate: Yup.date().required("Tanggal Pembayaran harus diisi"),
        paymentTime: Yup.string(),
        method: Yup.string(),
        refNumber: Yup.string().when("method", {
          is: (method) => method?.toUpperCase() === "BANK",
          then: Yup.string(),
        }),
        bankName: Yup.string().when("method", {
          is: (method) => method?.toUpperCase() === "BANK",
          then: Yup.string(),
        }),
        accountNumber: Yup.string().when("method", {
          is: (method) => method?.toUpperCase() === "BANK",
          then: Yup.string(),
        }),
        totalPayment: Yup.number().required("Total harus harus diisi"),
      }),
      onSubmit: async (values) => {
        try {
          const response = await api.payment.update(
            values.id,
            values.invoiceId,
            values.method,
            values.paymentDate,
            values.paymentTime,
            values.refNumber,
            values.bankName,
            values.accountNumber,
            values.totalPayment,
            values.status
          );

          isError = false;
          return response;
        } catch (error) {
          setPayloads("errorMessage.view", {
            title: "Konfirmasi Pembayaran",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Server Error",
          });
          isError = true;
          openModal("errorMessage.view");
        }
      },
      onSuccess: !isError && handleSuccess,
    });

  const [payment, setPayment, loader, fetch] = useFetcher({
    modelName: "payment",
    payload: payloads,
  });

  useEffect(() => {
    setInitialValues({
      id: payment.id,
      invoiceId: payment.invoice_id,
      method: payment.method,
      paymentDate: payment.payment_date,
      paymentTime: payment.payment_time,
      refNumber: payment.ref_number,
      bankName: payment.bank_name,
      accountNumber: payment.account_number,
      totalPayment: payment.total_payment,
      status: true,
    });
  }, [payment]);

  return (
    <ModalMolecule
      show={showStates["payment.confirm"]}
      title="Konfirmasi Pembayaran"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-2" onSubmit={handleSubmit}>
            Apakah Anda ingin mengkonfirmasi Payment?
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Konfirmasi Payment</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default ConfirmPaymentModal;
