import clsx from "clsx";

function SimplePaginationButtons(
  {
    start = 1,
    currentPage = 1,
    end = 1,
    onPreviousClick = () => {},
    onPageClick = () => {},
    onNextClick = () => {},
    maximumButtonsCount = 11,
  }
) {
  const allPageNumbersToBeShown = [...Array(isNaN(end) ? 1 : end).keys()];
  let pageNumbersToBeShown = [];

  if (allPageNumbersToBeShown.length > maximumButtonsCount) {
    // Determine whether the offset is in a leftie, mid, or rightie.
    const leftRangeLimit = Math.ceil(maximumButtonsCount / 2);
    const rightRangeLimit = end - leftRangeLimit;

    if (currentPage <= leftRangeLimit) {
      pageNumbersToBeShown = [
        ...Array(maximumButtonsCount - 1).keys(),
        "..."
      ];
    } else if (currentPage > leftRangeLimit && currentPage < rightRangeLimit) {
      pageNumbersToBeShown = [
        "...",
        ...Array.from(Array(maximumButtonsCount - 2).keys()).map(e => e + (currentPage - leftRangeLimit)),
        "..."
      ];
    } else if (currentPage >= rightRangeLimit) {
      pageNumbersToBeShown = [
        "...",
        ...Array.from(Array(maximumButtonsCount - 1).keys()).map(e => e + (end - maximumButtonsCount + 1)),
      ];
    }
  } else {
    pageNumbersToBeShown = allPageNumbersToBeShown;
  }

  return (
    <nav aria-label="Page navigation example">
      <ul className="inline-flex -space-x-px py-2">
        <li>
          <a
            href="#"
            onClick={() => onPreviousClick()}
            className={
              clsx(
                "px-3 py-2 ml-0 leading-tight border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-white",
                currentPage === start ? "bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-white" : "bg-white text-gray-500 dark:bg-gray-800 dark:text-gray-400"
              )
            }
          >
            Previous
          </a>
        </li>
        {
          pageNumbersToBeShown.map((item, key) => {
            return (
              <li key={key}>
                {
                  item === "..." && (
                    <a
                      href="#"
                      className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        { item }
                    </a>
                  )
                }
                {
                  (item + 1) !== currentPage && item !== "..." && (
                    <a onClick={e => onPageClick(item + 1)} href="#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{ item + 1 }</a>
                  )
                }
                {
                  (item + 1) === currentPage && item !== "..." && (
                    <a onClick={e => onPageClick(item + 1)} href="#" aria-current="page" className="px-3 py-2 text-primary-600 border border-gray-300 bg-primary-50 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">{ item + 1 }</a>
                  )
                }
              </li>
            )
          })
        }
        <li>
          <a
            href="#"
            onClick={() => onNextClick()}
            className={
              clsx(
                "px-3 py-2 ml-0 leading-tight border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-white",
                currentPage === end ? "bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-white" : "bg-white text-gray-500 dark:bg-gray-800 dark:text-gray-400"
              )
            }
          >
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default SimplePaginationButtons;
