import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showStates: {
    "deliveryOrder.packing": false,
    "product.create": false,
    "product.update": false,
    "product.delete": false,
    "productVariant.create": false,
    "productVariant.update": false,
    "productVariant.delete": false,
    "sizeVariant.create": false,
    "sizeVariant.update": false,
    "sizeVariant.delete": false,
    "colorVariant.create": false,
    "colorVariant.update": false,
    "colorVariant.delete": false,
    "category.create": false,
    "category.update": false,
    "category.delete": false,
    "customer.create": false,
    "customer.delete": false,
    "salesOrder.delete": false,
    "invoice.delete": false,
    "payment.create": false,
    "payment.update": false,
    "payment.delete": false,
    "payment.confirm": false,
  },
  payloads: {
    "deliveryOrder.packing": {},
    "product.create": {},
    "product.update": {},
    "product.delete": {},
    "productVariant.create": {},
    "productVariant.update": {},
    "productVariant.delete": {},
    "sizeVariant.create": {},
    "sizeVariant.update": {},
    "sizeVariant.delete": {},
    "colorVariant.create": {},
    "colorVariant.update": {},
    "colorVariant.delete": {},
    "category.create": {},
    "category.update": {},
    "category.delete": {},
    "customer.create": {},
    "customer.delete": {},
    "salesOrder.delete": {},
    "invoice.delete": {},
    "payment.create": {},
    "payment.update": {},
    "payment.delete": {},
    "payment.confirm": {},
  },
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    open: (state, { payload }) => {
      state.showStates[payload] = true;
    },
    close: (state, { payload }) => {
      state.showStates[payload] = false;
    },
    fill: (state, { payload: { key, payload } }) => {
      state.payloads[key] = payload;
    },
    clear: (state, { payload: { key } }) => {
      state.payloads[key] = {};
    },
  },
});

export const { open, close, fill, clear } = modalsSlice.actions;

export default modalsSlice.reducer;
