import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { open, close, fill, clear } from "app/features/modals/modalsSlice";

function useApplicationModals() {
  const { showStates, payloads } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const openModal = (key) => dispatch(open(key));
  const closeModal = (key) => {
    clearPayloads(key);
    dispatch(close(key));
  };
  const setPayloads = (key, payload) => dispatch(fill({ key, payload }));
  const getPayloads = (key) => payloads[key];
  const clearPayloads = (key) => dispatch(clear({ key }));

  return {
    showStates,
    payloads,
    setPayloads,
    getPayloads,
    openModal,
    closeModal,
  };
}

function useLoadingState() {
  const [loading, setLoading] = useState(false);

  const charge = () => setLoading(true);
  const discharge = () => setLoading(false);

  return {
    state: loading,
    charge,
    discharge,
  };
}

export default {
  useApplicationModals,
  useLoadingState,
};

export { useApplicationModals, useLoadingState };
