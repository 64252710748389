import { useState, useEffect } from "react";
import { InputLabel, Input, ErrorMessage } from "app/components/atoms/form";

function InputMolecule({
  label,
  type,
  name,
  placeholder,
  onChange,
  onBlur,
  value,
  errorMessage,
  hideLabel = false,
  withPrefix = false,
  prefix = "",
  disabled = false,
  align = "left",
  required = false,
  size = "base"
}) {
  const [localValue, setLocalValue] = useState(value);
  const localOnChange = (event) => {
    setLocalValue(event.target.value);
    onChange(event);
  };

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div className="">
      {!hideLabel && <InputLabel required={required}>{label}</InputLabel>}
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={localOnChange}
        onBlur={onBlur}
        value={localValue ? localValue : ""}
        withPrefix={withPrefix}
        prefix={prefix}
        disabled={disabled}
        align={align}
        size={size}
      />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
}

export default InputMolecule;
