import 'style/index.css';
import { StrictMode } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import routes from 'app/routes';
import { Provider } from 'react-redux';
import { store } from 'app/store';

const router = createBrowserRouter(routes);

function App() {
  return (
    <StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </StrictMode>
  );
}

export default App;
