import MobileBackUtility from './mobileBackUtility';
import MainLayoutHeadingBreadcrumb from './breadcrumb';
import MainLayoutHeadingButton from '../button';

function MainLayoutHeadingUpperbar({ breadcrumbItems = [], buttons = [] }) {
  return (
    <div className="flex h-10 items-center justify-between">
      <MobileBackUtility />
      <MainLayoutHeadingBreadcrumb items={breadcrumbItems} />
      <div className="mt-0 z-20 flex flex-shrink-0 space-x-2 print:hidden md:hidden lg:ml-4">
        <MainLayoutHeadingButton buttons={buttons} />
      </div>
    </div>
  );
}

export default MainLayoutHeadingUpperbar;
