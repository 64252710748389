import { useEffect, useState } from "react";
import { useApplicationModals } from "app/common/hooks";
import { useUpdateForm } from "app/integration/common/hooks";
import { useFetcher } from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { SelectMolecule } from "app/components/molecules/select";
import { Button } from "app/components/atoms/button";
import { DateTime } from "luxon";
import { InputLabel } from "app/components/atoms/form";
import Typography from "app/components/atoms/typography";

function UpdatePaymentModal({ onSuccess = () => {} }) {
  const { showStates, closeModal, getPayloads } = useApplicationModals();
  const payloads = getPayloads("payment.update");

  const handleClose = () => closeModal("payment.update");
  const handleSuccess = () => {
    closeModal("payment.update");
    onSuccess();
  };

  const now = DateTime.now();
  const dt = now.toISODate();
  const time = now.toLocaleString(DateTime.TIME_24_SIMPLE);

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        invoiceId: null,
        paymentDate: dt,
        paymentTime: time,
        method: 1,
        refNumber: "",
        bankName: "",
        accountNumber: "",
        totalPayment: 0,
        status: false,
        mode: 1,
      },
      validationSchema: Yup.object().shape({
        id: Yup.number().required("Id diperlukan"),
        paymentDate: Yup.date().required("Tanggal Pembayaran harus diisi"),
        paymentTime: Yup.string(),
        method: Yup.string(),
        refNumber: Yup.string().when("method", {
          is: (method) => method.toUpperCase() === "BANK",
          then: Yup.string(),
        }),
        bankName: Yup.string().when("method", {
          is: (method) => method.toUpperCase() === "BANK",
          then: Yup.string(),
        }),
        accountNumber: Yup.string().when("method", {
          is: (method) => method.toUpperCase() === "BANK",
          then: Yup.string(),
        }),
        totalPayment: Yup.number()
          .required("Total harus harus diisi")
          .when("mode", {
            is: 2,
            then: (schema) =>
              schema.max(
                invoice?.Customer?.credit,
                "Jumlah Pembayaran terlalu besar"
              ),
          }),
        // mode: Yup.number().required("Mode harus dipilih."),
      }),
      onSubmit: async (values) => {
        await api.payment.update(
          values.id,
          values.invoiceId,
          values.method,
          values.paymentDate,
          values.paymentTime,
          values.refNumber,
          values.bankName,
          values.accountNumber,
          values.totalPayment,
          values.status
        );
      },
      onSuccess: handleSuccess,
    });

  const [payment, setPayment, loader, fetch] = useFetcher({
    modelName: "payment",
    payload: payloads,
  });

  const [invoice, setInvoice, invoiceLoader, invoiceFetcher] = useFetcher({
    modelName: "invoice",
    payload: { id: payment?.invoice_id },
  });

  useEffect(() => {
    invoiceFetcher();
  }, [payment]);

  useEffect(() => {
    setInitialValues({
      id: payment.id,
      invoiceId: payment.invoice_id,
      method: payment.method,
      paymentDate: payment.payment_date,
      paymentTime: payment.payment_time,
      refNumber: payment.ref_number,
      bankName: payment.bank_name,
      accountNumber: payment.account_number,
      totalPayment: payment.total_payment,
      status: payment.status,
      mode: payment.mode,
    });
  }, [payment]);

  return (
    <ModalMolecule
      show={showStates["payment.update"]}
      title="Update Payment"
      onClose={handleClose}
    >
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-2" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <InputMolecule
                label="Tanggal Pembayaran"
                type="date"
                name="paymentDate"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDate}
                errorMessage={
                  errors.paymentDate &&
                  touched.paymentDate &&
                  errors.paymentDate
                }
              />
              <InputMolecule
                label="Waktu Pembayaran"
                type="time"
                name="paymentTime"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentTime}
                errorMessage={
                  errors.paymentTime &&
                  touched.paymentTime &&
                  errors.paymentTime
                }
              />
            </div>
            {/* <div className="grid grid-cols-2 gap-4">
              <SelectMolecule
                label="Sumber Pembayaran"
                placeholder="Pilih Status"
                options={[
                  {
                    value: 1,
                    label: "Pembayaran Customer",
                  },
                  {
                    value: 2,
                    label: "Kredit Customer",
                  },
                ]}
                name="mode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mode}
                errorMessage={
                  errors.mode &&
                  touched.mode &&
                  errors.mode
                }
                absolute={false}
              />
              <div>
                <InputLabel>Kredit Yang Dimiliki Customer</InputLabel>
                <div className="h-11 flex items-center px-3 border border-gray-300 rounded-lg bg-gray-100 cursor-default">
                  <Typography.Currency align="right" number={invoice?.Customer?.credit} />
                </div>
              </div>
            </div> */}
            <InputMolecule
              label="Amount"
              type="number"
              name="totalPayment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.totalPayment}
              errorMessage={
                errors.totalPayment &&
                touched.totalPayment &&
                errors.totalPayment
              }
            />
            <div className="hidden">
              <InputMolecule
                label="Metode Pembayaran"
                type="text"
                name="method"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.method}
                errorMessage={errors.method && touched.method && errors.method}
              />
            </div>
            <InputMolecule
              label="Nomor Referensi"
              type="text"
              name="refNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.refNumber}
              errorMessage={
                errors.refNumber && touched.refNumber && errors.refNumber
              }
            />
            <div className="grid grid-cols-2 gap-4">
              <InputMolecule
                label="Nama Bank"
                type="text"
                name="bankName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankName}
                errorMessage={
                  errors.bankName && touched.bankName && errors.bankName
                }
              />
              <InputMolecule
                label="No. Account"
                type="text"
                name="accountNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.accountNumber}
                errorMessage={
                  errors.accountNumber &&
                  touched.accountNumber &&
                  errors.accountNumber
                }
              />
            </div>
            <SelectMolecule
              label="Status Pembayaran"
              placeholder="Pilih Status"
              options={[
                {
                  value: true,
                  label: "Confirmed",
                },
                {
                  value: false,
                  label: "Unconfirmed",
                },
              ]}
              name="status"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.status}
              errorMessage={errors.status && touched.status && errors.status}
              absolute={false}
            />
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Update Payment</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default UpdatePaymentModal;
