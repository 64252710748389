import { HomeIcon } from '@heroicons/react/20/solid';

function BreadcrumbRoot() {
  return (
    <li>
      <div>
        <a
          href="#"
          className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
        >
          <HomeIcon className="flex-shrink-0 h-5 w-5" />
        </a>
      </div>
    </li>
  );
}

export default BreadcrumbRoot;
