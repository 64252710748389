import { Disclosure } from '@headlessui/react';

function SidebarNavigationCollapsibleWrapper({ expanded, children }) {
  return (
    <Disclosure
      as="div"
      className="space-y-1"
      defaultOpen={expanded}
    >
      {
        ({ open }) => children(open)
      }
    </Disclosure>
  );
}

export default SidebarNavigationCollapsibleWrapper;
