import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { revoke } from "app/features/authentication/authenticationSlice";
import { useNavigate } from "react-router-dom";

function LogoutPage() {
  const authenticated = useSelector(
    (state) => state.authentication.authenticated
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      dispatch(revoke());
      localStorage.clear();
    }

    navigate("/login");
  }, []);
}

export default LogoutPage;
