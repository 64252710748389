import { useApplicationModals } from "app/common/hooks";
import { Button } from "app/components/atoms/button";
import { InputMolecule } from "app/components/molecules/form";
import { ModalMolecule } from "app/components/molecules/modal";
import { SelectMolecule } from "app/components/molecules/select";
import api from "app/integration/api";
import {
  useCollector,
  useFetcher,
  useUpdateForm,
} from "app/integration/common/hooks";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import * as Yup from "yup";
import AppModals from ".";

function UpdateProductModal({ onSuccess = () => {} }) {
  const [categories] = useCollector({ modelName: "category" });
  const [suppliers] = useCollector({ modelName: "supplier" });
  const [errMsg, setErrMsg] = useState("");
  const [isError, setIsError] = useState(false);

  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const { openModal, setPayloads } = useApplicationModals();
  const payload = getPayloads("product.update");
  const [image, setImage] = useState("");

  const handleClose = () => closeModal("product.update");
  const handleSuccess = () => {
    handleClose();
    onSuccess();
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: "",
        name: "",
        code: "",
        variant_id: "",
        supplier_id: "",
        price: 0,
        weight: 0,
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Nama harus diisi"),
        code: Yup.string().required("Kode harus diisi"),
        // variant_id: Yup.string(),
        // supplier_id: Yup.string(),
        price: Yup.number().required("Harga harus diisi"),
        weight: Yup.number().required("Berat harus diisi"),
      }),
      onSubmit: async (values) => {
        try {
          const response = await api.product.update(
            values.id,
            values.name,
            values.code,
            values.variant_id,
            values.supplier_id,
            values.price,
            values.weight,
            image
          );

          setIsError(false);
          handleSuccess();
          return response;
        } catch (error) {
          setPayloads("errorMessage.view", {
            title: "Update Product",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Server Error",
          });
          setIsError(true);
          openModal("errorMessage.view");
        }
      },
    });

  const [product, setVariant, loader, fetch] = useFetcher({
    modelName: "product",
    payload,
  });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const fileChangeHandler = async (e, index) => {
    console.log("before resize: ", e.target.files);
    let imageResize = await resizeFile(e.target.files[0]);

    console.log("after resize: ", imageResize);

    let fileType = imageResize.type;
    let fileName = imageResize.name;

    if (fileType.search(/jpeg|jpg|png/gi) >= 0) {
      console.log(
        "file ini di perbolehkan untuk di upload",
        fileType.search(/jpeg|jpg|png/gi)
      );
      const uploaded = await api.uploadImage.product({ image: imageResize });

      if (uploaded && uploaded.status == "success") {
        setImage(uploaded.image_url);
      }
    } else {
      console.log(
        "file tidak dapat di upload.",
        fileType.search(/jpeg|jpg|pdf|xls|zip|rar/gi),
        fileName.search(/.rar/gi)
      );
      alert(`Jenis File ${fileType} tidak dapat di upload`);
    }
  };

  useEffect(() => {
    setImage("");
    product?.imageUrl && setImage(product.imageUrl);

    setInitialValues({
      id: product.id,
      name: product.name,
      variant_id: product.variant_id,
      supplier_id: product.supplier_id,
      code: product.code,
      price: product.price,
      weight: product.weight,
    });
  }, [product]);

  return (
    <ModalMolecule
      show={showStates["product.update"]}
      title="Ubah Produk"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>{isError && <div className="text-red-500">{errMsg}</div>}</div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputMolecule
                label="Nama"
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                errorMessage={errors.name && touched.name && errors.name}
              />
              <InputMolecule
                label="Kode"
                type="text"
                name="code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
                errorMessage={errors.code && touched.code && errors.code}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <SelectMolecule
                label="Kategori"
                name="variant_id"
                options={categories?.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.variant_id}
                errorMessage={
                  errors.variant_id && touched.variant_id && errors.variant_id
                }
              />
              <SelectMolecule
                label="Supplier"
                name="supplier_id"
                options={suppliers?.map((supplier) => ({
                  value: supplier.id,
                  label: `${supplier.firstName} ${supplier.middleName} ${supplier.lastName}`,
                }))}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.supplier_id}
                errorMessage={
                  errors.supplier_id &&
                  touched.supplier_id &&
                  errors.supplier_id
                }
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputMolecule
                label="Harga"
                type="number"
                name="price"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.price}
                errorMessage={errors.price && touched.price && errors.price}
              />
              <InputMolecule
                label="Berat (gram)"
                type="number"
                name="weight"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.weight}
                errorMessage={errors.weight && touched.weight && errors.weight}
              />
            </div>

            <div className="grid md:grid-cols-1 gap-1">
              {image && image != "" ? (
                <img src={`${process.env.REACT_APP_AWS_LINK_URL}${image}`} />
              ) : null}
              <>
                <div>Upload foto produk di sini:</div>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => fileChangeHandler(e)}
                />
              </>
            </div>

            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Simpan</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default UpdateProductModal;
