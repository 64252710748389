import Wrapper from './wrapper';
import Heading from './heading';
import Logo from './logo';
import Body from './body';
import Navigation from './navigation';
import NavigationCollapsible from './navigationCollapsible';

export default {
  Wrapper,
  Heading,
  Logo,
  Body,
  Navigation,
  NavigationCollapsible,
};

export {
  Wrapper as SidebarWrapper,
  Heading as SidebarHeading,
  Logo as SidebarLogo,
  Body as SidebarBody,
  Navigation as SidebarNavigation,
  NavigationCollapsible as SidebarNavigationCollapsible,
};
