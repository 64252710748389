import { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import AgnosticSidebar from "./agnostic";
import { XMarkIcon } from "@heroicons/react/24/outline";

function MainLayoutSidebar({
  onSidebarClose,
  onSidebarOpen,
  open = false,
  activeNavigation,
  activeChild,
}) {
  const [sidebarOpen, setSidebarOpen] = useState(open);

  useEffect(() => {
    if (open !== sidebarOpen) {
      setSidebarOpen(open);
    }
  }, [open]);

  return (
    <Fragment>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-40 inset-0 flex print:hidden"
          // onClose={setSidebarOpen}
          onClose={(e) => {
            setSidebarOpen(false);
            onSidebarClose();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex max-w-xs w-56 flex-col bg-primary-600">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  {/* Set sidebar close */}
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={(e) => {
                      setSidebarOpen(false);
                      onSidebarClose();
                    }}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                  {/* End set sidebar close */}
                </div>
              </Transition.Child>
              <AgnosticSidebar
                activeNavigation={activeNavigation}
                activeChild={activeChild}
              />
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      <div className="z-40 hidden w-14 transition-all duration-500 ease-in-out group hover:w-52 hover:ease-in-out print:hidden md:fixed md:inset-y-0 md:flex md:flex-col xl:w-52">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <AgnosticSidebar
          activeNavigation={activeNavigation}
          activeChild={activeChild}
        />
      </div>
    </Fragment>
  );
}

export default MainLayoutSidebar;
