import { useParams, useNavigate } from "react-router-dom";
import { useApplicationModals } from "app/common/hooks";
import {
  useUpdateForm,
  useCollector,
  useFetcher,
} from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { useEffect, useState } from "react";
import Directory from "app/components/atoms/directory";
import { ClosableCard } from "app/components/atoms/card";
import { SearchInput } from "app/components/atoms/input";
import { Formik, FieldArray, getIn } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { InputLabel } from "app/components/atoms/form";
import { SelectSearchMolecule } from "app/components/molecules/select";
import { Button, DottedOutlineButton } from "app/components/atoms/button";
import Alert from "app/components/molecules/alert";
import AppModals from "app/modals";

function SupplierDetailSubPage({
  onSuccess = () => {},
  suppliers = [],
  query = "",
  onQueryChange = () => {},
}) {
  let { id } = useParams();
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();
  const handleSuccess = () => {
    onSuccess();
  };

  var isError = false;

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        nickName: "",
        phone: "",
        email: "",
        first_bank: "",
        second_bank: "",
        supplier_address: [],
      },
      validationSchema: Yup.object().shape({
        firstName: Yup.string().required("Nama Supplier harus diisi"),
        middleName: Yup.string(),
        lastName: Yup.string(),
        nickName: Yup.string(),
        phone: Yup.string(),
        email: Yup.string(),
        // first_bank: Yup.string(),
        // second_bank: Yup.string(),
        // third_bank: Yup.string(),
        supplier_address: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            desc: Yup.string().required("Alamat harus diisi"),
            state_id: Yup.number().required("Provinsi harus diisi"),
            state_name: Yup.string(),
            city_id: Yup.number().required("Kota harus diisi"),
            city_name: Yup.string(),
            // district_id: Yup.number(),
            // district_name: Yup.string(),
            // sub_district_id: Yup.number(),
            // sub_district_name: Yup.string(),
            // zipcode: Yup.string(),
          })
        ),
      }),
      onSubmit: async (values) => {
        console.log("update supplier");
        await api.supplier
          .update(
            supplier.id,
            values.firstName,
            values.middleName,
            values.lastName,
            values.nickName,
            values.first_bank,
            values.second_bank,
            values.third_bank,
            values.email,
            values.phone,
            values.supplier_address.map((address) => ({
              desc: address.desc,
              state_id: address.state_id,
              state_name: address.state_name,
              city_id: address.city_id,
              city_name: address.city_name,
              district_id: address.district_id,
              district_name: address.district_name,
              sub_district_id: address.sub_district_id,
              sub_district_name: address.sub_district_name,
              zipcode: address.zipcode,
            }))
          )
          .catch((error) => {
            console.log("error: ", error);
            setPayloads("errorMessage.view", {
              title: "Tambah Supplier Error",
              message: error?.response?.data?.message,
            });
            isError = true;
            openModal("errorMessage.view");
          });
      },
      onSuccess: () => {
        if (!isError) {
          setAlert(true);
          handleSuccess();
        }
      },
    });

  const [supplier, setSupplier, loader, fetch] = useFetcher({
    modelName: "supplier",
    payload: { id },
  });

  const [states] = useCollector({
    modelName: "rajaOngkir",
    methodName: "province",
  });

  const [cities, setCities, cityLoader, collectCities] = useCollector({
    modelName: "rajaOngkir",
    methodName: "city",
  });

  const [subDistricts, setSubDistricts, subDistrictLoader, collectSubDistrict] =
    useCollector({
      modelName: "rajaOngkir",
      methodName: "subdistrict",
    });

  const [showAlert, setAlert] = useState(false);

  setTimeout(() => {
    setAlert(false);
  }, 7000)

  const closeBanner = (e) => {
    setAlert(false);
  }

  useEffect(() => {
    setInitialValues({
      id: supplier.id,
      firstName: supplier.firstName,
      middleName: supplier.middleName,
      lastName: supplier.lastName,
      nickName: supplier.nickName,
      first_bank: supplier.first_bank,
      second_bank: supplier.second_bank,
      third_bank: supplier.third_bank,
      email: supplier.email,
      phone: supplier.phone,
      credit: supplier.credit,
      supplier_address: supplier.supplier_address,
    });
  }, [supplier]);

  return (
    <div className="flex">
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <div className="hidden md:block">
        <Directory.Container>
          <Directory.UtilitiesWrapper>
            <Directory.SearchBarWrapper>
              <Directory.SearchBarItemWrapper>
                <SearchInput
                  placeholder="Cari Supplier"
                  type="text"
                  query={query}
                  onChange={onQueryChange}
                />
              </Directory.SearchBarItemWrapper>
            </Directory.SearchBarWrapper>
          </Directory.UtilitiesWrapper>
          <Directory.Wrapper asIndex={true}>
            <Directory.Header>Supplier</Directory.Header>
            <Directory.Body>
              {[...suppliers]
                ?.sort(function (a, b) {
                  if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase())
                    return -1;
                  if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase())
                    return 1;
                  return 0;
                })
                .map((supplier, key) => (
                  <Directory.Row
                    key={key}
                    active={supplier.id === +id}
                    onClick={(e) => navigate("/supplier/detail/" + supplier.id)}
                  >
                    {supplier.firstName} {supplier.middleName} {supplier.lastName}
                  </Directory.Row>
                ))}
            </Directory.Body>
          </Directory.Wrapper>
        </Directory.Container>
      </div>
      <ClosableCard
        grow={true}
        asMainContent={true}
        onClose={(e) => navigate("/supplier")}
      >
        {showAlert && (<Alert title="Sukses" desc="Supplier berhasil diubah"></Alert>)}
        <Formik
          {...{ initialValues, validationSchema, onSubmit }}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            status,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="space-y-4 p-2 pb-24" onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Nama Depan"
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  errorMessage={
                    errors.firstName && touched.firstName && errors.firstName
                  }
                />
                <InputMolecule
                  label="Nama Tengah"
                  type="text"
                  name="middleName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.middleName}
                  errorMessage={
                    errors.middleName && touched.middleName && errors.middleName
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Nama Belakang"
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  errorMessage={
                    errors.lastName && touched.lastName && errors.lastName
                  }
                />
                <InputMolecule
                  label="Nama Panggilan"
                  type="text"
                  name="nickName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nickName}
                  errorMessage={
                    errors.nickName && touched.nickName && errors.nickName
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Email"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  errorMessage={errors.email && touched.email && errors.email}
                />
                <InputMolecule
                  label="No. HP"
                  type="text"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  errorMessage={errors.phone && touched.phone && errors.phone}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Nama Bank"
                  type="text"
                  name="first_bank"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_bank}
                  errorMessage={
                    errors.first_bank && touched.first_bank && errors.first_bank
                  }
                />
                <InputMolecule
                  label="Nomor Rekening"
                  type="text"
                  name="second_bank"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.second_bank}
                  errorMessage={
                    errors.second_bank &&
                    touched.second_bank &&
                    errors.second_bank
                  }
                />

                {/* <InputMolecule
                  label="Account Bank 3"
                  type="text"
                  name="third_bank"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.third_bank}
                  errorMessage={
                    errors.third_bank && touched.third_bank && errors.third_bank
                  }
                /> */}
              </div>
              <div>
                <hr className="my-5" />
              </div>
              <div>
                <InputLabel>Alamat</InputLabel>
                <FieldArray name="supplier_address">
                  {(arrayHelpers) => (
                    <div className="space-y-2">
                      {values?.supplier_address?.map((address, addressKey) => (
                        <ClosableCard
                          key={addressKey}
                          onClose={() => arrayHelpers.remove(addressKey)}
                        >
                          <div className="space-y-2 p-2">
                            <InputMolecule
                              label="Alamat Lengkap"
                              type="text"
                              name={`supplier_address[${addressKey}].desc`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.desc}
                              errorMessage={
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].desc`
                                ) &&
                                getIn(
                                  touched,
                                  `supplier_address[${addressKey}].desc`
                                ) &&
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].desc`
                                )
                              }
                            />
                            <SelectSearchMolecule
                              label="Provinsi"
                              name={`supplier_address[${addressKey}].state_id`}
                              options={states?.map((state) => ({
                                value: state.province_id,
                                label: state.province,
                              }))}
                              absolute={true}
                              onChange={(e) => {
                                values.supplier_address[
                                  `${addressKey}`
                                ].state_name = states.filter(
                                  (i) => i.province_id == e.target.value
                                )[0]["province"];
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              value={`${address.state_id}`}
                              errorMessage={
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].state_id`
                                ) &&
                                getIn(
                                  touched,
                                  `supplier_address[${addressKey}].state_id`
                                ) &&
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].state_id`
                                )
                              }
                            />
                            <SelectSearchMolecule
                              label="Kota"
                              name={`supplier_address[${addressKey}].city_id`}
                              options={cities?.map((city) => ({
                                value: city.city_id,
                                label: city.city_name,
                              }))}
                              absolute={true}
                              onChange={(e) => {
                                values.supplier_address[
                                  `${addressKey}`
                                ].city_name = cities.filter(
                                  (i) => i.city_id == e.target.value
                                )[0]["city_name"];
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              value={`${address.city_id}`}
                              errorMessage={
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].city_id`
                                ) &&
                                getIn(
                                  touched,
                                  `supplier_address[${addressKey}].city_id`
                                ) &&
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].city_id`
                                )
                              }
                            />
                            <InputMolecule
                              label="Kecamatan"
                              type="text"
                              name={`supplier_address[${addressKey}].district_name`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.district_name}
                              errorMessage={
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].district_name`
                                ) &&
                                getIn(
                                  touched,
                                  `supplier_address[${addressKey}].district_name`
                                ) &&
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].district_name`
                                )
                              }
                            />
                            <InputMolecule
                              label="Kelurahan"
                              type="text"
                              name={`supplier_address[${addressKey}].sub_district_name`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.sub_district_name}
                              errorMessage={
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].sub_district_name`
                                ) &&
                                getIn(
                                  touched,
                                  `supplier_address[${addressKey}].sub_district_name`
                                ) &&
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].sub_district_name`
                                )
                              }
                            />
                            <InputMolecule
                              label="Kode Pos"
                              type="text"
                              name={`supplier_address[${addressKey}].zipcode`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.zipcode}
                              errorMessage={
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].zipcode`
                                ) &&
                                getIn(
                                  touched,
                                  `supplier_address[${addressKey}].zipcode`
                                ) &&
                                getIn(
                                  errors,
                                  `supplier_address[${addressKey}].zipcode`
                                )
                              }
                            />
                          </div>
                        </ClosableCard>
                      ))}
                      <div>
                        <div className="my-8">
                          <DottedOutlineButton
                            type="button"
                            onClick={(e) =>
                              arrayHelpers.push({
                                address: "",
                                state: "",
                                city: "",
                                district: "",
                                subDistrict: "",
                                shipmentCostCategoryId: null,
                              })
                            }
                          >
                            Tambah Alamat
                          </DottedOutlineButton>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>
              <div>
                <hr className="my-5" />
              </div>
              <div className="fixed bottom-2 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
                <Button type="submit">Ubah Supplier</Button>
              </div>
            </form>
          )}
        </Formik>
      </ClosableCard>
    </div>
  );
}

export default SupplierDetailSubPage;
