
const env = process.env;

function baseUrl(url) {
  return env.REACT_APP_API_BASE_URL + url;
}

export default {
  baseUrl,
};

export {
  baseUrl,
};
