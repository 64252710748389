import axios from "../axios";
import h from "../helpers";

const credit = {
  index: async () => {
    const { data } = await axios.get(h.baseUrl("credits"));
    return data;
  },
  store: async (
    customer_id,
    method,
    ref_number,
    bank_name,
    account_number,
    amount,
    desc,
    type
  ) => {
    const { data } = await axios.post(h.baseUrl("credits"), {
      customer_id,
      method,
      ref_number,
      bank_name,
      account_number,
      amount,
      desc,
      type,
    });
    return data;
  },
  show: async (filter) => {
    let arr = [];
    let queryParams = "";

    if (filter && filter != {}) {
      const filterObj = Object.entries(filter);
      for (let i in filterObj) {
        if (filterObj[i][1] && filterObj[i][1] != "") {
          arr.push(`${filterObj[i][0]}=${filterObj[i][1]}`);
        }
      }
    }

    if (arr.length > 0) {
      for (let i in arr) {
        if (i == 0) {
          queryParams += `?${arr[i]}`;
        } else {
          queryParams += `&${arr[i]}`;
        }
      }
    }
    console.log("arr: ", arr);
    const { data } = await axios.get(h.baseUrl("credits/" + queryParams));
    return data;
  },
};

export default credit;
