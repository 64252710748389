
const authentication = {
  store(authenticated, token) {
    const authenticationStorage = JSON.parse(localStorage.getItem('authentication') || '{}');
    authenticationStorage.authenticated = authenticated;
    authenticationStorage.token = token;
    localStorage.setItem('authentication', JSON.stringify(authenticationStorage));
  },
  token() {
    const authenticationStorage = JSON.parse(localStorage.getItem('authentication') || '{}');
    return authenticationStorage.token;
  },
  authenticated() {
    const authenticationStorage = JSON.parse(localStorage.getItem('authentication') || '{}');
    return authenticationStorage.authenticated;
  }
};

export default {
  authentication,
};

export {
  authentication,
};
