import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useApplicationModals } from "app/common/hooks";
import { IconButton } from "app/components/atoms/button";
import Table from "app/components/atoms/table";
import { useCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";

const breadcrumbItems = [
  { label: "Settings", href: "#" },
  { label: "Kategori", href: "#" },
];

function CategoryPage() {
  const { openModal, setPayloads } = useApplicationModals();

  const [categorys, setVariants, loader, collect] = useCollector({
    modelName: "category",
  });

  return (
    <MainLayout
      activeSidebarNavigation="settings"
      activeSidebarChild="category"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Kategori"
      headingButtons={[
        {
          label: "Tambah Kategori",
          type: "button",
          onClick: () => openModal("category.create"),
        },
      ]}
    >
      <AppModals
        items={["category.create", "category.update", "category.delete"]}
        onSuccess={{
          "category.create": () => collect(),
          "category.update": () => collect(),
          "category.delete": () => collect(),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <Table.SearchBarItemWrapper>
              {/* <SearchInput placeholder="Search" /> */}
            </Table.SearchBarItemWrapper>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={false}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>Nama Kategori</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {categorys?.map((category, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>{category.name}</Table.Cell>
                <Table.Cell>
                  <div className="flex">
                    <IconButton
                      icon={PencilSquareIcon}
                      onClick={(e) => {
                        setPayloads("category.update", {
                          id: category.id,
                        });
                        openModal("category.update");
                      }}
                    />
                    {/* <IconButton
                      icon={TrashIcon}
                      onClick={(e) => {
                        setPayloads("category.delete", {
                          id: category.id,
                        });
                        openModal("category.delete");
                      }}
                    /> */}
                  </div>
                </Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default CategoryPage;
