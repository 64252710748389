import { useParams, useNavigate, useFetcher } from "react-router-dom";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useApplicationModals } from "app/common/hooks";
import { useCollector } from "app/integration/common/hooks";
import { IconButton } from "app/components/atoms/button";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import Table from "app/components/atoms/table";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";

function ProductDetail() {
  let { id } = useParams();

  const breadcrumbItems = [
    { label: "Settings", href: "#" },
    { label: "Product Master", href: "/product" },
    { label: "Product Detail" },
  ];
  const navigate = useNavigate();
  const { openModal, setPayloads } = useApplicationModals();

  const [productVariants, setProductVariant, productVariantLoader, collect] =
    useCollector({
      modelName: "productVariant",
      params: { product_id: id },
    });

  return (
    <MainLayout
      activeSidebarNavigation="settings"
      activeSidebarChild="productVariant"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Product Variant"
      headingButtons={[
        {
          label: "Add Product Variant",
          type: "button",
          onClick: () => {
            setPayloads("productVariant.create", {
              id: id,
            });
            openModal("productVariant.create");
          },
        },
      ]}
    >
      <AppModals
        items={[
          "productVariant.create",
          "productVariant.update",
          "productVariant.delete",
        ]}
        onSuccess={{
          "productVariant.create": () => collect({ product_id: id }),
          "productVariant.update": () => collect({ product_id: id }),
          "productVariant.delete": () => collect({ product_id: id }),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <Table.SearchBarItemWrapper>
              {/* <SearchInput placeholder="Search" /> */}
            </Table.SearchBarItemWrapper>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={false}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>Kode</Table.Heading>
              <Table.Heading>Nama</Table.Heading>
              <Table.Heading>Size</Table.Heading>
              <Table.Heading>Warna</Table.Heading>
              <Table.Heading>Stok</Table.Heading>
              <Table.Heading>Keluar</Table.Heading>
              <Table.Heading>Masuk</Table.Heading>
              <Table.Heading>Harga</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {productVariants?.map((productVariant, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>{productVariant?.product_detail?.code}</Table.Cell>
                <Table.Cell>{productVariant?.product_detail?.name}</Table.Cell>
                <Table.Cell>{productVariant?.size?.name}</Table.Cell>
                <Table.Cell>{productVariant?.color?.name}</Table.Cell>
                <Table.Cell>{productVariant?.qty}</Table.Cell>
                <Table.Cell>{productVariant?.outgoing_qty}</Table.Cell>
                <Table.Cell>{productVariant?.incoming_qty}</Table.Cell>
                <Table.Cell>{productVariant?.product_detail?.price}</Table.Cell>
                <Table.Cell>
                  <div className="flex">
                    <IconButton
                      icon={PencilSquareIcon}
                      onClick={(e) => {
                        setPayloads("productVariant.update", {
                          id: productVariant.id,
                        });
                        openModal("productVariant.update");
                      }}
                    />
                    {/* <IconButton
                      icon={TrashIcon}
                      onClick={(e) => {
                        setPayloads("productVariant.delete", {
                          id: productVariant.id,
                        });
                        openModal("productVariant.delete");
                      }}
                    /> */}
                  </div>
                </Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default ProductDetail;
