import { useApplicationModals } from "app/common/hooks";
import { ModalMolecule } from "app/components/molecules/modal";

function ViewErrorMessageModal({ onSuccess = () => {} }) {
  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("errorMessage.view");
  const report = payload?.data;
  const title = payload?.title;
  const message = payload?.message;

  // console.log("errors: ", payload?.data);

  const handleClose = () => closeModal("errorMessage.view");
  const handleSuccess = () => {
    closeModal("errorMessage.view");
    onSuccess();
  };

  return (
    <ModalMolecule
      show={showStates["errorMessage.view"]}
      title={title}
      onClose={handleClose}
      size="xl"
    >
      <ul className="list-disc px-4">
        {report !== null &&
          report?.map((errorMsg) => (
            <li className="text-gray-700 font-light">{errorMsg}</li>
          ))}
      </ul>
      {message !== null && <p>{message}</p>}
    </ModalMolecule>
  );
}

export default ViewErrorMessageModal;
