import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

function BreadcrumbItem({ children, href = '#', to = null, active = false }) {
  return (
    <li>
      <div className="flex items-center">
        <ChevronRightIcon
          className="flex-shrink-0 h-5 w-5 text-gray-400 dark:text-gray-500"
        />
        {
          !to && (
            <a
              href={href}
              className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
            >
              {
                children
              }
            </a>
          )
        }
        {
          to && (
            <Link
              to={to}
              className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
            >
              {
                children
              }
            </Link>
          )
        }
      </div>
    </li>
  );
}

export default BreadcrumbItem;
