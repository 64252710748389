
function BreadcrumbBody({ children }) {
  return (
    <ol className="hidden sm:flex items-center space-x-4">
      {
        children
      }
    </ol>
  );
}

export default BreadcrumbBody;
