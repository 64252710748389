import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

function MobileBackUtility() {
  const history = useNavigate();

  return (
    <nav className="flex h-10 items-center print:hidden sm:hidden">
      <a
        href="#"
        onClick={() => history(-1)}
        className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 dark:hover:text-gray-200"
      >
        <ChevronLeftIcon
          className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
        Back
      </a>
    </nav>
  );
}

export default MobileBackUtility;
