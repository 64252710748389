import { Disclosure } from '@headlessui/react';

function SidebarNavigationCollapsibleChildrenWrapper({ children }) {
  return (
    <Disclosure.Panel className="space-y-1">
      {
        children
      }
    </Disclosure.Panel>
  );
}

export default SidebarNavigationCollapsibleChildrenWrapper;
