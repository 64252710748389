import str from "lib/str";

function Day({ date, format }) {
  return (
    <span className="font-light text-gray-500">
      {str.standardDay(date, "id", format)}
    </span>
  );
}

export default Day;
