import { useState, useEffect } from "react";
import clsx from "clsx";
import style from "style/height.module.css";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IconButton } from "app/components/atoms/button";
import { XMarkIcon } from "@heroicons/react/24/outline";

function modalSize(size) {
  return {
    lg: "md:max-w-2xl",
    xl: "lg:max-w-3xl"
  }[size];
}

function ModalMolecule({
  show = false,
  onClose = () => {},
  title,
  children,
  size = "sm",
  useHideButton = false,
}) {
  const [localShow, setLocalShow] = useState(show);

  const handleClose = () => {
    setLocalShow(false);
    onClose();
  };

  useEffect(() => {
    if (localShow != show) {
      setLocalShow(show);
    }
  }, [show]);

  return (
    <Transition.Root show={localShow} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50" onClose={handleClose}>
        <div className="max-height flex min-h-full items-end justify-center text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={clsx(
                "relative inline-block w-full transform overflow-visible rounded-none bg-white text-left align-bottom shadow-xl transition-all dark:bg-gray-900 sm:my-8 sm:w-full sm:max-w-xl sm:rounded-lg sm:align-middle",
                modalSize(size)
              )}
            >
              <div className="flex items-center justify-between rounded-t border-b p-4 dark:border-gray-600 md:px-6">
                <h1 className="text-gray-900 dark:text-gray-100 text-lg font-medium">
                  {title}
                </h1>
                {useHideButton ? (
                  <button className="w-8 h-8 relative">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Dialog.Overlay className="absolute inset-0" />
                    </Transition.Child>
                    <IconButton icon={XMarkIcon} />
                  </button>
                ) : (
                  <IconButton icon={XMarkIcon} onClick={handleClose} />
                )}
              </div>
              <div
                className={clsx(
                  style["modal-body-max-height"],
                  "overflow-visible p-0"
                )}
              >
                <div
                  className={clsx(
                    style["modal-content-max-height"],
                    "flex flex-col gap-4 overflow-y-auto px-6 py-4 pb-24"
                  )}
                >
                  {children}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ModalMolecule;
