import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import clsx from "clsx";

function SidebarNavigationCollapsibleItem({
  icon,
  active = false,
  children,
  expanded,
  onExpanded = () => {},
  onCollapsed = () => {},
}) {
  const Icon = icon;

  const [localExpanded, setLocalExpanded] = useState(expanded);
  const handleDisclosureButtonClick = (e) => {
    const newLocalExpanded = !localExpanded;
    setLocalExpanded(newLocalExpanded);
    if (newLocalExpanded) {
      onExpanded();
    } else {
      onCollapsed();
    }
  };

  useEffect(() => {
    if (expanded !== localExpanded) {
      setLocalExpanded(expanded);
    }
  }, [expanded]);

  return (
    <Disclosure.Button
      className={clsx(
        active
          ? "bg-primary-700 text-gray-100"
          : "text-gray-100 hover:bg-primary-600",
        "group flex w-full items-center justify-between rounded-md py-2 pl-2 pr-0 text-left text-base font-normal focus:outline-none focus:ring-2 focus:ring-primary-500 transition-colors duration-500 ease-in-out"
      )}
      onClick={handleDisclosureButtonClick}
    >
      <div className="w-full flex items-center">
        <div>
          <Icon
            className="mr-4 h-6 w-6 flex-shrink-0 text-gray-100"
            aria-hidden="true"
          />
        </div>
        <div className="w-full pr-2 flex justify-between items-center">
          <div>{children}</div>
          <div className="">
            <svg
              className={clsx(
                localExpanded ? "rotate-90" : "text-gray-100",
                "h-5 w-5 flex-shrink-0 transform transition-colors duration-500 ease-in-out group-hover:text-gray-100"
              )}
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
    </Disclosure.Button>
  );
}

export default SidebarNavigationCollapsibleItem;
