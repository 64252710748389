import clsx from 'clsx';

function tableHeadingJustify(justify) {
  return {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
  }[justify];
}

function tableHeadingClassName(className) {
  return className;
}

function TableHeading({
  children,
  justify = "left",
  className = "",
  align = "",
}) {
  return (
    <th
      className={clsx(
        "sticky top-0 z-0 bg-gray-100 dark:bg-gray-700 bg-opacity-75 text-xs font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wider py-3 px-4",
        tableHeadingJustify(justify),
        tableHeadingClassName(className),
        align === "right" ? "text-right" : ""
      )}
    >
      {children}
    </th>
  );
}

export default TableHeading;
