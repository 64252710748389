import { useApplicationModals } from "app/common/hooks";
import { Button } from "app/components/atoms/button";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import api from "app/integration/api";
import { useCollector, useUpdateForm } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik, getIn } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "style/height.module.css";
import * as Yup from "yup";

const breadcrumbItems = [
  { label: "Purchase", href: "/purchase_order" },
  { label: "Order", href: "/purchase_order" },
  { label: "Product Forecast", href: "#" },
];

function CreatePurchaseOrderPage() {
  const navigate = useNavigate();
  let isError = false;

  const handleSuccess = (res) => {
    console.log(res);
    navigate("/purchase_order");
  };

  const [isLoading, setIsloading] = useState(false);

  const [forecast] = useCollector({
    modelName: "report",
    methodName: "productForecast",
  });

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // console.log(today);

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        items: [],
      },
      validationSchema: Yup.object().shape({
        items: Yup.array()
          .of(
            Yup.object().shape({
              to_order: Yup.number().required("Menu harus diisi"),
            })
          )
          .min(1, "Minimal harus ada 1 Item yang dibeli."),
      }),
      onSubmit: async (values) => {
        try {
          setIsloading(true);
          let response = await api.purchaseOrder.store(
            values.items.filter((item) => item.to_order > 0)
          );

          if (response && response.status == "success") {
            isError = false;
            setIsloading(false);
          }
          return response;
        } catch (error) {
          setPayloads("errorMessage.view", {
            title: "Tambah PO",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Server Error",
          });
          isError = true;
          openModal("errorMessage.view");
          setIsloading(false);
        }
      },
      onSuccess: handleSuccess,
    });

  const { openModal, setPayloads } = useApplicationModals();
  const [currentErrors, setCurrentErrors] = useState([]);

  function runValidations(values) {
    validationSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
        setCurrentErrors([]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.name); // ValidationError
        console.log(err.errors);
        setCurrentErrors(err.errors);

        setPayloads("errorMessage.view", {
          title: "Tambah Order Error",
          data: err.errors,
        });
        openModal("errorMessage.view");
      });
  }

  useEffect(() => {
    setInitialValues({
      items: forecast.map((item) => ({
        id: item.id,
        product_name: item.product_name,
        product_code: item.product_code,
        imageUrl: item.imageUrl,
        color: item.color,
        size: item.size,
        supplier_id: item.supplier_id,
        product_id: item.product_id,
        size_variant_id: item.size_variant_id,
        color_variant_id: item.color_variant_id,
        onhand_qty: item.onhand_qty,
        outgoing_qty: item.outgoing_qty,
        incoming_qty: item.incoming_qty,
        forecast_qty: item.forecast_qty,
        to_order: item.to_order,
      })),
    });
    console.log("initial: ", initialValues);
  }, [forecast?.length > 0]);

  return (
    <MainLayout
      activeSidebarNavigation="purchase"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Tambah Pembelian"
      headingButtons={[]}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
          >
            <div className="py-2 overflow-visible">
              <div className="w-full">
                <FieldArray name="items">
                  {(arrayHelpers) => (
                    <div className="main-content-height overflow-y-auto pb-24">
                      <Table.Wrapper asIndex={false}>
                        <Table.Header>
                          <Table.HeaderRow>
                            <Table.Heading>Nama</Table.Heading>
                            <Table.Heading>Kode</Table.Heading>
                            <Table.Heading>Warna</Table.Heading>
                            <Table.Heading>Size</Table.Heading>
                            <Table.Heading align="right">Stok</Table.Heading>
                            <Table.Heading align="right">Keluar</Table.Heading>
                            <Table.Heading align="right">Datang</Table.Heading>
                            <Table.Heading align="right">
                              Prediksi
                            </Table.Heading>
                            <Table.Heading align="right">Order</Table.Heading>
                            <Table.Heading></Table.Heading>
                          </Table.HeaderRow>
                        </Table.Header>
                        <Table.Body>
                          {console.log("values: ", values)}
                          {values?.items.length > 0 &&
                            values.items.map((item, key) => (
                              <Table.BodyRow key={key}>
                                <Table.Cell size="xs">
                                  <div className="flex gap-2 items-center">
                                    {item?.imageUrl && item?.imageUrl != "" ? (
                                      <ImageWithPreview src={`${process.env.REACT_APP_AWS_LINK_URL}${item?.imageUrl}`}></ImageWithPreview>
                                    ) : null}
                                    <p>{item.product_name}</p>
                                  </div>
                                </Table.Cell>
                                <Table.Cell size="xs">
                                  <p>{item.product_code}</p>
                                </Table.Cell>
                                <Table.Cell size="xs">
                                  <p>{item.color}</p>
                                </Table.Cell>
                                <Table.Cell size="xs">{item.size}</Table.Cell>
                                <Table.Cell size="xs">
                                  <p className="text-right">
                                    <Typography.Currency
                                      number={item.onhand_qty}
                                    />
                                  </p>
                                </Table.Cell>
                                <Table.Cell size="xs">
                                  <p className="text-right">
                                    <Typography.Currency
                                      number={item.outgoing_qty}
                                    />
                                  </p>
                                </Table.Cell>
                                <Table.Cell size="xs">
                                  <p className="text-right">
                                    <Typography.Currency
                                      number={item.incoming_qty}
                                    />
                                  </p>
                                </Table.Cell>
                                <Table.Cell size="xs">
                                  <p className="text-right">
                                    <Typography.Currency
                                      number={item.onhand_qty + item.incoming_qty - item.outgoing_qty}
                                    />
                                  </p>
                                </Table.Cell>
                                <Table.Cell size="xs" className="w-24">
                                  <InputMolecule
                                    hideLabel={true}
                                    type="number"
                                    name={`items[${key}].to_order`}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={item.to_order}
                                    errorMessage={
                                      getIn(errors, `items[${key}].to_order`) &&
                                      getIn(
                                        touched,
                                        `items[${key}].to_order`
                                      ) &&
                                      getIn(errors, `items[${key}].to_order`)
                                    }
                                    align="right"
                                    size="xs"
                                  />
                                </Table.Cell>
                              </Table.BodyRow>
                            ))}
                        </Table.Body>
                      </Table.Wrapper>
                    </div>
                  )}
                </FieldArray>
              </div>
            </div>

            <div className="fixed bottom-0 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
              <Button
                onClick={() => runValidations(values)}
                type="submit"
                disabled={isLoading || forecast.length == 0}
              >
                {/* Tambah Order{confirmedDuplicateOrder && " (duplikat)"} */}
                {isLoading ? "Loading..." : `Konfirmasi Pembelian`}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

export default CreatePurchaseOrderPage;
